import React, { Component } from 'react';
import './sidebar.scss';
import { Nav, Navbar, Container } from 'react-bootstrap';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import routePaths from '../../../shared/constants/routePaths';
import GSEDisplayTextConst from '../../../shared/constants/gseDisplayTextConst';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { ApplicationState } from '../../../store';
import { UserHelper } from '../../../helpers';

interface ComponentProps {
    applicationState: ApplicationState | undefined;
}

export class SideBar extends Component<ComponentProps> {
    state = {
        visible: true
    };

    constructor(props: ComponentProps) {
        super(props);
        this.isUserAuthorized = this.isUserAuthorized.bind(this);
    }

    procurementIcon = require('../../../assets/GSE Tracker Icons/Procurement team.png');
    transportationIcon = require('../../../assets/GSE Tracker Icons/Transportation team.png');
    manufacturingVendorIcon = require('../../../assets/GSE Tracker Icons/Manufacturing Vendors.png');
    transportationVendorIcon = require('../../../assets/GSE Tracker Icons/Transportation vendors.png');
    dashboardIcon = require('../../../assets/GSE Tracker Icons/Dashboard.png');
    adminIcon = require('../../../assets/GSE Tracker Icons/adminIcon.png');

    procurementIconActive = require('../../../assets/GSE Tracker Icons/Procurement team blue.png');
    transportationIconActive = require('../../../assets/GSE Tracker Icons/Transportation team blue.png');
    manufacturingVendorIconActive = require('../../../assets/GSE Tracker Icons/Manufacturing Vendors blue.png');
    transportationVandorIconActive = require('../../../assets/GSE Tracker Icons/Transportation vendors blue.png');
    dashboardIconActive = require('../../../assets/GSE Tracker Icons/Dashboard blue.png');
    adminIconActive = require('../../../assets/GSE Tracker Icons/adminIcon-Active.png');

    componentDidMount(): void {
        this.setNavLinkItemActive();
    }

    private setNavLinkItemActive() {
        setTimeout(() => {
            const link = window.location.href;
            let navControl;
            if (link.includes(routePaths.procurementDashboard)) {
                navControl = document.getElementById(GSEDisplayTextConst.dashboardLayoutId.procurementId);
            } else if (link.includes(routePaths.internalTransportationDashboard)) {
                navControl = document.getElementById(GSEDisplayTextConst.dashboardLayoutId.transportId);
            }
            else if (link.includes(routePaths.manufacturingVendorDashboard)) {
                navControl = document.getElementById(GSEDisplayTextConst.dashboardLayoutId.manufacturingVendorId);
            }
            else if (link.includes(routePaths.transportationVendorDashboard)) {
                navControl = document.getElementById(GSEDisplayTextConst.dashboardLayoutId.transportationVendorId);
            }
            else if (link.includes(routePaths.dashboard)) {
                navControl = document.getElementById(GSEDisplayTextConst.dashboardLayoutId.dashboardId);
            }   
            else if (link.includes(routePaths.admin)) {
                navControl = document.getElementById(GSEDisplayTextConst.dashboardLayoutId.adminId);
            }           
            navControl?.classList.add('nav-link-active');
        }, 200);
    }

    isUserAuthorized(pageName: string): boolean {
        const { applicationState } = this.props;
        return (applicationState && (UserHelper.isInternalUserAuthorized(applicationState, pageName) || 
            UserHelper.isExternalUserAuthorized(applicationState, pageName))) ?? false;
    }

    render() {
        this.setNavLinkItemActive();
        return (
            <div className="justify-content-center">
            <Sidebar visible={this.state.visible} onHide={() => this.setState({visible: false})} 
            className="w-15rem sidebar bg-light">
                <Navbar collapseOnSelect expand='sm'>
                    <Container>
                        <NavbarToggle aria-controls="sideBar" />
                        <Navbar.Collapse id='sideBar'>
                            <Nav className="col-md-12 d-md-block">
                                <div className="sidebar-sticky"></div>
                                {this.isUserAuthorized(routePaths.procurementDashboard) &&
                                <Nav.Item>
                                    <Nav.Link id= {GSEDisplayTextConst.dashboardLayoutId.procurementId} href={routePaths.procurementDashboard}>
                                        <img alt="procurementIcon" src={this.procurementIcon}></img>
                                        Procurement Team
                                    </Nav.Link>
                                </Nav.Item> }
                                {this.isUserAuthorized(routePaths.internalTransportationDashboard) &&
                                <Nav.Item>
                                    <Nav.Link id={GSEDisplayTextConst.dashboardLayoutId.transportId} 
                                        href={routePaths.internalTransportationDashboard}>
                                        <img alt="transportationIcon" src={this.transportationIcon}></img>
                                        Transportation Team
                                    </Nav.Link>
                                </Nav.Item>}
                                {this.isUserAuthorized(routePaths.transportationVendorDashboard) &&
                                <Nav.Item>
                                    <Nav.Link id={GSEDisplayTextConst.dashboardLayoutId.transportationVendorId} 
                                        href={routePaths.transportationVendorDashboard}>
                                        <img alt="transportationVendorIcon" src={this.transportationVendorIcon}></img>
                                        Transportation Vendor
                                    </Nav.Link>
                                </Nav.Item> }
                                {this.isUserAuthorized(routePaths.manufacturingVendorDashboard) &&
                                <Nav.Item>
                                    <Nav.Link id={GSEDisplayTextConst.dashboardLayoutId.manufacturingVendorId} 
                                        href={routePaths.manufacturingVendorDashboard}>
                                        <img alt="manufacturingVendorIcon" src={this.manufacturingVendorIcon}></img>
                                        Manufacturing Vendor
                                    </Nav.Link>
                                </Nav.Item> }   
                                {this.isUserAuthorized(routePaths.dashboard) &&
                                <Nav.Item>
                                    <Nav.Link id={GSEDisplayTextConst.dashboardLayoutId.dashboardId} href={routePaths.dashboard}>
                                        <img alt="dashboardIcon" src={this.dashboardIcon}></img>Dashboard
                                    </Nav.Link>
                                </Nav.Item> }
                                    {this.isUserAuthorized(routePaths.admin) &&
                                        <Nav.Item>
                                            <Nav.Link id={GSEDisplayTextConst.dashboardLayoutId.adminId} href={routePaths.admin}>
                                                <img alt="adminIcon" src={this.adminIcon}></img>Admin
                                            </Nav.Link>
                                        </Nav.Item>}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Sidebar>
            <Button icon="pi pi-list" onClick={() => this.setState({visible: true})} />
        </div>
        );
    }
}

