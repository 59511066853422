import { ApplicationState } from '.';
import initialState from './initialState';

class StateLoader {
    public loadState() {
        try {
            const serializedState = sessionStorage.getItem('travel.apps.globalservices.com:state');

            if (serializedState === null) {
                return initialState;
            }

            const persistedState: ApplicationState = JSON.parse(serializedState);
            persistedState.internalUser.msalReady = initialState.internalUser.msalReady;
            const mergedState = Object.assign({}, initialState, persistedState, initialState.flags);

            return mergedState;
        } catch (err) {
            console.error(err);
            return initialState;
        }
    }

    public saveState(state: ApplicationState) {
        try {
            const serializedState = JSON.stringify(state);
            sessionStorage.setItem('gse.apps.globalservices.com:state', serializedState);
        } catch (err) {
            console.error(err);
        }
    }
}

export default StateLoader;
