import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { FlagsActions } from '../../store/flags';
import { DashboardTable } from '../../shared/components/dynamic-data-table/dynamic-data-table';
import { HeadCell } from '../../shared/models/HeadCell';
import { DataTableFilterMeta, DataTableStateEvent } from 'primereact/datatable';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DashboardActions, DashboardState } from '../../store/dashboard';
import { DashboardRequest, DashboardViewModel, Filters, PaginationInfo } from '../../shared/models/dashboard';
import { DashboardPOViewModel } from '../../shared/models/DashboardPO';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import DashboardHelper from '../../helpers/DashboardHelper';
import { ColumnTypeEnum } from '../../shared/models/ColumnTypeEnum';
import UploadDownloadHelper from '../../helpers/UploadDownloadHelper';
import { DateHelper } from '../../helpers';
import PieChart from '../../shared/components/pie-chart/pieChart';
import { MultiSelect } from 'primereact/multiselect';
import html2canvas from 'html2canvas';
import pdfConverter from 'jspdf';


interface ComponentProps {
  isAuthenticated?: boolean;
  dashboardPO?: DashboardState;
  filters?: DataTableFilterMeta;


  isDashboardLayoutEnable?: () => void;
  resetDashboardData?: () => void;
  getDashboardPO?: (request: any) => void;
  setFilterMetaData?: (dataTableFilterMeta: DataTableFilterMeta | undefined) => void;
  resetDownloadDashboardData?: () => void;
  getSpecificFieldDataWithRequest?: (columnName: string, dataType: ColumnTypeEnum | undefined, filters?: Filters[] | undefined) => void;
  isFetchingDashboard?: (isFetching: boolean) => void;
  getPieChart?: (FromScreen: string, request: any) => any;
}

interface ComponentState {
  selectedDates: any[] | undefined,
  pieChartData: any[] | undefined,
  labels: any[] | undefined,
  dates: any[] | undefined,
  selectedStations: string[],
  fromStatus: string,
  statusText: string
}

type DashboardProps =
  ComponentProps
  & typeof mapDispatchToProps;

class Dashboard extends Component<DashboardProps, ComponentState> {
  minRecordCount: number = DashboardHelper.getMinRecordCount();
  stationColumnName = 'station';

  state = {
    selectedDates: undefined,
    pieChartData: undefined,
    labels: undefined,
    dates: undefined,
    selectedStations: [],
    fromStatus: '',
    statusText: ''
  };

  constructor(props: DashboardProps) {
    super(props);
    if (props.isDashboardLayoutEnable)
      props.isDashboardLayoutEnable();
    if (props.resetDashboardData)
      props.resetDashboardData();

    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.onDataTableFilter = this.onDataTableFilter.bind(this);
    this.setFilterMetaData = this.setFilterMetaData.bind(this);
    this.getSpecificFieldData = this.getSpecificFieldData.bind(this);
    this.onSort = this.onSort.bind(this);
    this.downloadClickEvent = this.downloadClickEvent.bind(this);
    this.getAdvancedFilters = this.getAdvancedFilters.bind(this);
    this.getPieChartData = this.getPieChartData.bind(this);
    this.exportToPDF = this.exportToPDF.bind(this);
    //this.filterOnshow = this.filterOnshow.bind(this);
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.getSpecificFieldData(this.stationColumnName);
    }, 100);
  }

  getDashboardPO() {
    const { dashboardPO } = this.props;

    if (this.props?.getDashboardPO && dashboardPO?.dashboardRequest && !this.props.dashboardPO?.isFetching) {
      this.props.getDashboardPO(dashboardPO?.dashboardRequest);
    }
  }

  getPieChartData(fromStatus: string) {
    let chartData: string[] = [];
    let chartLabel: string[] = [];
    let selectedDates: any = this.state.dates;
    this.setState({fromStatus: fromStatus});
    this.setState({statusText: fromStatus.replace(/.{6}$/, ' $&')});

    let request: any;

    request = {
      'stationCodes': this.state.selectedStations?.length ? this.state.selectedStations : null,
      'startDate': selectedDates?.length && selectedDates[0] ? DateHelper.formatDate(selectedDates[0]) : null,
      'endDate': selectedDates?.length && selectedDates[1] ? DateHelper.formatDate(selectedDates[1]) : null
    };

    if (this.props.getPieChart) {
      this.props.getPieChart(fromStatus, request).then((responseData: any) => {
        if (responseData) {
          chartData = Array.from(new Set(responseData.map((item: any) => item.statusCount)));
          chartLabel = Array.from(new Set(responseData.map((item: any) => item.status)));
          this.setState({ pieChartData: chartData });
          this.setState({ labels: chartLabel });
        }
      });
    }
  }

  exportToPDF(e: any) { 
    const but = e.target;
    //but.style.display = 'none';  
    let input = document.getElementById('pieChart');

    if (input) {
      html2canvas(input).then(canvas => {
        const img = canvas.toDataURL('image/png');
        const pdf = new pdfConverter('l', 'pt');
        pdf.addImage(img,'png',15,0,0,0);
        pdf.save(this.state.fromStatus + '.pdf');
        but.style.display = 'block';
      });
    }
  }

  // define columns
  columns: HeadCell<DashboardPOViewModel>[] = [
    { name: 'tag', header: 'Tag', dataType: ColumnTypeEnum.tag, isSortable: false, filter: false },
    { name: 'contractCode', header: 'Contract Code' },
    { name: 'items', header: 'Item' },
    { name: 'contractStartDate', header: 'Contract Start Date', dataType: ColumnTypeEnum.date },
    { name: 'manufacturingReadyDate', header: 'Manufacturing Ready Date', dataType: ColumnTypeEnum.date },
    { name: 'deliveryDate', header: 'Delivery Date', dataType: ColumnTypeEnum.date },
    { name: 'poNumber', header: 'PO#' },
    { name: 'assetNumber', header: 'Asset#' },
    { name: 'acType', header: 'AC Type' },
    { name: 'station', header: 'Station' },
    { name: 'vendorName', header: 'Vendor Name' },
    { name: 'shippingStatus', header: 'Shipping Status', dataType: ColumnTypeEnum.shippingStatus },
    { name: 'make', header: 'Make' },
    { name: 'model', header: 'Model' },
    { name: 'serial', header: 'Serial Number' },
    { name: 'qty', header: 'Quantity' },
  ];

  onPaginationChange(event: PaginatorPageChangeEvent) {
    const { dashboardPO } = this.props;
    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.pagination = pagination;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  onSort(event: DataTableStateEvent) {
    const { dashboardPO } = this.props;

    const sortOptions = DashboardHelper.getSortingOptions(event.sortField, event.sortOrder);
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.sorting = sortOptions;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  onDataTableFilter(event: DataTableStateEvent) {
    if (this.props.setFilterMetaData)
      this.props.setFilterMetaData(event.filters && event.filters);
    const selectedDates: any = this.state?.selectedDates;
    let filters: Filters[] = DashboardHelper.setFilterData(event.filters, this.columns);

    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);

    const { dashboardPO } = this.props;

    if (dashboardPO?.dashboardRequest) {
      filters = this.getStationFilter(filters);
      dashboardPO.dashboardRequest.startDate = selectedDates?.length && selectedDates[0] ? DateHelper.formatDate(selectedDates[0]) : undefined;
      dashboardPO.dashboardRequest.endDate = selectedDates?.length && selectedDates[1] ? DateHelper.formatDate(selectedDates[1]) : undefined;
      dashboardPO.dashboardRequest.pagination = pagination;
      dashboardPO.dashboardRequest.filters = filters;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  getAdvancedFilters() {
    const selectedDates: any = this.state?.selectedDates;
    const { dashboardPO } = this.props;

    if (selectedDates?.length !== 2 && this.state.selectedStations?.length <= 0) {
      return;
    }

    if (dashboardPO?.dashboardRequest) {
      let filters = dashboardPO.dashboardRequest.filters ?? [];
      filters = this.getStationFilter(filters);

      dashboardPO.dashboardRequest.startDate = selectedDates?.length && selectedDates[0] ? DateHelper.formatDate(selectedDates[0]) : undefined;
      dashboardPO.dashboardRequest.endDate = selectedDates?.length && selectedDates[1] ? DateHelper.formatDate(selectedDates[1]) : undefined;
      dashboardPO.dashboardRequest.filters = filters;
      dashboardPO.dashboardRequest.isDownload = false;
    }

    this.getDashboardPO();
  }

  private getStationFilter(filters: Filters[] | undefined) {
    if (filters) {
      const stationFilter = this.getFilterValueWithAdvancedData();
      filters = filters.filter(x => x.columnName !== this.stationColumnName);
      if (stationFilter) {
        filters.push(stationFilter);
      }
      return filters;
    }
    return [];
  }

  private getFilterValueWithAdvancedData() {
    if (this.state?.selectedStations?.length > 0) {
      const filterObj: Filters = {
        columnName: this.stationColumnName,
        values: this.state.selectedStations ?? []
      };
      return filterObj;
    }
    return undefined;
  }

  setFilterMetaData(filterMetaData?: DataTableFilterMeta) {
    const { dashboardPO } = this.props;
    if (this.props?.setFilterMetaData && filterMetaData) {
      this.props.setFilterMetaData(filterMetaData);

      let filters: Filters[] = DashboardHelper.setFilterData(filterMetaData, this.columns);

      if (dashboardPO?.dashboardRequest) {
        dashboardPO.dashboardRequest.filters = filters;
        dashboardPO.dashboardRequest.isDownload = false;
      }
      setTimeout(() => {
        this.getDashboardPO();
      }, 100);
    }
  }

  getSpecificFieldData(columnName: string) {
    const currentColumn = this.columns.find(x => x.name === columnName);
    const { dashboardPO } = this.props;

    if (this.props?.getSpecificFieldDataWithRequest && dashboardPO?.dashboardRequest) {
      this.props.getSpecificFieldDataWithRequest(columnName, currentColumn?.dataType, dashboardPO?.dashboardRequest?.filters);
    }
  }

  downloadClickEvent() {
    const { dashboardPO } = this.props;
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.isDownload = true;
    }
    this.getDashboardPO();
  }

  private downloadExcelAfterResponse(dashboardPO: DashboardState | undefined) {
    setTimeout(() => {
      if (!dashboardPO?.downloadUploadResponse.downloadLoading && dashboardPO?.downloadUploadResponse?.dashboardResponse) {
        const response = dashboardPO?.downloadUploadResponse.dashboardResponse;
        let downloadItems: any[] = [];
        if (response?.data) {
          downloadItems = response.data.map((x: any) => (UploadDownloadHelper.setDownloadObject(x, this.columns)));
        }
        UploadDownloadHelper.exportExcel<DashboardViewModel>(downloadItems, 'dashboard');
        if (this.props.resetDownloadDashboardData)
          this.props.resetDownloadDashboardData();
      }
    }, 50);
  }

  /**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
  render() {
    const { dashboardPO } = this.props;
    const request = dashboardPO?.dashboardRequest;
    const response = dashboardPO?.dashboardResponse;
    const filters = dashboardPO?.filters;
    const sortOptions = request?.sorting;
    const dropdownOptions = dashboardPO?.dropdownOptions;
    const downloadUploadResponse = dashboardPO?.downloadUploadResponse;
    const selectedDates: any = this.state?.selectedDates;

    const paginationOptions: PaginationInfo = DashboardHelper.getPaginationOptions(request, response?.totalCount);
    const formattedRecords = response?.data?.map((x: any) => (UploadDownloadHelper.setDashboardViewModelObject(x, this.columns)));
    this.downloadExcelAfterResponse(dashboardPO);

    return (
      <div className='card m-0 dashboard'>
        <h6 className='card-title ml-3 mt-2'>Dashboard</h6>
        <TabView>
          <TabPanel header='Dashboard'>
            <div className='dashboard-container'>
              <DashboardTable columns={this.columns} rows={formattedRecords ?? []}
                loading={this.props.dashboardPO?.isFetching ?? false}
                paginationOptions={paginationOptions}
                filters={filters}
                sortOptions={sortOptions}
                minRecordCount={this.minRecordCount}
                onPaginationChange={this.onPaginationChange}
                onSort={this.onSort}
                dropdownOptions={dropdownOptions}
                onDataTableFilter={this.onDataTableFilter}
                setFilterMetaData={this.setFilterMetaData}
                getSpecificFieldData={this.getSpecificFieldData}
                isSendNotification={false}
                scrollHeight='61vh'>
                <div className='flex justify-content-end mr-2'>
                  <span className='p-float-label'>
                    <Calendar className='mr-2' value={this.state.selectedDates}
                      onChange={(e) => this.setState({ selectedDates: e.value as any[] })} selectionMode='range' />
                    <label htmlFor='date_range'>Date range</label>
                  </span>
                  <Button type='button' label='Apply' onClick={this.getAdvancedFilters}
                    disabled={selectedDates?.length !== 2} />
                  <Button className='ml-2' type='button' loading={downloadUploadResponse?.downloadLoading} label='Download' onClick={this.downloadClickEvent} />
                </div>
              </DashboardTable>
            </div>
          </TabPanel>
          <TabPanel header='PieChart'>
            <div>
              <div className='flex justify-content-end mt-3 mx-2'>
                <span className='p-float-label'>
                  <Calendar className='mr-2' value={this.state.dates} onChange={(e) => this.setState({ dates: e.value as any[] })} selectionMode='range' />
                  <label htmlFor='birth_date'>Date range</label>
                </span>
                <MultiSelect value={this.state.selectedStations} filter
                  onChange={(e) => this.setState({ selectedStations: e.value })}
                  options={DashboardHelper.getDropDownOptions(this.stationColumnName, dropdownOptions)} optionLabel='label'
                  placeholder='Select stations' maxSelectedLabels={3} className='w-full md:w-15rem mr-2' />
                <Button type='button' label='Apply' onClick={() => this.getPieChartData('POStatus')} />
                <Button type='button' className='ml-2' label='Download' onClick={(e) => this.exportToPDF(e)} />

              </div>
              <hr />
              <PieChart getPieChart={this.getPieChartData} pieChartData={this.state.pieChartData} labels={this.state.labels} statusText={this.state.statusText} />
            </div>
          </TabPanel>
        </TabView>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
  const { internalUser: auth, dashboard } = state;

  return {
    ...auth,
    dashboardPO: dashboard
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
  isDashboardLayoutEnable: () => dispatch(FlagsActions.isDashboardLayoutEnable()),
  getDashboardPO: (request: DashboardRequest) => {
    return dispatch(DashboardActions.getDashboardPO(request));
  },
  setFilterMetaData: (filters?: DataTableFilterMeta | undefined) => dispatch(DashboardActions.setFilterMetaData(filters)),
  resetDashboardData: () => dispatch(DashboardActions.resetDashboardData()),
  getSpecificFieldDataWithRequest: (columnName: string, columnType: ColumnTypeEnum | undefined, filters?: Filters[] | undefined) =>
    dispatch(DashboardActions.getSpecificFieldDataWithRequest(columnName, columnType, filters)),
  isFetchingDashboard: DashboardActions.isFetchingDashboard,
  resetDownloadDashboardData: () => dispatch(DashboardActions.resetDownloadDashboardData()),
  getPieChart: (forStatus: string, request: any) => {
    return dispatch(DashboardActions.getPieChart(forStatus, request));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard as any);
