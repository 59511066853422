import React, { Component, Dispatch } from 'react';
import DynamicCrudComponent from '../../../shared/components/dynamic-crud-component/dynamic-crud-component';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';
import { Link } from 'react-router-dom';
import routePaths from '../../../shared/constants/routePaths';
import { DashboardRequest } from '../../../shared/models/dashboard';
import { AdminActions } from '../../../store/admin';
import { HeadCell } from '../../../shared/models/HeadCell';
import { UserViewModel } from '../../../shared/models/UserViewModel';
import AdminScreenColumnHelper from '../../../helpers/AdminScreenColumnHelper';
import { AdminCRUDTypeEnum } from '../../../shared/models/Enums/AdminCRUDTypeEnum';
import { AdminOperationsEnum } from '../../../shared/models/Enums/AdminOperationsEnum';

interface ComponentProps {
    getDashboard?: (Request: DashboardRequest) => any;
    updateUser?: (request: any) => any;
    deleteUser?: (request: string) => any;
    addUser?: (request: string) => any;
}

class UserContainer extends Component<ComponentProps> {

    columns: HeadCell<UserViewModel>[] = AdminScreenColumnHelper.getColumns(AdminCRUDTypeEnum.user, AdminOperationsEnum.viewAll);

    render() {
        return (
            <div className="card m-0 flex">
                <h6 className="card-title mx-3 mt-2"><Link className='mr-2' to={routePaths.admin}>Admin</Link>{'> View All User'}</h6>
                <TabView>
                    <TabPanel header="User Management">
                        <div style={{ position: 'relative', width: '100%', height: '95%' }}>
                            <DynamicCrudComponent columns={this.columns}
                                getDashboard={this.props.getDashboard}
                                downloadFilePrefix='user_list'
                                editDialogHeader='User'
                                deleteApi={this.props.deleteUser}
                                updateApi={this.props.updateUser}
                                adminPageType={AdminCRUDTypeEnum.user}
                                addApi={this.props.addUser}
                            ></DynamicCrudComponent>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
    const { internalUser: auth, dashboard } = state;

    return {
        ...auth,
        dashboardPO: dashboard
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
    getDashboard: (request: DashboardRequest) => {
        return dispatch(AdminActions.getAllUser(request));
    },
    updateUser: (request: any) => dispatch(AdminActions.updateUser(request)),
    deleteUser: (request: string) => dispatch(AdminActions.deleteUser(request)),
    addUser: (request: string) => dispatch(AdminActions.addUser(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer as any);

