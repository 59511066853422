import React, { Component, Dispatch, RefObject } from 'react';
import './VendorSignIn.scss';
import { Button, Form } from 'react-bootstrap';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { ExternalUserActions } from '../../store/externalUser';
import { Toast } from 'primereact/toast';
import routePaths from '../../shared/constants/routePaths';

interface ComponentProps {
    params?: string;
    ResetPassword?: (request: any) => any;
}

type ChangePasswordProps =
    ComponentProps
    & typeof mapDispatchToProps;

interface IState {
    pwd: string,
    confirmPwd: string,
    shouldShowToast: boolean,
}

class ChangePassword extends Component<ChangePasswordProps, IState>  {
    responseData: any;
    toast: RefObject<Toast> | undefined = undefined;

    constructor(props: Readonly<ChangePasswordProps>) {
        super(props);
        this.toast = React.createRef();
        this.state = {
            pwd: '',
            confirmPwd: '',
            shouldShowToast: false
        };
        this.setPassword = this.setPassword.bind(this);
    }

    setPassword(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const params = new URLSearchParams(this.props.params);
        const id = params.get('id') ?? '';
        const activate = params.get('activate') ?? '';
        if (this.state.pwd === this.state.confirmPwd) {
            var userData = {
                userId: id,
                confirmPassword: this.state.confirmPwd,
                newPassword: this.state.pwd,
                activationCode: activate
            };
            if (this.props.ResetPassword) {
                this.props.ResetPassword(userData).then((response: any) => {
                    if (response) {
                        if (response === 'Password changed successfully') {
                            this.showStickyToast('success', response, true);
                            window.location.href = routePaths.VendorSignIn;                           
                        }
                        else {
                            this.showStickyToast('warn', response, true);
                        }
                    }
                });
            }
        }
        else {
            this.showStickyToast('error', 'Please enter same password', true);
        }
    }
    showStickyToast(severity: 'info' | 'success' | 'warn' | 'error' | undefined, message: string, isResponseMessage: boolean = false) {
        setTimeout(() => {
            this.toast?.current?.show({ severity: severity, summary: 'Notification Process', detail: message, sticky: false });
            if (isResponseMessage) {
                this.setState({ shouldShowToast: false });
            }
        }, 20);
    }
    render() {
        return (
            <React.Fragment>
                <Toast ref={this.toast} />
                <Form className="rounded p-5 p-sm-4" onSubmit={($event) => this.setPassword($event)}>
                    <a href="/vendor-sign-in">Back</a>
                    <div className="Login-header text-center">Pick A Password</div><br />
                    <Form.Group className="mb-2 alignLeft">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type="password" defaultValue={this.state.pwd}
                            onChange={e => this.setState({ pwd: e.target.value })}
                            className="border-0 border-bottom border-dark rounded-0 txt"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-2 alignLeft">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" defaultValue={this.state.confirmPwd}
                            onChange={e => this.setState({ confirmPwd: e.target.value })}
                            className="border-0 border-bottom border-dark rounded-0 txt"></Form.Control>
                    </Form.Group>
                    <Button type="submit" className="vendor-sign-in">Save Password
                    </Button>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
    return {
        applicationState: state
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
    ResetPassword: (request: any) => dispatch(ExternalUserActions.ResetPassword(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword as any); 