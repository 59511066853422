import { Dispatch } from 'react';
import { ApplicationState } from '..';
import { FlagsActionTypes } from './types';
// import { StringHelper } from "../../helpers";

interface FlagsReady {
    type: FlagsActionTypes.FLAGS_READY;
}

interface UpdateFlag {
    payload: {
        name: string;
        enabled: boolean;
    };
    type: FlagsActionTypes.UPDATE_FLAG;
}

interface IsDashboardLayoutEnable {
    type: FlagsActionTypes.ISDASHBOARDLAYOUT_SET;
}

interface IsDashboardLayoutDisable {
    type: FlagsActionTypes.ISDASHBOARDLAYOUT_RESET;
}

export type FlagsAction = UpdateFlag | FlagsReady | IsDashboardLayoutEnable | IsDashboardLayoutDisable;

export const setFlagsReady = () => {
    return (dispatch: Dispatch<FlagsAction>, _: () => ApplicationState) => {
        dispatch({
            type: FlagsActionTypes.FLAGS_READY,
        });
    };
};

export const updateFlag = (name: string, enabled: boolean) => {
    return (dispatch: Dispatch<FlagsAction>, _: () => ApplicationState) => {
        const camelCasedName = name.charAt(0).toLowerCase() + name.slice(1);
        dispatch({
            payload: {
                name: camelCasedName,
                enabled,
            },
            type: FlagsActionTypes.UPDATE_FLAG,
        });
    };
};

export const isDashboardLayoutEnable = () => {
    return (dispatch: Dispatch<FlagsAction>, _: () => ApplicationState) => {
        dispatch({
            type: FlagsActionTypes.ISDASHBOARDLAYOUT_SET
        });
    };
};

export const disableDashboardLayout = () => {
    return (dispatch: Dispatch<FlagsAction>, _: () => ApplicationState) => {
        dispatch({
            type: FlagsActionTypes.ISDASHBOARDLAYOUT_RESET
        });
    };
};
