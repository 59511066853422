import React, { Component, Dispatch } from 'react';
import DynamicCrudComponent from '../../../shared/components/dynamic-crud-component/dynamic-crud-component';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';
import { Link } from 'react-router-dom';
import routePaths from '../../../shared/constants/routePaths';
import { DashboardRequest } from '../../../shared/models/dashboard';
import { AdminActions } from '../../../store/admin';
import { HeadCell } from '../../../shared/models/HeadCell';
import { AdminTemplateViewModel } from '../../../shared/models/AdminTemplateViewModel';
import AdminTemplateColumnHelper from '../../../helpers/AdminTemplateColumnHelper';
import { AdminOperationsEnum } from '../../../shared/models/Enums/AdminOperationsEnum';
import { AdminCRUDTypeEnum } from '../../../shared/models/Enums/AdminCRUDTypeEnum';

interface ComponentProps {
    getTemplate?: (Request: DashboardRequest) => any;
    updateTemplate?: (request: any) => any;
    deleteTemplate?: (request: string) => any;
    addTemplate?: (request: string) => any;
}

class AdminTemplateContainer extends Component<ComponentProps> {

    columns: HeadCell<AdminTemplateViewModel>[] = AdminTemplateColumnHelper.getColumns(AdminOperationsEnum.viewAll);


    render() {
        return (
            <div className="card m-0 flex">
                <h6 className="card-title mx-3 mt-2"><Link className='mr-2' to={routePaths.admin}>Admin</Link>{'> View All Template'}</h6>
                <TabView>
                    <TabPanel header="Template Management">
                        <div style={{ position: 'relative', width: '100%', height: '95%' }}>
                            <DynamicCrudComponent columns={this.columns}
                                getDashboard={this.props.getTemplate}
                                downloadFilePrefix='Template_list'
                                editDialogHeader='Template'
                                deleteApi={this.props.deleteTemplate}   
                                updateApi={this.props.updateTemplate}
                                adminPageType={AdminCRUDTypeEnum.template}
                                addApi={this.props.addTemplate}
                            />
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
    const { internalUser: auth, dashboard } = state;

    return {
        ...auth,
        dashboardPO: dashboard
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
    getTemplate: (request: DashboardRequest) => {
        return dispatch(AdminActions.getAllTemplate(request));
    },
    updateTemplate: (request: any) => dispatch(AdminActions.updateTemplate(request)),
    deleteTemplate: (request: string) => dispatch(AdminActions.deleteTemplate(request)),
    addTemplate: (request: string) => dispatch(AdminActions.addTemplate(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTemplateContainer as any);

