/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
import * as React from 'react';
import { connect } from 'react-redux';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import 'whatwg-fetch';
import { appInsights, appSettings } from '.';
import { ApplicationState } from './store';
import { AppLoader } from './components/layout';
import { AuthActions } from './store/auth';

interface ComponentProps {
    idToken: any;
    msalReady: boolean;
    children?: JSX.Element;
}

type AppProps =
    ComponentProps
    & typeof mapDispatchToProps;

interface ComponentState {
    hasError: boolean;
    showDetails: boolean;
    exception?: {
        error?: any;
        info?: any;
    };
}

class App extends React.Component<AppProps, ComponentState> {
    constructor(props: Readonly<AppProps>) {
        super(props);

        this.state = {
            hasError: false,
            showDetails: false,
        };

        this.onViewRoles = this.onViewRoles.bind(this);
    }

    public componentDidMount() {
        this.props.initMsal(appSettings.authentication.clientId);
    }

    public componentDidUpdate(prevProps: AppProps) {
        const { idToken } = this.props;
        if (idToken !== prevProps.idToken && idToken && idToken.hasOwnProperty('oid')) {
            appInsights?.setAuthenticatedUserContext(idToken.oid);
        }
    }

    public componentDidCatch(error: Error | null, info: object) {
        console.error(error);
        console.error(info);
        this.setState({
            hasError: true,
            exception: {
                error,
                info,
            },
        });

        appInsights?.trackException({
            exception: error || new Error('UNKNOWN_ERROR'),
            severityLevel: SeverityLevel.Critical,
        });
    }

    public render() {
        const { msalReady, children } = this.props;
        const { hasError} = this.state;

        if (!msalReady) {
            return <AppLoader />;
        }

        return (
            <>
                    {hasError ? (                        
                        <React.Fragment>Error in App Page</React.Fragment>
                    ) : (
                        <React.Fragment>
                        {children}
                        </React.Fragment>
                        )
                    }
            </>
        );
    }

    private _onShowDetailsClick = () => {
        this.setState((prevState: ComponentState) => {
            return {
                ...prevState,
                showDetails: !prevState.showDetails,
            };
        });
    };

    private onViewRoles(_: any) {
        console.log(this.props.idToken);
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): AppProps => {
    const { internalUser: auth } = state;

    return {
        ...auth,
        ...ownProps,
    };
};

const mapDispatchToProps = {
    initMsal: AuthActions.initMsalAgent
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
