import * as React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.scss';
import routePaths from '../../../shared/constants/routePaths';

interface ComponentProps {
}

const appLogo = require('../../../assets/applogo.png');

class Header extends React.Component<ComponentProps> {

    async componentDidMount() {
        this.update();
        window.addEventListener('resize', this.update.bind(this));
    }
    
    private update = () => {
        this.setState({ userScreenWidth: window.innerWidth });
    };


    public render() {
        return (
            <React.Fragment>
                <div className="backgroundImg">
                    <div className="web-app-bar d-flex row mt-2">
                        <div className="appLogoContainer col-2">
                            <NavLink className="appTitle" to={routePaths.root}>
                                <img className="appLogo" alt="Unifi Icon" src={appLogo} />
                            </NavLink>
                        </div>
                        <div className="appTitle col-3">
                            <NavLink className="appTitle" to={routePaths.root}>
                                <span className="appTitle">GSE Tracker APP</span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }    
}

export default Header;
