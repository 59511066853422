import React, { Component, Dispatch, RefObject } from 'react';
import { AuthActions } from '../../store/auth';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { DashboardTable } from '../../shared/components/dynamic-data-table/dynamic-data-table';
import { HeadCell } from '../../shared/models/HeadCell';
import { DataTableFilterMeta, DataTableStateEvent, DataTableValue } from 'primereact/datatable';
import { DashboardActions, DashboardState } from '../../store/dashboard';
import { DashboardRequest, Filters, PaginationInfo } from '../../shared/models/dashboard';
import DashboardHelper from '../../helpers/DashboardHelper';
import { ColumnTypeEnum } from '../../shared/models/ColumnTypeEnum';
import { ContractTileViewModel } from '../../shared/models/ContractTileViewModel';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import UploadDownloadHelper from '../../helpers/UploadDownloadHelper';
import { Sidebar } from 'primereact/sidebar';
import { DashboardEdit } from '../../shared/components/dashboardEdit/dashboardEdit';
import { ContractTileUpdateRequest } from '../../shared/models/ContractTileUpdateRequest';
import { DateHelper } from '../../helpers';
import { Toast } from 'primereact/toast';
import NotificationPopup from './notificationPopup';
import { sendNotification } from '../../shared/models/SendNotificationModel';

interface ComponentProps {
  isAuthenticated?: boolean;
  dashboardPO?: DashboardState;
  filters?: DataTableFilterMeta;
  requestedPageReset?: () => void;
  selectedColumn?: { columnName: string; columnValue: string; };
  setFilterMetaData?: (dataTableFilterMeta: DataTableFilterMeta | undefined) => void;
  getSpecificFieldDataWithRequest?: (columnName: string, dataType: ColumnTypeEnum | undefined, filters?: Filters[] | undefined) => void;
  getDropdownDataByColumnName?: (columnName: string, dataType: ColumnTypeEnum | undefined) => void;
  resetPOContractTiledData?: () => void;
  getPOTileView?: (request: DashboardRequest) => void;
  updatePOContract?: (request: ContractTileUpdateRequest) => any;
  getNotificationTemplate?: (FromScreen: string) => any;
  sendNotificationEmail?: (FromScreen: any) => any;
}

type DashboardProps =
  ComponentProps
  & typeof mapDispatchToProps;

class POContractTile extends Component<DashboardProps> {
  minRecordCount: number = DashboardHelper.getMinRecordCount();
  toast: RefObject<Toast> | undefined = undefined;

  state = {
    showPOContractSidebar: false,
    poContractEditData: undefined,
    shouldShowToast: false,
    isNotificationDialogVisible: false,
    selectedData: undefined,
    templateData: undefined,
  };

  constructor(props: DashboardProps) {
    super(props);

    this.toast = React.createRef();

    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.onDataTableFilter = this.onDataTableFilter.bind(this);
    this.setFilterMetaData = this.setFilterMetaData.bind(this);
    this.getSpecificFieldData = this.getSpecificFieldData.bind(this);
    this.getDropdownDataByColumnName = this.getDropdownDataByColumnName.bind(this);
    this.onSort = this.onSort.bind(this);
    this.renderPOContractEditSidebar = this.renderPOContractEditSidebar.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.getNotificationTemplate = this.getNotificationTemplate.bind(this);
    this.renderSendNotificationDialog = this.renderSendNotificationDialog.bind(this);
    this.onClose = this.onClose.bind(this);
    if (this.props.resetPOContractTiledData)
      this.props.resetPOContractTiledData();
    this.sendNotification = this.sendNotification.bind(this);
    this.sendVendorNotificationEmail = this.sendVendorNotificationEmail.bind(this);
  }

  getDashboardPO() {
    const { dashboardPO, selectedColumn } = this.props;

    if (selectedColumn) {
      const poStatusFilter: Filters[] = DashboardHelper.setFilterByNameAndValue(selectedColumn.columnName, selectedColumn.columnValue);

      if (dashboardPO?.poGridData?.poGridRequest) {
        if (!dashboardPO.poGridData.poGridRequest?.filters) {
          dashboardPO.poGridData.poGridRequest['filters'] = [];
        }
        dashboardPO.poGridData.poGridRequest.filters = dashboardPO.poGridData.poGridRequest.filters.concat(...poStatusFilter);
      }
    }

    if (this.props?.getPOTileView && dashboardPO?.poGridData?.poGridRequest && !this.props?.dashboardPO?.isFetching) {
      this.props.getPOTileView(dashboardPO.poGridData.poGridRequest);
    }
  }

  // define columns
  columns: HeadCell<ContractTileViewModel>[] = [
    { name: 'edit', header: 'Edit', isSortable: false, filter: false, dataType: ColumnTypeEnum.edit },
    // { name: 'contractCode', header: 'Contract Code' },
    { name: 'contractStartDate', header: 'Contract Start Date', dataType: ColumnTypeEnum.date, isEditable: true },
    { name: 'acType', header: 'AC Type', isEditable: true },
    { name: 'vendorName', header: 'Vendor Name' },
    { name: 'poNumber', header: 'PO#' },
    { name: 'items', header: 'Item' },
    { name: 'assetNumber', header: 'Asset Number', isEditable: true },
    { name: 'make', header: 'Make', isEditable: true },
    { name: 'model', header: 'Model', isEditable: true },
    { name: 'serial', header: 'Serial Number', isEditable: true },
    { name: 'qty', header: 'Quantity' },
    { name: 'unitStatus', header: 'Equipment Ready status', isEditable: true },
    { name: 'shippingStatus', header: 'Shipping Status', isEditable: true, dataType: ColumnTypeEnum.shippingStatus },
    { name: 'newReadyDate', header: 'New Ready Date', dataType: ColumnTypeEnum.date, isEditable: true },
    { name: 'gap', header: 'GAP', isSortable: false, filter: false },
    { name: 'shippingETA', header: 'Shipping ETA', dataType: ColumnTypeEnum.date, isEditable: true },
    { name: 'transporter', header: 'Shipper', isEditable: true },
    { name: 'trDate', header: 'TR Date', dataType: ColumnTypeEnum.date, isEditable: true },
    { name: 'comments', header: 'Comments', isEditable: true },
    { name: 'sendNotification', header: '', isSortable: false, filter: false, selectionMode: this.props.selectedColumn?.columnName === 'poNumber'? 'multiple' :'none'}
  ];

  onPaginationChange(event: PaginatorPageChangeEvent) {
    const { dashboardPO } = this.props;
    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);
    if (dashboardPO?.poGridData?.poGridRequest) {
      dashboardPO.poGridData.poGridRequest.pagination = pagination;
      dashboardPO.poGridData.poGridRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  onSort(event: DataTableStateEvent) {
    const { dashboardPO } = this.props;

    const sortOptions = DashboardHelper.getSortingOptions(event.sortField, event.sortOrder);
    if (dashboardPO?.poGridData?.poGridRequest) {
      dashboardPO.poGridData.poGridRequest.sorting = sortOptions;
      dashboardPO.poGridData.poGridRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  onDataTableFilter(event: DataTableStateEvent) {
    if (this.props.setFilterMetaData)
      this.props.setFilterMetaData(event.filters);

    const filters: Filters[] = DashboardHelper.setFilterData(event.filters, this.columns);

    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);

    const { dashboardPO } = this.props;

    if (dashboardPO?.poGridData?.poGridRequest) {
      dashboardPO.poGridData.poGridRequest.filters = filters;
      dashboardPO.poGridData.poGridRequest.pagination = pagination;
      dashboardPO.poGridData.poGridRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  setFilterMetaData(filterMetaData?: DataTableFilterMeta) {
    const { dashboardPO } = this.props;
    if (this.props?.setFilterMetaData) {
      this.props.setFilterMetaData(filterMetaData);

      const filters: Filters[] = DashboardHelper.setFilterData(filterMetaData, this.columns);

      if (dashboardPO?.poGridData?.poGridRequest) {
        dashboardPO.poGridData.poGridRequest.filters = filters;
        dashboardPO.poGridData.poGridRequest.isDownload = false;
      }
      setTimeout(() => {
        this.getDashboardPO();
      }, 100);
    }
  }

  getSpecificFieldData(columnName: string) {
    const currentColumn = this.columns.find(x => x.name === columnName);
    const { dashboardPO } = this.props;

    if (this.props?.getSpecificFieldDataWithRequest && dashboardPO?.dashboardRequest) {
      this.props.getSpecificFieldDataWithRequest(columnName, currentColumn?.dataType, dashboardPO?.poGridData?.poGridRequest?.filters);
    }
  }

  getDropdownDataByColumnName(columnName: string) {
    const currentColumn = this.columns?.find(x => x.name === columnName);

    if (this.props?.getDropdownDataByColumnName) {
      this.props.getDropdownDataByColumnName(columnName, currentColumn?.dataType);
    }
  }

  onEditClick(data: DataTableValue) {
    this.setState({ poContractEditData: data });
    this.setState({ showPOContractSidebar: true });
  }

  sendNotification(selectData: any) {
    if (selectData === undefined || selectData === null || selectData === '') {
      this.showNotificationStickyToast('error', 'Please select PO line item.', true);
    }
    else {
      this.getNotificationTemplate();
      this.setState({ selectedData: selectData });
      this.setState({ isNotificationDialogVisible: true });
    }
  }

  sendVendorNotificationEmail(notificationData: any, poData: any) {
    let poNum: string[] = [];
    let poId: string[] = [];
    if (notificationData !== undefined || notificationData !== null || notificationData !== '' ||
      poData !== undefined || poData !== '' || poData !== null
    ) {
        poNum = Array.from(new Set(poData.map((item: any) => item.poNumber)));
        poId = Array.from(new Set(poData.map((item: any) => item.polId)));
        const updateRequest: sendNotification = {
          //notificationDetails: [],
          notificationSubject: notificationData.emailSubject,
          notificationBody: notificationData.emailBody,
          //vendorName: poData[0].vendorName,
          poNumber: poNum,
          polId: poId,
          //poDate: poData[0].poDate,
          templateName: notificationData.name,
          emailSender: notificationData.emailSender,
          emailCc: notificationData.emailCc,
          fromScreen: 'PO Tile'
      };

      if (this.props.sendNotificationEmail) {
        this.props.sendNotificationEmail(updateRequest).then((response: any) => {
          console.log(response);
          if (response) {
            response.includes('Notifiction Sent Successfully')
              ? this.showNotificationStickyToast('success', response, true)
              : this.showNotificationStickyToast('warn', response, true);
              this.setState({ isNotificationDialogVisible: false });
          }
        });
      }
    }
  }

  poContractUpdate(request: any) {
    this.setState({ shouldShowToast: true });
    const poContractUpdateRequest: ContractTileUpdateRequest = {
      id: request.polId,
      assetNumber: request.assetNumber?.trim(),
      contractCode: request.contractCode?.trim(),
      contractStartDate: request.contractStartDate ? DateHelper.formatDate(new Date(request.contractStartDate)) : '',
      acType: request.acType?.trim(),
      make: request.make?.trim(),
      model: request.model?.trim(),
      serial: request.serial?.trim(),
      newReadyDate: request.newReadyDate ? DateHelper.formatDate(new Date(request.newReadyDate)) : '',
      shippingETA: request.shippingETA ? DateHelper.formatDate(new Date(request.shippingETA)) : '',
      shipper: request.transporter?.trim(),
      trDate: request.trDate ? DateHelper.formatDate(new Date(request.trDate)) : '',
      comments: request.comments?.trim(),
    };
    if (this.props.updatePOContract) {
      this.props.updatePOContract(poContractUpdateRequest).then((editAPIMessage: string) => {
        if (editAPIMessage) {
          editAPIMessage.includes('success')
            ? this.showEditStickyToast('success', editAPIMessage, true)
            : this.showEditStickyToast('warn', editAPIMessage, true);
        }
        this.getDashboardPO();
        this.setState({ showPOContractSidebar: false });
      });
    }
  }

  showEditStickyToast(severity: 'info' | 'success' | 'warn' | 'error' | undefined, message: string, isResponseMessage: boolean = false) {
    setTimeout(() => {
      this.toast?.current?.show({ severity: severity, summary: 'Update Process', detail: message, sticky: true });
      if (isResponseMessage) {
        this.setState({ shouldShowToast: false });
      }
    }, 20);
  }

  showNotificationStickyToast(severity: 'info' | 'success' | 'warn' | 'error' | undefined, message: string, isResponseMessage: boolean = false) {
    setTimeout(() => {
      this.toast?.current?.show({ severity: severity, summary: 'Notification Process', detail: message, sticky: true });
      if (isResponseMessage) {
        this.setState({ shouldShowToast: false });
      }
    }, 20);
  }

  renderPOContractEditSidebar() {
    const { dashboardPO } = this.props;
    const dropdownOptions = dashboardPO?.dropdownOptions;
    const editResponse = dashboardPO?.editResponse;
    return (
      <Sidebar visible={this.state.showPOContractSidebar} position="right" onHide={() => this.setState({ showPOContractSidebar: false })}>
        <DashboardEdit dashboardEditData={this.state.poContractEditData} columns={this.columns}
          dashboardEditHeader='PO Contract Edit' DashboardEditLoading={editResponse?.editLoading ?? false}
          dropdownOptions={dropdownOptions}
          getSpecificFieldData={this.getDropdownDataByColumnName}
          onClose={() => this.setState({ showPOContractSidebar: false })}
          onSubmit={(request) => this.poContractUpdate(request)}
        />
      </Sidebar>
    );
  }

  onClose() {
    this.setState({ isNotificationDialogVisible: false });
  }

  getNotificationTemplate() {
    if (this.props.getNotificationTemplate) {
      this.props.getNotificationTemplate('PO Tile').then((responseData: any) => {
        if (responseData) {
          this.setState({ templateData: responseData });
        }
      });
    }
  }

  renderSendNotificationDialog() {
    return (
      <Sidebar className='w-full md:w-20rem lg:w-30rem text-center ml-0' visible={this.state.isNotificationDialogVisible} position="right" onHide={() => this.setState({ isNotificationDialogVisible: false })}>
        <NotificationPopup onClose={this.onClose} categories={this.state.templateData} selectedPoData={this.state.selectedData} sendResetPasswordEmail={this.sendVendorNotificationEmail} />
      </Sidebar>);
  }

  render() {
    const { dashboardPO, selectedColumn } = this.props;
    const request = dashboardPO?.poGridData?.poGridRequest;
    const response = dashboardPO?.poGridData?.poGridResponse;
    const filters = dashboardPO?.filters;
    const sortOptions = request?.sorting;
    const dropdownOptions = dashboardPO?.dropdownOptions;
    const isFetching = dashboardPO?.poGridData?.isFetching;
    const paginationOptions: PaginationInfo = DashboardHelper.getPaginationOptions(request, response?.totalCount);
    const formattedRecords = response?.data?.map((x: any) => (UploadDownloadHelper.setDashboardViewModelObject(x, this.columns)));
    return (

      <div className="card m-0 dashboard">
        <Toast ref={this.toast} />
        <div className="dashboard-container">
          {this.renderPOContractEditSidebar()}
          {this.renderSendNotificationDialog()}
          <DashboardTable columns={this.columns} rows={formattedRecords ?? []}
            loading={isFetching ?? false}
            paginationOptions={paginationOptions}
            filters={filters}
            sortOptions={sortOptions}
            minRecordCount={this.minRecordCount}
            onPaginationChange={this.onPaginationChange}
            onSort={this.onSort}
            dropdownOptions={dropdownOptions}
            onDataTableFilter={this.onDataTableFilter}
            setFilterMetaData={this.setFilterMetaData}
            getSpecificFieldData={this.getSpecificFieldData}
            isSendNotification={selectedColumn?.columnName === 'poNumber' ? true : false}
            onEditClick={this.onEditClick}
            sendNotification={this.sendNotification}
            scrollHeight='65vh'
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
  const { internalUser: auth, dashboard } = state;

  return {
    ...auth,
    dashboardPO: dashboard
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
  requestedPageReset: () => dispatch(AuthActions.requestedPageReset()),
  getPOTileView: (request: DashboardRequest) => {
    return dispatch(DashboardActions.getPOTileView(request));
  },
  setFilterMetaData: (filters?: DataTableFilterMeta | undefined) => dispatch(DashboardActions.setFilterMetaData(filters)),
  resetPOContractTiledData: () => dispatch(DashboardActions.resetPOContractTiledData()),
  getSpecificFieldDataWithRequest: (columnName: string, columnType?: ColumnTypeEnum | undefined, filters?: Filters[] | undefined) =>
    dispatch(DashboardActions.getSpecificFieldDataWithRequest(columnName, columnType, filters)),
  updatePOContract: (request: any) => dispatch(DashboardActions.updatePOContract(request)),
  getDropdownDataByColumnName: (columnName: string, columnType: ColumnTypeEnum | undefined) =>
    dispatch(DashboardActions.getDropdownDataByColumnName(columnName, columnType)),
  getNotificationTemplate: (FromScreen: string) => {
    return dispatch(DashboardActions.getNotificationTemplate(FromScreen));
  },
  sendNotificationEmail: (FromScreen: string) => {
    return dispatch(DashboardActions.sendNotificationEmail(FromScreen));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(POContractTile as any);
