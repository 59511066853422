import React, { Component, Dispatch, RefObject } from 'react';
import './VendorSignIn.scss';
import { Button, Form } from 'react-bootstrap';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { ExternalUserActions } from '../../store/externalUser';
import { Toast } from 'primereact/toast';

interface ComponentProps {
    resetPwdEmail?: (email: string) => any;
}

type ChangePasswordProps =
    ComponentProps
    & typeof mapDispatchToProps;

interface IState {
    email: string,
    shouldShowToast: boolean,
    isSent: boolean,
}

class resetPwdEmail extends Component<ChangePasswordProps, IState>  {
    responseData: any;
    toast: RefObject<Toast> | undefined = undefined;

    constructor(props: Readonly<ChangePasswordProps>) {
        super(props);
        this.toast = React.createRef();
        this.state = {
            email: '',
            shouldShowToast: false,
            isSent: false
        };
        this.resetEmail = this.resetEmail.bind(this);
        this.tryAgain = this.tryAgain.bind(this);
    }

    resetEmail(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (this.props.resetPwdEmail) {
            this.props.resetPwdEmail(this.state.email).then((response: any) => {
                console.log(response);
                if (response) {
                    if (response === 'Email Sent Successfully') {
                        this.showStickyToast('success', response, true);
                        this.setState({ isSent: true });
                    }
                    else {
                        this.showStickyToast('warn', response, true);
                        this.setState({ isSent: false });
                    }
                }
            });
        }

    }
    showStickyToast(severity: 'info' | 'success' | 'warn' | 'error' | undefined, message: string, isResponseMessage: boolean = false) {
        setTimeout(() => {
            this.toast?.current?.show({ severity: severity, summary: 'Notification Process', detail: message, sticky: true });
            if (isResponseMessage) {
                this.setState({ shouldShowToast: false });
            }
        }, 20);
    }

    tryAgain() {
        this.setState({ isSent: false });
    }

    render() {
        return (
            <React.Fragment>
                <Toast ref={this.toast} />
                {!this.state.isSent &&
                    <div>
                        <Form className="rounded p-5 p-sm-4" onSubmit={($event) => this.resetEmail($event)}>
                            <a href="/vendor-sign-in">Back</a>
                            <div className="Login-header text-center">Reset Your Password</div><br />
                            <Form.Group className="mb-2 alignLeft">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" defaultValue={this.state.email}
                                    onChange={e => this.setState({ email: e.target.value })}
                                    className="border-0 border-bottom border-dark rounded-0 txt"></Form.Control>
                            </Form.Group>
                            <Button type="submit" className="vendor-sign-in">Send A Password Reset Email
                            </Button>
                        </Form>
                    </div>
                }
                {this.state.isSent &&
                    <div>
                        <Form className="rounded p-5 p-sm-4">
                            <div className="Login-header text-center">Email Sent</div><br />
                            <Form.Group className="mb-2 alignLeft">
                                <Form.Label className='lbl'>
                                    We sent an email to <b>{this.state.email}!</b> If this email is connected to a GSE Tracker App account,
                                    you'll be able to reset your password.
                                    Please click Try Again button if you didn't get the email.
                                </Form.Label>
                            </Form.Group>
                            <a href="/vendor-sign-in" className="btn btn-primary vendor-sign-in">Back To Login</a>
                            <Button type="submit" className="tryAgain" onClick={this.tryAgain}>Try Again</Button>
                        </Form>

                    </div>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
    return {
        applicationState: state
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
    resetPwdEmail: (email: string) => dispatch(ExternalUserActions.resetPwdEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(resetPwdEmail as any); 