import React, { Component, Dispatch } from 'react';
import DynamicCrudComponent from '../../../shared/components/dynamic-crud-component/dynamic-crud-component';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';
import { Link } from 'react-router-dom';
import routePaths from '../../../shared/constants/routePaths';
import { DashboardRequest } from '../../../shared/models/dashboard';
import { AdminActions } from '../../../store/admin';
import { HeadCell } from '../../../shared/models/HeadCell';
import { AdminVendorViewModel } from '../../../shared/models/AdminVendorViewModel';
import { AdminCRUDTypeEnum } from '../../../shared/models/Enums/AdminCRUDTypeEnum';
import AdminScreenColumnHelper from '../../../helpers/AdminScreenColumnHelper';
import { AdminOperationsEnum } from '../../../shared/models/Enums/AdminOperationsEnum';

interface ComponentProps {
    getDashboard?: (Request: DashboardRequest) => any;
    updateVendor?: (request: AdminVendorViewModel) => any;
    deleteVendor?: (request: string) => any;
}

class AdminVendorContainer extends Component<ComponentProps> {

    columns: HeadCell<AdminVendorViewModel>[] = AdminScreenColumnHelper.getColumns(AdminCRUDTypeEnum.vendor, AdminOperationsEnum.viewAll);

    render() {
        const { getDashboard, deleteVendor, updateVendor } = this.props;
        return (
            <div className="card m-0 flex">
                <h6 className="card-title mx-3 mt-2"><Link className='mr-2' to={routePaths.admin}>Admin</Link>{'> View All Vendors'}</h6>
                <TabView>
                    <TabPanel header="Vendor Management">
                        <div style={{ position: 'relative', width: '100%', height: '95%' }}>
                            <DynamicCrudComponent columns={this.columns}
                                getDashboard={getDashboard}
                                downloadFilePrefix='vendor_list'
                                editDialogHeader='Vendor'
                                adminPageType={AdminCRUDTypeEnum.vendor}
                                deleteApi={deleteVendor}
                                updateApi={updateVendor}
                                addApi={updateVendor}
                            />
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
    const { internalUser: auth, dashboard } = state;

    return {
        ...auth,
        dashboardPO: dashboard
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
    getDashboard: (request: DashboardRequest) => {
        return dispatch(AdminActions.getAllVendors(request));
    },
    updateVendor: (request: AdminVendorViewModel) => dispatch(AdminActions.updateVendor(request)),
    deleteVendor: (request: string) => dispatch(AdminActions.deleteVendor(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminVendorContainer as any);

