import { DashboardState } from '.';
import DashboardHelper from '../../helpers/DashboardHelper';
import initialState from '../initialState';
import { DashboardAction } from './actions';
import { DashboardPOActionTypes } from './types';

export const DashboardReducer = (state = initialState.dashboard, action: DashboardAction): DashboardState => {
    const maxRecordCount = DashboardHelper.getMinRecordCount();
    switch (action.type) {
        case DashboardPOActionTypes.DASHBOARD_PO_SUCCESS: {
            const { payload } = action;
            if(payload.request?.isDownload) {
                return {
                    ...state,
                    downloadUploadResponse: {
                        uploadLoading: false,
                        downloadLoading: false,
                        dashboardResponse: payload.response,
                        uploadAPIMessage: ''
                    }                    
                };
            }
            return {
                ...state,
                dashboardRequest: payload.request,
                dashboardResponse: payload.response
            };
        }

        case DashboardPOActionTypes.PO_TILE_SUCCESS: {
            const { payload } = action;
            const poGridData =  state.poGridData;
            if(poGridData) {
                poGridData.isFetching = false;
                poGridData.poGridRequest = payload.request;
                poGridData.poGridResponse = payload.response;
            }
            return {
                ...state,
                poGridData
            };
        }

        case DashboardPOActionTypes.PO_TILE_LOADING: {
            const { payload } = action;
            const poGridData =  state.poGridData;
            if(poGridData) {
                poGridData.isFetching = payload.isFetching;
            }
            return {
                ...state,
                poGridData
            };
        }

        case DashboardPOActionTypes.DASHBOARD_DROPDOWN_SUCCESS: {
            const { payload } = action;
            const columnOptions = state?.dropdownOptions?.columnOptions;
            if(columnOptions?.some(x => x.columnName === payload.columnName)) {
                const currentDropdownOptions = columnOptions.find(x => x.columnName === payload.columnName);
                if(currentDropdownOptions?.options) {
                    currentDropdownOptions.options = payload.DropdownOptions ?? [];
                }
            } else {
                columnOptions?.push({columnName: payload.columnName, options: payload.DropdownOptions ?? []});
            }
            return {
                ...state,
                dropdownOptions: {columnOptions: columnOptions ?? [], isFetching: false}
            };
        }

        case DashboardPOActionTypes.SET_DASHBOARD_REQUEST: {
            const { payload } = action;
            return {
                ...state,
                dashboardRequest: payload.request
            };
        }

        case DashboardPOActionTypes.DASHBOARD_PO_LOADING: {
            const { payload } = action;
            return {
                ...state,
                isFetching: payload.isFetching
            };
        }

        case DashboardPOActionTypes.DASHBOARD_DOWNLOAD_LOADING: {
            const { payload } = action;
            const downloadUploadResponse =  state.downloadUploadResponse;
            if(downloadUploadResponse) {
                downloadUploadResponse.downloadLoading = payload.isFetching;
            }
            return {
                ...state,
                downloadUploadResponse
            };
        }

        case DashboardPOActionTypes.DASHBOARD_UPLOAD_LOADING: {
            const { payload } = action;
            const downloadUploadResponse =  state.downloadUploadResponse;
            if(downloadUploadResponse) {
                downloadUploadResponse.uploadLoading = payload.isFetching;
            }
            return {
                ...state,
                downloadUploadResponse
            };
        }
        case DashboardPOActionTypes.DASHBOARD_EDIT_LOADING: {
            const { payload } = action;
            const editResponse =  state.editResponse;
            if(editResponse) {
                editResponse.editLoading = payload.isFetching;
            }
            return {
                ...state,
                editResponse
            };
        }
        case DashboardPOActionTypes.DASHBOARD_EDIT_SUCCESS: {
            const { payload } = action;
            const editResponse =  state.editResponse;
            if(editResponse) {
                editResponse.editAPIMessage = payload.editAPIMessage;
            }
            return {
                ...state,
                editResponse: editResponse,
                dropdownOptions: {columnOptions: [], isFetching: false}
            };
        }

        case DashboardPOActionTypes.DASHBOARD_UPLOAD_SUCCESS: {
            const { payload } = action;
            const uploadResponse =  state.downloadUploadResponse;
            if(uploadResponse) {
                uploadResponse.uploadAPIMessage = payload.uploadAPIMessage;
            }
            return {
                ...state,
                downloadUploadResponse: uploadResponse
            };
        }

        case DashboardPOActionTypes.RESET_UPLOAD_API_MESSAGE: {
            const uploadResponse =  state.downloadUploadResponse;
            if(uploadResponse) {
                uploadResponse.uploadAPIMessage = undefined;
            }
            return {
                ...state,
                downloadUploadResponse: uploadResponse
            };
        }

        case DashboardPOActionTypes.RESET_UPDATE_API_MESSAGE: {
            const editResponse =  state.editResponse;
            if(editResponse) {
                editResponse.editAPIMessage = undefined;
            }
            return {
                ...state,
                editResponse
            };
        }

        case DashboardPOActionTypes.RESET_DOWNLOAD_DASHBOARD_DATA: {
            const downloadResponse =  state.downloadUploadResponse;
            if(downloadResponse) {
                downloadResponse.dashboardResponse = undefined;
            }
            return {
                ...state,
                downloadUploadResponse: downloadResponse
            };
        }

        case DashboardPOActionTypes.DASHBOARD_DROPDOWN_LOADING: {
            const { payload } = action;
            const dropdownOptions =  state.dropdownOptions;
            if(dropdownOptions) {
                dropdownOptions.isFetching = payload.isFetchingDropdownOptions;
            }
            return {
                ...state,
                dropdownOptions
            };
        }

        case DashboardPOActionTypes.SET_FILTER_METADATA: {
            const { payload } = action;
            return {
                ...state,
                filters: payload.request
            };
        }

        case DashboardPOActionTypes.RESET_DROPDOWN_WITH_FILTER: {
            const { payload } = action;
            let columnOptions = state.dropdownOptions?.columnOptions.filter(x => x.columnName === 'station');

            payload.filters?.forEach(filter => {
                columnOptions?.push({columnName: filter.columnName ?? '', options: filter.values});
            });
            return {
                ...state,
                dropdownOptions: { isFetching: state.dropdownOptions?.isFetching ?? false, columnOptions: columnOptions ?? [] }
            };
        }

        case DashboardPOActionTypes.RESET_DASHBOARD_DATA: {
            return {
                ...state,
                dashboardRequest: {
                    filters: undefined,
                    sorting: {
                        columnName: undefined,
                        isAsc: true
                    },
                    pagination: {
                        pageNumber: 1,
                        pageSize: maxRecordCount
                    },
                    startDate: undefined,
                    endDate: undefined,
                    isDownload: false
                },
                dashboardResponse: undefined,
                isFetching: false,
                dropdownOptions: {
                    isFetching: false,
                    columnOptions: []
                }
            };
        }

        case DashboardPOActionTypes.RESET_PO_CONTRACT_TILE_DATA: {
            return {
                ...state,
                poGridData: {
                    poGridRequest: {
                        filters: undefined,
                        sorting: {
                            columnName: undefined,
                            isAsc: true
                        },
                        pagination: {
                            pageNumber: 1,
                            pageSize: maxRecordCount
                        },
                        startDate: undefined,
                        endDate: undefined,
                        isDownload: false
                    },
                    poGridResponse: undefined,
                    isFetching: false
                }
            };
        }

        default:
            return state;
    }
};