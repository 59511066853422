import { ColumnTypeEnum } from '../shared/models/ColumnTypeEnum';
import { AdminOperationsEnum } from '../shared/models/Enums/AdminOperationsEnum';
import { HeadCell } from '../shared/models/HeadCell';
import { StationUserModel, StationViewModel } from '../shared/models/StationViewModel';

class AdminStationContactColumnHelper {
    public static getColumns(operation: AdminOperationsEnum): HeadCell<StationViewModel>[] {
        switch (operation) {
            case AdminOperationsEnum.viewAll:
                return [
                    { name: 'edit', header: 'Edit', isSortable: false, filter: false, dataType: ColumnTypeEnum.edit },
                    { name: 'delete', header: 'Delete', isSortable: false, filter: false, dataType: ColumnTypeEnum.delete },
                    { name: 'stationCode', header: 'Station Name' },
                    /* { name: 'airportName', header: 'Airport Name' },*/
                    { name: 'mailingAddress', header: 'Mailing Address', isSortable: false, filter: false },
                    { name: 'mainContactName', header: 'Main Contact Name', isSortable: false, filter: false },
                    { name: 'mainContactEmailId', header: 'Email Id', isSortable: false, filter: false },
                    { name: 'mainContactOfficePhone', header: 'Office Phone', isSortable: false, filter: false },
                    { name: 'mainContactCellPhone', header: 'Cell Phone', isSortable: false, filter: false },
                    { name: 'isConfirmed', header: 'Status', dataType: ColumnTypeEnum.inputSwitch },
                    { name: 'isActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },
                    { name: 'view', dataType: ColumnTypeEnum.view }
                ];
            case AdminOperationsEnum.view:
                return [
                    { name: 'stationCode', header: 'Station Name', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'airportName', header: 'Airport Name', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'address1', header: 'Address Line 1', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true, },
                    { name: 'address2', header: 'Address Line 2(optional)', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'country', header: 'Country', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.staticDropdown },
                    { name: 'state', header: 'State', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.staticDropdown, parentColumnName: 'country' },
                    { name: 'city', header: 'City', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.text },
                    { name: 'zipCode', header: 'Zip/Postal Code', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.text },
                    { name: 'airportName', header: 'Airport Name' },
                    {
                        name: 'stationUsers', header: 'Additional Contact', dataType: ColumnTypeEnum.additionalContact,
                        childColumns: AdminStationContactColumnHelper.getAdditionalColumns(),
                        childNewObject: AdminStationContactColumnHelper.getAdditionalContact()
                    },
                    { name: 'comments', header: 'Comments', dataType: ColumnTypeEnum.text },
                    { name: 'isConfirmed', header: 'Status', dataType: ColumnTypeEnum.inputSwitch },
                    { name: 'isActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },
                    { name: 'view', dataType: ColumnTypeEnum.view }
                ];
            case AdminOperationsEnum.edit:
                return [
                    { name: 'stationCode', header: 'Station Name', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'airportName', header: 'Airport Name', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'address1', header: 'Address Line 1', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true, },
                    { name: 'address2', header: 'Address Line 2(optional)', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'country', header: 'Country', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.staticDropdown },
                    { name: 'state', header: 'State', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.staticDropdown, parentColumnName: 'country' },
                    { name: 'city', header: 'City', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.text },
                    { name: 'zipCode', header: 'Zip/Postal Code', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.text, isValidZip:true },
                    {
                        name: 'stationUsers', header: 'Additional Contact', dataType: ColumnTypeEnum.additionalContact, isEditable: true,
                        childColumns: AdminStationContactColumnHelper.getAdditionalColumns(),
                        childNewObject: AdminStationContactColumnHelper.getAdditionalContact()
                    },
                    { name: 'comments', header: 'Comments', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'isConfirmed', header: 'Is Confirmed', isEditable: true, dataType: ColumnTypeEnum.inputSwitch },
                    { name: 'isActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },
                ];

            case AdminOperationsEnum.add:
                return [
                    { name: 'stationCode', header: 'Station Name', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'airportName', header: 'Airport Name', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'address1', header: 'Address Line 1', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true, },
                    { name: 'address2', header: 'Address Line 2(optional)', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'country', header: 'Country', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.staticDropdown },
                    { name: 'state', header: 'State', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.staticDropdown, parentColumnName: 'country' },
                    { name: 'city', header: 'City', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.text },
                    { name: 'zipCode', header: 'Zip/Postal Code', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.text, isValidZip:true },
                    {
                        name: 'stationUsers', header: 'Additional Contact', dataType: ColumnTypeEnum.additionalContact, isEditable: true,
                        childColumns: AdminStationContactColumnHelper.getAdditionalColumns(),
                        childNewObject: AdminStationContactColumnHelper.getAdditionalContact()
                    },
                    { name: 'comments', header: 'Comments', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'isConfirmed', header: 'Is Confirmed', isEditable: true, dataType: ColumnTypeEnum.inputSwitch },
                    { name: 'isActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },
                ];

            default:
                return [];
        }
    }

    public static getAdditionalColumns(): HeadCell<StationUserModel>[] {
        return [
            { name: 'contactName', header: 'Contact Name', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text },
            { name: 'emailId', header: 'Email Id', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text, isValidEmail:true },
            { name: 'officePhone', header: 'Office Phone', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text, isValidMobile:true },
            { name: 'cellPhone', header: 'Cell Phone', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text, isValidMobile:true },
            { name: 'isActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch, isPresentInMultipleColumn: true },
            { name: 'isMainContact', isEditable: true, header: 'Is Main Contact', dataType: ColumnTypeEnum.inputSwitch, isPresentInMultipleColumn: true },

        ];
    }

    private static getAdditionalContact(): StationUserModel {
        return {
            contactName: '',
            emailId: '',
            officePhone: '',
            cellPhone: '',
            isActive: true,
            isMainContact: false
        };
    }
}

export default AdminStationContactColumnHelper;

