import thunk from 'redux-thunk';
import { combineReducers} from 'redux';
import StateLoader from './StateLoader';
import { AuthenticationState } from './auth';
import { authReducer } from './auth/reducers';
import { flagsReducer } from './flags/reducers';
import { FlagsState } from './flags';
import { ExternalUserState } from './externalUser';
import { UserReducer } from './externalUser/reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { configureStore } from '@reduxjs/toolkit';
import { DashboardState } from './dashboard';
import { DashboardReducer } from './dashboard/reducers';

export interface ApplicationState {
    internalUser: AuthenticationState;
    flags: FlagsState;
    externalUser: ExternalUserState;
    dashboard: DashboardState;
}

export const rootReducer = combineReducers<ApplicationState>({
    internalUser: authReducer,
    flags: flagsReducer,
    externalUser: UserReducer,
    dashboard: DashboardReducer
});

const persistConfig = {
  key: 'root',
  storage: storageSession
};
 
const persistedReducer = persistReducer(persistConfig, rootReducer);

const stateLoader = new StateLoader();

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
  });

let persistor = persistStore(store);

store.subscribe(() => {
    const state: ApplicationState = store.getState() as ApplicationState;
    stateLoader.saveState(state);
});

export { store, persistor };