import React, { Component, Dispatch, RefObject } from 'react';
import { ApplicationState } from '../../../store';
import { FlagsActions } from '../../../store/flags';
import { connect } from 'react-redux';
import { DashboardTable } from '../../../shared/components/dynamic-data-table/dynamic-data-table';
import { HeadCell } from '../../../shared/models/HeadCell';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { DashboardRequest, DashboardViewModel, Filters, PaginationInfo } from '../../../shared/models/dashboard';
import { TransportVendorViewModel } from '../../../shared/models/TransportVendorViewModel';
import { DataTableStateEvent, DataTableFilterMeta, DataTableValue } from 'primereact/datatable';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import DashboardHelper from '../../../helpers/DashboardHelper';
import { DashboardActions, DashboardState } from '../../../store/dashboard';
import { ColumnTypeEnum } from '../../../shared/models/ColumnTypeEnum';
import UploadDownloadHelper from '../../../helpers/UploadDownloadHelper';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { DashboardEdit } from '../../../shared/components/dashboardEdit/dashboardEdit';
import { TransportationVendorUpdateRequest } from '../../../shared/models/TransportationVendorUpdateRequest';
import { AuthActions } from '../../../store/auth';
import './transportationVendorContainer.scss';
import { DateHelper } from '../../../helpers';
import { PdfViewer } from '../../../shared/components/pdf-viewer/pdf-viewer';

interface ComponentProps {
  isAuthenticated?: boolean;
  dashboardPO?: DashboardState;
  filters?: DataTableFilterMeta;

  isDashboardLayoutEnable?: () => void;
  resetDashboardData?: () => void;
  getDashboardPO?: (request: DashboardRequest) => void;
  setFilterMetaData?: (dataTableFilterMeta: DataTableFilterMeta | undefined) => void;
  getSpecificFieldDataWithRequest?: (columnName: string, dataType: ColumnTypeEnum | undefined, filters?: Filters[] | undefined) => void;
  getDropdownDataByColumnName?: (columnName: string, dataType: ColumnTypeEnum | undefined) => void;
  updateTransportationVendorPO?: (Request: any) => any;
  resetUpdateApiMessage?: () => void;
  resetDownloadDashboardData?: () => void;
  requestedPageReset?: () => void;
  getSpecDocumentData?: (polId: string, columnName: string) => any;
}

type TransportVendorDashboardProps =
  ComponentProps
  & typeof mapDispatchToProps;

class TransportationVendorContainer extends Component<TransportVendorDashboardProps> {
  minRecordCount: number = DashboardHelper.getMinRecordCount();
  toast: RefObject<Toast> | undefined = undefined;

  state = {
    showTransportationVendorEditDialog: false,
    transportVendorEditData: undefined,
    showPdfViewerDialog: false,
    specFormData: '',
    pdfViewerFetching: false,
    specDocName: 'Specification Document'
  };

  constructor(props: TransportVendorDashboardProps) {
    super(props);
    this.toast = React.createRef();
    if (props.requestedPageReset)
      props.requestedPageReset();

    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.onDataTableFilter = this.onDataTableFilter.bind(this);
    this.setFilterMetaData = this.setFilterMetaData.bind(this);
    this.getSpecificFieldData = this.getSpecificFieldData.bind(this);
    this.getDropdownDataByColumnName = this.getDropdownDataByColumnName.bind(this);
    this.onSort = this.onSort.bind(this);
    this.downloadClickEvent = this.downloadClickEvent.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.renderTransportationVendorEditDialog = this.renderTransportationVendorEditDialog.bind(this);
    this.getSpecFormData = this.getSpecFormData.bind(this);
  }

  componentDidMount(): void {
    if (this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();

    if (this.props.resetDashboardData)
      this.props.resetDashboardData();

    if (this.props.isDashboardLayoutEnable) {
      this.props.isDashboardLayoutEnable();
    }
  }

  getDashboardPO() {
    const { dashboardPO } = this.props;

    if (this.props?.getDashboardPO && dashboardPO?.dashboardRequest && !this.props.dashboardPO?.isFetching) {
      this.props.getDashboardPO(dashboardPO?.dashboardRequest);
    }
  }

  // define columns
  columns: HeadCell<TransportVendorViewModel>[] = [
    { name: 'edit', header: 'Edit', isSortable: false, filter: false, dataType: ColumnTypeEnum.edit },
    { name: 'tagId', header: 'Tag', dataType: ColumnTypeEnum.tag },
    { name: 'vendorName', header: 'Vendor' },
    { name: 'transporter', header: 'Transporter' },
    { name: 'loadOrPickupDate', header: 'Load Date/Pick Up Date', dataType: ColumnTypeEnum.date, isEditable: true },
    { name: 'shippingStatus', header: 'Shipping Status', isEditable: true, dataType: ColumnTypeEnum.shippingStatus },
    { name: 'deliveryDate', header: 'Del. Date', dataType: ColumnTypeEnum.date, isEditable: true }, 
    { name: 'proBOL', header: 'PRD/BOL', isEditable: true },
    { name: 'proBOLFileName', header: 'PRD/BOL Filename', isEditable: true, dataType: ColumnTypeEnum.file },
    { name: 'transportationInvoiceNumber', header: 'Transportation Invoice#', isEditable: true },
    { name: 'transportInvoiceFileName', header: 'Transport Invoice FileName', isEditable: true, dataType: ColumnTypeEnum.file },
    { name: 'commentsOnTR', header: 'Comments or Notes on TR', isEditable: true },
    { name: 'poNumber', header: 'PO#' },
    { name: 'make', header: 'Make' },
    { name: 'model', header: 'Model' },
    { name: 'serial', header: 'Serial' },
    { name: 'unitStatus', header: 'Unit Status' },
    { name: 'newReadyDate', header: 'New Ready Date', dataType: ColumnTypeEnum.date },
    { name: 'specFileName', header: 'Spec Form/ Attachments', dataType: ColumnTypeEnum.file },
    { name: 'fuelType', header: 'Fuel Type' },
    { name: 'weight', header: 'Weights(lbs)' },
    { name: 'dimensions', header: 'Dimensions(L*W*H)', isSortable: false, filter: false },
  ];

  onPaginationChange(event: PaginatorPageChangeEvent) {
    const { dashboardPO } = this.props;
    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.pagination = pagination;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  onSort(event: DataTableStateEvent) {
    const { dashboardPO } = this.props;

    const sortOptions = DashboardHelper.getSortingOptions(event.sortField, event.sortOrder);
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.sorting = sortOptions;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  onDataTableFilter(event: DataTableStateEvent) {
    if (this.props.setFilterMetaData && event.filters) {
      this.props.setFilterMetaData(event.filters);
    }
    const filters: Filters[] = DashboardHelper.setFilterData(event.filters, this.columns);

    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);

    const { dashboardPO } = this.props;

    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.filters = filters;
      dashboardPO.dashboardRequest.pagination = pagination;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  setFilterMetaData(filterMetaData?: DataTableFilterMeta) {
    const { dashboardPO } = this.props;
    if (this.props?.setFilterMetaData && filterMetaData) {
      this.props.setFilterMetaData(filterMetaData);

      const filters: Filters[] = DashboardHelper.setFilterData(filterMetaData, this.columns);

      if (dashboardPO?.dashboardRequest) {
        dashboardPO.dashboardRequest.filters = filters;
        dashboardPO.dashboardRequest.isDownload = false;
      }
      setTimeout(() => {
        this.getDashboardPO();
      }, 100);
    }
  }

  getSpecFormData(event: any, columnName: string, rowValue: any) {
    event?.preventDefault();
    this.setState({ pdfViewerFetching: true });
    this.setState({ showPdfViewerDialog: true });
    this.setState({ specDocName: rowValue[columnName] });
    if (this.props.getSpecDocumentData) {
      const columnDataName = UploadDownloadHelper.getValueColumnByDisplayColumn(columnName);
      this.props.getSpecDocumentData(rowValue['polId'], columnDataName).then((specFormData: string[]) => {
        this.setState({ specFormData: specFormData ? specFormData : '' });
        this.setState({ pdfViewerFetching: false });
      });
    }
  }

  getSpecificFieldData(columnName: string) {
    const currentColumn = this.columns.find(x => x.name === columnName);
    const { dashboardPO } = this.props;

    if (this.props?.getSpecificFieldDataWithRequest && dashboardPO?.dashboardRequest) {
      this.props.getSpecificFieldDataWithRequest(columnName, currentColumn?.dataType, dashboardPO?.dashboardRequest?.filters);
    }
  }

  getDropdownDataByColumnName(columnName: string) {
    const currentColumn = this.columns?.find(x => x.name === columnName);

    if (this.props?.getDropdownDataByColumnName) {
      this.props.getDropdownDataByColumnName(columnName, currentColumn?.dataType);
    }
  }

  downloadClickEvent() {
    const { dashboardPO } = this.props;
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.isDownload = true;
    }
    this.getDashboardPO();
  }

  private downloadExcelAfterResponse(dashboardPO: DashboardState | undefined) {
    setTimeout(() => {
      if (!dashboardPO?.downloadUploadResponse.downloadLoading && dashboardPO?.downloadUploadResponse?.dashboardResponse) {
        const response = dashboardPO?.downloadUploadResponse.dashboardResponse;
        let downloadItems: any[] = [];
        if (response?.data) {
          downloadItems = response.data.map((x: any) => (UploadDownloadHelper.setDownloadObject(x, this.columns)));
        }
        UploadDownloadHelper.exportExcel<DashboardViewModel>(downloadItems, 'transport_vendor');
        if (this.props.resetDownloadDashboardData) {
          this.props.resetDownloadDashboardData();
        }
      }
    }, 50);
  }

  onEditClick(data: DataTableValue) {
    if (this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();

    this.setState({ transportVendorEditData: data });
    this.setState({ showTransportationVendorEditDialog: true });
  }

  renderTransportationVendorEditDialog() {
    const { dashboardPO } = this.props;
    const dropdownOptions = dashboardPO?.dropdownOptions;
    const editResponse = dashboardPO?.editResponse;
    const uploadFiles = UploadDownloadHelper.getUploadFiles();
    return (
      <Dialog header='Transportation Vendor Edit' className='dashboard-edit-dialog edit-dialog rounded text-center ml-0 mb-2'
        visible={this.state.showTransportationVendorEditDialog} onHide={() => this.setState({ showTransportationVendorEditDialog: false })}>
        <DashboardEdit dashboardEditData={this.state.transportVendorEditData} columns={this.columns}
          DashboardEditLoading={editResponse?.editLoading ?? false}
          dropdownOptions={dropdownOptions}
          uploadFiles={uploadFiles}
          getSpecificFieldData={this.getDropdownDataByColumnName}
          onClose={() => this.setState({ showTransportationVendorEditDialog: false })}
          onSubmit={(request) => this.transportationVendorUpdate(request)}
        />
      </Dialog>
    );
  }

  transportationVendorUpdate(request: any) {
    console.log(request);
    if (this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();

    const updateRequest: TransportationVendorUpdateRequest = {
      id: request.polId,
      loadOrPickupDate: request.loadOrPickupDate ? DateHelper.formatDate(new Date(request.loadOrPickupDate)) : '',
      shippingStatus: request.shippingStatus?.trim(),
      deliveryDate: request.deliveryDate ? DateHelper.formatDate(new Date(request.deliveryDate)) : '',
      proBOL: request.proBOL?.trim(),
      transportationInvoiceNumber: request.transportationInvoiceNumber?.trim(),
      commentsOnTR: request.commentsOnTR?.trim(),
      proBOLFileName: request.proBOLFileName?.trim(),
      proBOLFileData: request.proBOLFileData?.trim(),
      transportInvoiceFileName: request.transportInvoiceFileName?.trim(),
      transportInvoiceFileData: request.transportInvoiceFileData?.trim(),
    };

    this.setState({ shouldShowToast: true });
    if (this.props.updateTransportationVendorPO) {
      this.props.updateTransportationVendorPO(updateRequest).then((editAPIMessage: string) => {
        this.setToastFromResponse(editAPIMessage, 'Update Process');
        this.getDashboardPO();
        this.setState({ showTransportationVendorEditDialog: false });
      });
    }
  }

  private setToastFromResponse(apiResponseMessage: string, summary: string) {
    if (apiResponseMessage) {
      apiResponseMessage.includes('success')
        ? this.showStickyToast('success', apiResponseMessage, summary)
        : this.showStickyToast('warn', apiResponseMessage, summary);
    }
  }

  showStickyToast(severity: 'info' | 'success' | 'warn' | 'error' | undefined, message: string, summary: string) {
    setTimeout(() => {
      this.toast?.current?.show({ severity: severity, summary: summary, detail: message, sticky: true });
    }, 50);
  }

  renderPdfViewer() {
    const { showPdfViewerDialog, specFormData, specDocName, pdfViewerFetching } = this.state;
    return (
      <Dialog header={specDocName} className='rounded text-center pdf-viewer-dialog'
        visible={showPdfViewerDialog}
        onHide={() => this.setState({ showPdfViewerDialog: false })}>
        <PdfViewer base64String={specFormData} loading={pdfViewerFetching} fileName={specDocName}
          onClose={() => this.setState({ showPdfViewerDialog: false })} />
      </Dialog>
    );
  }

  /**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
  render() {
    const { dashboardPO } = this.props;
    const request = dashboardPO?.dashboardRequest;
    const response = dashboardPO?.dashboardResponse;
    const filters = dashboardPO?.filters;
    const sortOptions = request?.sorting;
    const dropdownOptions = dashboardPO?.dropdownOptions;
    const downloadUploadResponse = dashboardPO?.downloadUploadResponse;

    const paginationOptions: PaginationInfo = DashboardHelper.getPaginationOptions(request, response?.totalCount);
    const formattedRecords = response?.data?.map((x: any) => (UploadDownloadHelper.setDashboardViewModelObject(x, this.columns)));
    this.downloadExcelAfterResponse(dashboardPO);

    return (
      <React.Fragment>
        <Toast ref={this.toast} />
        {this.renderTransportationVendorEditDialog()}
        {this.renderPdfViewer()}
        <div className="card m-0">
          <h6 className="card-title ml-3 mt-2">Transportation Vendors</h6>
          <TabView>
            <TabPanel header="Transportation Vendor Sheet">
              <div className="dashboard-container">
                <DashboardTable columns={this.columns} rows={formattedRecords ?? []}
                  loading={this.props.dashboardPO?.isFetching ?? false}
                  paginationOptions={paginationOptions}
                  filters={filters}
                  sortOptions={sortOptions}
                  minRecordCount={this.minRecordCount}
                  onPaginationChange={this.onPaginationChange}
                  onSort={this.onSort}
                  dropdownOptions={dropdownOptions}
                  onDataTableFilter={this.onDataTableFilter}
                  setFilterMetaData={this.setFilterMetaData}
                  getSpecificFieldData={this.getSpecificFieldData}
                  isSendNotification={false}
                  specFileLinkClick={this.getSpecFormData}
                  onEditClick={this.onEditClick}>
                  <div className="flex justify-content-end p-0">
                    <Button loading={downloadUploadResponse?.downloadLoading} label='Download'
                      onClick={this.downloadClickEvent} size='small' type='button' />
                  </div>
                </DashboardTable>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
  const { internalUser: auth, dashboard } = state;

  return {
    ...auth,
    dashboardPO: dashboard
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
  isDashboardLayoutEnable: () => dispatch(FlagsActions.isDashboardLayoutEnable()),
  getDashboardPO: (request: DashboardRequest) => {
    return dispatch(DashboardActions.getTransportVendorPO(request));
  },
  setFilterMetaData: (filters?: DataTableFilterMeta | undefined) => dispatch(DashboardActions.setFilterMetaData(filters)),
  resetDashboardData: () => dispatch(DashboardActions.resetDashboardData()),
  getSpecificFieldDataWithRequest: (columnName: string, columnType: ColumnTypeEnum | undefined, filters?: Filters[] | undefined) =>
    dispatch(DashboardActions.getSpecificFieldDataWithRequest(columnName, columnType, filters)),
  resetDownloadDashboardData: () => dispatch(DashboardActions.resetDownloadDashboardData()),
  updateTransportationVendorPO: (request: any) => dispatch(DashboardActions.updateTransportationVendorPO(request)),
  resetUpdateApiMessage: () => dispatch(DashboardActions.resetUpdateApiMessage()),
  requestedPageReset: () => dispatch(AuthActions.requestedPageReset()),
  getSpecDocumentData: (polId: string, columnName: string) => dispatch(DashboardActions.getSpecDocumentData(polId, columnName)),
  getDropdownDataByColumnName: (columnName: string, columnType: ColumnTypeEnum | undefined) =>
    dispatch(DashboardActions.getDropdownDataByColumnName(columnName, columnType))
});


export default connect(mapStateToProps, mapDispatchToProps)(TransportationVendorContainer as any);