import React, { Component, Dispatch, RefObject } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { FlagsActions } from '../../store/flags';
import { DashboardTable } from '../../shared/components/dynamic-data-table/dynamic-data-table';
import { HeadCell } from '../../shared/models/HeadCell';
import { DataTableFilterMeta, DataTableStateEvent, DataTableValue } from 'primereact/datatable';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { DashboardActions, DashboardState } from '../../store/dashboard';
import { DashboardRequest, DashboardViewModel, Filters, PaginationInfo } from '../../shared/models/dashboard';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { TransportationViewModel } from '../../shared/models/TransportationViewModel';
import DashboardHelper from '../../helpers/DashboardHelper';
import { ColumnTypeEnum } from '../../shared/models/ColumnTypeEnum';
import { Dialog } from 'primereact/dialog';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import UploadDownloadHelper from '../../helpers/UploadDownloadHelper';
import { Toast } from 'primereact/toast';
import { SplitButton } from 'primereact/splitbutton';
import { Link } from 'react-router-dom';
import { PrimeIcons } from 'primereact/api';
import { MenuItem } from 'primereact/menuitem';
import { DashboardEdit } from '../../shared/components/dashboardEdit/dashboardEdit';
import { TransportationUpdateRequest } from '../../shared/models/TransportationUpdateRequest';
import { DateHelper } from '../../helpers';
import NotificationPopup from '../POContractTile/notificationPopup';
import { Sidebar } from 'primereact/sidebar';
import { AuthActions } from '../../store/auth';
import { sendNotification } from '../../shared/models/SendNotificationModel';
import { PdfViewer } from '../../shared/components/pdf-viewer/pdf-viewer';

interface ComponentProps {
  isAuthenticated?: boolean;
  dashboardPO?: DashboardState;
  filters?: DataTableFilterMeta;

  isDashboardLayoutEnable?: () => void;
  resetDashboardData?: () => void;
  getDashboardPO?: (request: DashboardRequest) => void;
  setFilterMetaData?: (dataTableFilterMeta: DataTableFilterMeta | undefined) => void;
  getSpecificFieldDataWithRequest?: (columnName: string, dataType: ColumnTypeEnum | undefined, filters?: Filters[] | undefined) => void;
  getDropdownDataByColumnName?: (columnName: string, dataType: ColumnTypeEnum | undefined) => void;
  updateTransportationPO?: (Request: any) => any;
  resetUploadApiMessage?: () => void;
  resetUpdateApiMessage?: () => void;
  resetDownloadDashboardData?: () => void;
  uploadTransportationFile?: (Request: any) => any;
  requestedPageReset?: () => void;
  getNotificationTemplate?: (FromScreen: string) => any;
  sendNotificationEmail?: (FromScreen: any) => any;
  getSpecDocumentData?: (polId: string, columnName: string) => any;
}

type TransportationDashboardProps =
  ComponentProps
  & typeof mapDispatchToProps;

class TransportationContainer extends Component<TransportationDashboardProps> {
  minRecordCount: number = DashboardHelper.getMinRecordCount();
  toast: RefObject<Toast> | undefined = undefined;
  templateItems: MenuItem[] | undefined = undefined;

  state = {
    selectedTabIndex: 0,
    isUploadDialogVisible: false,
    shouldShowToast: false,
    showTransportationDialog: false,
    transportEditData: undefined,
    isNotificationDialogVisible: false,
    selectedData: undefined,
    templateData: undefined,
    showPdfViewerDialog: false,
    specFormData: '',
    pdfViewerFetching: false,
    specDocName: 'Specification Document'
  };

  constructor(props: TransportationDashboardProps) {
    super(props);
    this.toast = React.createRef();

    if (props.requestedPageReset)
      props.requestedPageReset();

    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.onDataTableFilter = this.onDataTableFilter.bind(this);
    this.setFilterMetaData = this.setFilterMetaData.bind(this);
    this.getSpecificFieldData = this.getSpecificFieldData.bind(this);
    this.getDropdownDataByColumnName = this.getDropdownDataByColumnName.bind(this);
    this.onSort = this.onSort.bind(this);
    this.uploadClickEvent = this.uploadClickEvent.bind(this);
    this.invoiceUploadHandler = this.invoiceUploadHandler.bind(this);
    this.downloadClickEvent = this.downloadClickEvent.bind(this);
    this.renderTransportationDialog = this.renderTransportationDialog.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.sendNotification = this.sendNotification.bind(this);
    this.renderSendNotificationDialog = this.renderSendNotificationDialog.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getNotificationTemplate = this.getNotificationTemplate.bind(this);
    this.sendVendorNotificationEmail = this.sendVendorNotificationEmail.bind(this);
    this.getSpecFormData = this.getSpecFormData.bind(this);

    this.templateItems = [
      {
        label: 'Download Template',
        icon: PrimeIcons.DOWNLOAD,
        template: () => {
          return (
            <Link className='p-menuitem-link' to='/Template/PO_Logistics_Master.xlsx'
              target='_blank' download>
              <i className={'pi pi-download mr-2'} style={{ fontSize: '1rem' }}></i>
              <span>Download Template</span>
            </Link>
          );
        },
        command: () => {
          this.toast?.current?.show({ severity: 'warn', summary: 'Download', detail: 'Upload template file downloaded' });
        }
      }
    ];
  }

  componentDidMount(): void {
    if (this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();

    if (this.props.resetUploadApiMessage)
      this.props.resetUploadApiMessage();

    if (this.props.isDashboardLayoutEnable) {
      this.props.isDashboardLayoutEnable();
    }

    if (this.props.resetDashboardData)
      this.props.resetDashboardData();
  }

  getDashboardPO() {
    const { dashboardPO } = this.props;

    if (this.props?.getDashboardPO && dashboardPO?.dashboardRequest && !this.props.dashboardPO?.isFetching) {
      this.props.getDashboardPO(dashboardPO?.dashboardRequest);
    }
  }

  // define columns
  columns: HeadCell<TransportationViewModel>[] = [
    { name: 'edit', header: 'Edit', isSortable: false, filter: false, dataType: ColumnTypeEnum.edit },
    { name: 'tagId', header: 'Tag', dataType: ColumnTypeEnum.tag },
    { name: 'trDate', header: 'TR Date', dataType: ColumnTypeEnum.date, isEditable: true },
    { name: 'vendorName', header: 'Vendor' },
    { name: 'edr', header: 'EDR', isEditable: true },
    { name: 'poNumber', header: 'PO/UNIT #' },
    { name: 'assetNumber', header: 'Asset#' },
    { name: 'transportationContractStartDate', header: 'Contract Start Date', dataType: ColumnTypeEnum.date, isEditable: true },
    { name: 'priority', header: 'Priority', isEditable: true },
    { name: 'transporter', header: 'Transporter', isEditable: true },
    { name: 'pickupLocation', header: 'PO/Transfer contract (Pick up location)', isEditable: true },
    { name: 'destinationLocation', header: 'Dest contract/Delivery location (To)', isEditable: false },
    { name: 'loadOrPickupDate', header: 'Load Date/Pick Up date', dataType: ColumnTypeEnum.date },
    { name: 'qty', header: 'Qty' },
    { name: 'make', header: 'Make' },
    { name: 'model', header: 'Model' },
    { name: 'items', header: 'Item' },
    { name: 'serial', header: 'Serial' },
    { name: 'shippingStatus', header: 'Shipping Status', isEditable: true, dataType: ColumnTypeEnum.shippingStatus },
    { name: 'gap', header: 'GAP', isSortable: false, filter: false },
    { name: 'deliveryDate', header: 'Del. Date', dataType: ColumnTypeEnum.date },
    { name: 'proBOL', header: 'Pro/ BOL' },
    { name: 'proBOLFileName', header: 'PRD/BOL Filename', isEditable: false, dataType: ColumnTypeEnum.file },
    { name: 'fuelType', header: 'Fuel Type' },
    { name: 'weight', header: 'Weights(lbs)' },
    { name: 'dimensions', header: 'Dimensions(L*W*H)', isSortable: false, filter: false },
    { name: 'equipmentCost', header: 'Equipment Cost', dataType: ColumnTypeEnum.currency },
    { name: 'irec', header: 'IREC (NetSuite)' },
    { name: 'bidNumber', header: 'Bid Number', isEditable: true },
    { name: 'bidReceived', header: 'Bid Received', isEditable: true },
    { name: 'differenceOn', header: 'Difference On', isEditable: true },
    { name: 'transportationInvoiceNumber', header: 'Transportation Invoice #', isEditable: true },
    { name: 'transportationInvoiceAmount', header: 'Invoice Amount', dataType: ColumnTypeEnum.currency },
    { name: 'transportationInvoiceStatus', header: 'Invoice Status', isEditable: true },
    { name: 'transportInvoiceFileName', header: 'Transport Invoice FileName', isEditable: false, dataType: ColumnTypeEnum.file },
    { name: 'transportationTeamComments', header: 'Comments By Transport Team', isEditable: true },
    { name: 'specFileName', header: 'Spec Form/ Attachments', dataType: ColumnTypeEnum.file, isEditable: false },
    { name: 'commentsOnTR', header: 'comments OR Notes on TR' },
    { name: 'sendNotification', header: '', isSortable: false, filter: false, selectionMode: 'multiple' }
  ];

  onPaginationChange(event: PaginatorPageChangeEvent) {
    const { dashboardPO } = this.props;
    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.pagination = pagination;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  onSort(event: DataTableStateEvent) {
    const { dashboardPO } = this.props;

    const sortOptions = DashboardHelper.getSortingOptions(event.sortField, event.sortOrder);
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.sorting = sortOptions;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  onDataTableFilter(event: DataTableStateEvent) {
    if (this.props.setFilterMetaData && event.filters) {
      this.props.setFilterMetaData(event.filters);
    }
    const filters: Filters[] = DashboardHelper.setFilterData(event.filters, this.columns);

    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);

    const { dashboardPO } = this.props;

    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.filters = filters;
      dashboardPO.dashboardRequest.pagination = pagination;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  setFilterMetaData(filterMetaData?: DataTableFilterMeta) {
    const { dashboardPO } = this.props;
    if (this.props?.setFilterMetaData && filterMetaData) {
      this.props.setFilterMetaData(filterMetaData);

      const filters: Filters[] = DashboardHelper.setFilterData(filterMetaData, this.columns);

      if (dashboardPO?.dashboardRequest) {
        dashboardPO.dashboardRequest.filters = filters;
        dashboardPO.dashboardRequest.isDownload = false;
      }
      setTimeout(() => {
        this.getDashboardPO();
      }, 100);
    }
  }

  getSpecificFieldData(columnName: string) {
    const currentColumn = this.columns.find(x => x.name === columnName);
    const { dashboardPO } = this.props;

    if (this.props?.getSpecificFieldDataWithRequest && dashboardPO?.dashboardRequest) {
      this.props.getSpecificFieldDataWithRequest(columnName, currentColumn?.dataType, dashboardPO?.dashboardRequest?.filters);
    }
  }

  getDropdownDataByColumnName(columnName: string) {
    const currentColumn = this.columns?.find(x => x.name === columnName);

    if (this.props?.getDropdownDataByColumnName) {
      this.props.getDropdownDataByColumnName(columnName, currentColumn?.dataType);
    }
  }

  uploadClickEvent() {
    this.setState({ isUploadDialogVisible: true });
  }

  invoiceUploadHandler(event: FileUploadHandlerEvent) {
    if (this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();

    if (this.props.resetUploadApiMessage)
      this.props.resetUploadApiMessage();

    UploadDownloadHelper.invoiceUploadHandler(event).then((formData: FormData) => {
      this.setState({ isUploadDialogVisible: false });
      if (this.props.uploadTransportationFile) {
        this.props.uploadTransportationFile(formData).then((response: string) => {
          this.setToastFromResponse(response, 'Upload Process');
        });
      }
    });
  }

  downloadClickEvent() {
    const { dashboardPO } = this.props;
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.isDownload = true;
    }
    this.getDashboardPO();
  }

  private downloadExcelAfterResponse(dashboardPO: DashboardState | undefined) {
    setTimeout(() => {
      if (!dashboardPO?.downloadUploadResponse.downloadLoading && dashboardPO?.downloadUploadResponse?.dashboardResponse) {
        const response = dashboardPO?.downloadUploadResponse.dashboardResponse;
        let downloadItems: any[] = [];
        if (response?.data) {
          downloadItems = response.data.map((x: any) => (UploadDownloadHelper.setDownloadObject(x, this.columns)));
        }
        UploadDownloadHelper.exportExcel<DashboardViewModel>(downloadItems, 'transportation');
        if (this.props.resetDownloadDashboardData) {
          this.props.resetDownloadDashboardData();
        }
      }
    }, 50);
  }

  sendVendorNotificationEmail(notificationData: any, poData: any) {
    let poNum: string[] = [];
    let poId: string[] = [];
    if (notificationData !== undefined || notificationData !== null || notificationData !== '' ||
      poData !== undefined || poData !== '' || poData !== null) {
      poNum = Array.from(new Set(poData.map((item: any) => item.poNumber)));
      poId = Array.from(new Set(poData.map((item: any) => item.polId)));
      const updateRequest: sendNotification = {
        //notificationDetails: [],
        notificationSubject: notificationData.emailSubject,
        notificationBody: notificationData.emailBody,
        //vendorName: poData[0].transporter,
        poNumber: poNum,
        polId: poId,
        //poDate: poData[0].poDate,
        templateName: notificationData.name,
        emailSender: notificationData.emailSender,
        emailCc: notificationData.emailCc,
        fromScreen: 'Transportation Team View'
      };

      if (this.props.sendNotificationEmail) {
        this.props.sendNotificationEmail(updateRequest).then((response: any) => {
          console.log(response);
          if (response) {
            response.includes('Notifiction Sent Successfully')
              ? this.showNotificationStickyToast('success', response, true)
              : this.showNotificationStickyToast('warn', response, true);
            this.setState({ isNotificationDialogVisible: false });
          }
        });
      }
    }
  }

  showStickyToast(severity: 'info' | 'success' | 'warn' | 'error' | undefined, message: string, summary: string) {
    setTimeout(() => {
      this.toast?.current?.show({ severity: severity, summary: summary, detail: message, sticky: true });
    }, 50);
  }

  transportationUpdate(request: any) {
    if (this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();

    if (this.props.resetUploadApiMessage)
      this.props.resetUploadApiMessage();

    const updateRequest: TransportationUpdateRequest = {
      id: request.polId,
      trDate: request.trDate ? DateHelper.formatDate(new Date(request.trDate)) : '',
      transportationContractStartDate: request.transportationContractStartDate
        ? DateHelper.formatDate(new Date(request.transportationContractStartDate)) : '',
      edr: request.edr?.trim(),
      priority: request.priority?.trim(),
      transporter: request.transporter?.trim(),
      bidNumber: request.bidNumber?.trim(),
      bidReceived: request.bidReceived?.trim(),
      differenceOn: request.differenceOn?.trim(),
      shippingStatus: request.shippingStatus?.trim(),
      transportationInvoiceNumber: request.transportationInvoiceNumber?.trim(),
      transportationTeamComments: request.transportationTeamComments?.trim(),
      transportationInvoiceStatus: request.transportationInvoiceStatus?.trim(),
      pickupLocation: request.pickupLocation?.trim()
    };
    this.setState({ shouldShowToast: true });
    if (this.props.updateTransportationPO) {
      this.props.updateTransportationPO(updateRequest).then((editAPIMessage: string) => {
        this.setToastFromResponse(editAPIMessage, 'Update Process');
        this.getDashboardPO();
        this.setState({ showTransportationDialog: false });
      });
    }
  }

  private setToastFromResponse(apiResponseMessage: string, summary: string) {
    if (apiResponseMessage) {
      apiResponseMessage.includes('success')
        ? this.showStickyToast('success', apiResponseMessage, summary)
        : this.showStickyToast('warn', apiResponseMessage, summary);
    }
  }

  renderTransportationDialog() {
    const { dashboardPO } = this.props;
    const dropdownOptions = dashboardPO?.dropdownOptions;
    const editResponse = dashboardPO?.editResponse;
    return (
      <Dialog header='Transportation Edit' className='dashboard-edit-dialog edit-dialog rounded text-center ml-0 mb-2' visible={this.state.showTransportationDialog} onHide={() => this.setState({ showTransportationDialog: false })}>
        <DashboardEdit dashboardEditData={this.state.transportEditData} columns={this.columns}
          DashboardEditLoading={editResponse?.editLoading ?? false}
          dropdownOptions={dropdownOptions}
          getSpecificFieldData={this.getDropdownDataByColumnName}
          onClose={() => this.setState({ showTransportationDialog: false })}
          onSubmit={(request) => this.transportationUpdate(request)}
        />
      </Dialog>
    );
  }

  onEditClick(data: DataTableValue) {
    this.setState({ transportEditData: data });
    this.setState({ showTransportationDialog: true });
  }

  sendNotification(selectData: any) {
    if (selectData === undefined || selectData == null || selectData === '') {
      this.showNotificationStickyToast('error', 'Please select PO line item.', true);
    }
    else {
      this.getNotificationTemplate();
      this.setState({ selectedData: selectData });
      this.setState({ isNotificationDialogVisible: true });
    }
  }

  getNotificationTemplate() {
    if (this.props.getNotificationTemplate) {
      this.props.getNotificationTemplate('Transportation Team View').then((responseData: any) => {
        if (responseData) {
          this.setState({ templateData: responseData });
        }
      });
    }
  }

  showNotificationStickyToast(severity: 'info' | 'success' | 'warn' | 'error' | undefined, message: string, isResponseMessage: boolean = false) {
    setTimeout(() => {
      this.toast?.current?.show({ severity: severity, summary: 'Notification Process', detail: message, sticky: true });
      if (isResponseMessage) {
        this.setState({ shouldShowToast: false });
      }
    }, 20);
  }

  onClose() {
    this.setState({ isNotificationDialogVisible: false });
  }

  renderSendNotificationDialog() {
    return (
      <Sidebar className='w-full md:w-20rem lg:w-30rem text-center ml-0' visible={this.state.isNotificationDialogVisible} position="right" onHide={() => this.setState({ isNotificationDialogVisible: false })}>
        <NotificationPopup onClose={this.onClose} categories={this.state.templateData} selectedPoData={this.state.selectedData} sendResetPasswordEmail={this.sendVendorNotificationEmail} />
      </Sidebar>);
  }

  getSpecFormData(event: any, columnName: string, rowValue: any) {
    event?.preventDefault();
    this.setState({ pdfViewerFetching: true });
    this.setState({ showPdfViewerDialog: true });
    this.setState({ specDocName: rowValue[columnName] });
    if (this.props.getSpecDocumentData) {
      const columnDataName = UploadDownloadHelper.getValueColumnByDisplayColumn(columnName);
      this.props.getSpecDocumentData(rowValue['polId'], columnDataName).then((specFormData: string[]) => {
        this.setState({ specFormData: specFormData ? specFormData : '' });
        this.setState({ pdfViewerFetching: false });
      });
    }
  }

  renderPdfViewer() {
    const { showPdfViewerDialog, specFormData, specDocName, pdfViewerFetching } = this.state;
    return (
      <Dialog header={specDocName} className='rounded text-center pdf-viewer-dialog'
        visible={showPdfViewerDialog}
        onHide={() => this.setState({ showPdfViewerDialog: false })}>
        <PdfViewer base64String={specFormData} loading={pdfViewerFetching} fileName={specDocName}
          onClose={() => this.setState({ showPdfViewerDialog: false })} />
      </Dialog>
    );
  }

  /**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
  render() {
    const { dashboardPO } = this.props;
    const request = dashboardPO?.dashboardRequest;
    const response = dashboardPO?.dashboardResponse;
    const filters = dashboardPO?.filters;
    const sortOptions = request?.sorting;
    const dropdownOptions = dashboardPO?.dropdownOptions;
    const downloadUploadResponse = dashboardPO?.downloadUploadResponse;

    const paginationOptions: PaginationInfo = DashboardHelper.getPaginationOptions(request, response?.totalCount);
    const formattedRecords = response?.data?.map((x: any) => (UploadDownloadHelper.setDashboardViewModelObject(x, this.columns)));
    this.downloadExcelAfterResponse(dashboardPO);

    return (
      <React.Fragment>
        <Toast ref={this.toast} />
        {this.renderSendNotificationDialog()}
        {this.renderTransportationDialog()}
        {this.renderPdfViewer()}
        <div className="card m-0">
          <h6 className="card-title ml-3 mt-2">Transportation Team</h6>
          <TabView>
            <TabPanel header="Transportation Master">
              <div style={{ position: 'relative', width: '100%', height: '95%' }}>
                <DashboardTable columns={this.columns} rows={formattedRecords ?? []}
                  loading={this.props.dashboardPO?.isFetching ?? false}
                  paginationOptions={paginationOptions}
                  filters={filters}
                  sortOptions={sortOptions}
                  minRecordCount={this.minRecordCount}
                  onPaginationChange={this.onPaginationChange}
                  onSort={this.onSort}
                  dropdownOptions={dropdownOptions}
                  onDataTableFilter={this.onDataTableFilter}
                  setFilterMetaData={this.setFilterMetaData}
                  getSpecificFieldData={this.getSpecificFieldData}
                  isSendNotification={true}
                  onEditClick={this.onEditClick}
                  sendNotification={this.sendNotification}
                  specFileLinkClick={this.getSpecFormData}
                >
                  <div className="p-0">
                    <SplitButton loading={downloadUploadResponse?.uploadLoading} className="mr-1"
                      label="Upload" onClick={this.uploadClickEvent} size="small" model={this.templateItems} />
                    <Button type="button" loading={downloadUploadResponse?.downloadLoading}
                      label="Download" onClick={this.downloadClickEvent} size="small" />
                    <Dialog header="Upload File" visible={this.state.isUploadDialogVisible} style={{ width: '50vw' }}
                      onHide={() => this.setState({ isUploadDialogVisible: false })}>
                      <div className="card">
                        <FileUpload name="TransportUpload" accept=".xls, .xlsx"
                          uploadHandler={this.invoiceUploadHandler} customUpload={true}
                          chooseLabel="Select a File"
                          maxFileSize={15000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                      </div>
                    </Dialog>
                  </div>
                </DashboardTable>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
  const { internalUser: auth, dashboard } = state;

  return {
    ...auth,
    dashboardPO: dashboard
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
  isDashboardLayoutEnable: () => dispatch(FlagsActions.isDashboardLayoutEnable()),
  getDashboardPO: (request: DashboardRequest) => {
    return dispatch(DashboardActions.getTransportationPO(request));
  },
  setFilterMetaData: (filters?: DataTableFilterMeta | undefined) => dispatch(DashboardActions.setFilterMetaData(filters)),
  resetDashboardData: () => dispatch(DashboardActions.resetDashboardData()),
  getSpecificFieldDataWithRequest: (columnName: string, columnType: ColumnTypeEnum | undefined, filters?: Filters[] | undefined) =>
    dispatch(DashboardActions.getSpecificFieldDataWithRequest(columnName, columnType, filters)), resetUploadApiMessage: () => dispatch(DashboardActions.resetUploadApiMessage()),
  resetDownloadDashboardData: () => dispatch(DashboardActions.resetDownloadDashboardData()),
  updateTransportationPO: (request: any) => dispatch(DashboardActions.updateTransportationPO(request)),
  resetUpdateApiMessage: () => dispatch(DashboardActions.resetUpdateApiMessage()),
  uploadTransportationFile: (request: any) => {
    return dispatch(DashboardActions.uploadTransportationFile(request));
  },
  requestedPageReset: () => dispatch(AuthActions.requestedPageReset()),
  getDropdownDataByColumnName: (columnName: string, columnType: ColumnTypeEnum | undefined) =>
    dispatch(DashboardActions.getDropdownDataByColumnName(columnName, columnType)),
  getNotificationTemplate: (FromScreen: string) => {
    return dispatch(DashboardActions.getNotificationTemplate(FromScreen));
  },
  sendNotificationEmail: (FromScreen: string) => {
    return dispatch(DashboardActions.sendNotificationEmail(FromScreen));
  },
  getSpecDocumentData: (polId: string, columnName: string) => dispatch(DashboardActions.getSpecDocumentData(polId, columnName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportationContainer as any);