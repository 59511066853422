import initialState from '../initialState';
import { FlagsActionTypes } from './types';
import { FlagsState } from '.';
import { FlagsAction } from './actions';

export const flagsReducer = (state = initialState.flags, action: FlagsAction): FlagsState => {
    switch (action.type) {
        case FlagsActionTypes.FLAGS_READY: {
            return {
                ...state,
                flagsReady: true,
            };
        }
        case FlagsActionTypes.UPDATE_FLAG: {
            const { name, enabled } = action.payload;

            return {
                ...state,
                [name]: enabled,
            };
        }
        case FlagsActionTypes.ISDASHBOARDLAYOUT_SET: {
            return {
                ...state,
                isDashboardLayout: true
            };
        }
        case FlagsActionTypes.ISDASHBOARDLAYOUT_RESET: {
            return {
                ...state,
                isDashboardLayout: false
            };
        }
        default:
            return state;
    }
};
