import { ApplicationState } from '.';
import DashboardHelper from '../helpers/DashboardHelper';
import { UserTypeEnum } from '../shared/models/UserTypeEnum';
const initialState: ApplicationState = {
    internalUser: {
        userName: '',
        userType: undefined,
        accessTokens: {},
        roles: [],
        requestedPage: undefined,
        isAuthenticated: false,
        authenticating: false,
        msalReady: false,
        idToken: undefined
    },
    flags: {
        flagsReady: false,
        isDashboardLayout: false,
        userType: UserTypeEnum.none
    },
    externalUser: {
        userName: '',
        userType: undefined,
        accessTokens: {},
        roles: [],
        requestedPage: undefined,
        isAuthenticated: false,
        authenticating: false,
        idToken: undefined
    },
    dashboard: {
        dashboardRequest: {
            filters: undefined,
            sorting: {
                columnName: undefined,
                isAsc: true
            },
            pagination: {
                pageNumber: 1,
                pageSize: DashboardHelper.getMinRecordCount()
            },
            startDate: undefined,
            endDate: undefined,
            isDownload: false
        },
        dashboardResponse: undefined,
        dropdownOptions: {
            isFetching: false,
            columnOptions: []
        },
        downloadUploadResponse: {
            uploadLoading: false,
            downloadLoading: false,
            dashboardResponse: undefined,
            uploadAPIMessage: ''
        },
        editResponse:{
            editLoading: false,           
            editAPIMessage: ''
        }, 
        isFetching: false,
        poGridData: {
            poGridRequest: {
                filters: undefined,
                sorting: {
                    columnName: undefined,
                    isAsc: true
                },
                pagination: {
                    pageNumber: 1,
                    pageSize: DashboardHelper.getMinRecordCount()
                },
                startDate: undefined,
                endDate: undefined,
                isDownload: false
            },
            poGridResponse: undefined,
            isFetching: false
        },      
    }      
    
};

export default initialState;
