import { ColumnTypeEnum } from '../shared/models/ColumnTypeEnum';
import { AdminOperationsEnum } from '../shared/models/Enums/AdminOperationsEnum';
import { HeadCell } from '../shared/models/HeadCell';

class AdminTemplateColumnHelper {
    public static getColumns(operation: AdminOperationsEnum): HeadCell<any>[] {
        switch (operation) {
            case AdminOperationsEnum.viewAll:
                return [
                    { name: 'edit', header: 'Edit', isSortable: false, filter: false, dataType: ColumnTypeEnum.edit },
                    { name: 'delete', header: 'Delete', isSortable: false, filter: false, dataType: ColumnTypeEnum.delete },
                    { name: 'name', header: 'Template Name', isEditable: false },
                    { name: 'isActive', header: 'Status', dataType: ColumnTypeEnum.inputSwitch },
                    { name: 'modifiedDate', header: 'Updated Date', dataType: ColumnTypeEnum.date },
                    { name: 'emailSender', header: 'Email Sender' },
                    { name: 'emailCc', header: 'Email Cc' },
                    { name: 'view', dataType: ColumnTypeEnum.view }
                ];
            case AdminOperationsEnum.view:
                return [
                    { name: 'name', header: 'Name', isEditable: false, dataType: ColumnTypeEnum.text, isRequired: true },
                    { name: 'emailSubject', header: 'Email Subject', isEditable: false, dataType: ColumnTypeEnum.text },
                    { name: 'emailBody', header: 'Email Body', isEditable: false, dataType: ColumnTypeEnum.textArea },
                    { name: 'fromScreen', header: 'From Screen', dataType: ColumnTypeEnum.text, isEditable: false, isRequired: true  },
                    { name: 'emailSender', header: 'Email Sender', dataType: ColumnTypeEnum.text, isEditable: false },
                    { name: 'emailCc', header: 'Email Cc', dataType: ColumnTypeEnum.text, isEditable: false },
                    { name: 'isActive', header: 'Status', dataType: ColumnTypeEnum.inputSwitch, isEditable: false }
                ];
            case AdminOperationsEnum.edit:
                return [
                    { name: 'name', header: 'Name', isEditable: false, dataType: ColumnTypeEnum.text, isRequired: true },
                    { name: 'emailSubject', header: 'Email Subject', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'emailBody', header: 'Email Body', isEditable: true, dataType: ColumnTypeEnum.textArea },
                    { name: 'fromScreen', header: 'From Screen', dataType: ColumnTypeEnum.text, isEditable: false, isRequired: true  },
                    { name: 'emailSender', header: 'Email Sender', dataType: ColumnTypeEnum.staticDropdown, isEditable: true },
                    { name: 'emailCc', header: 'Email Cc', dataType: ColumnTypeEnum.text, isEditable: true },
                    { name: 'isActive', header: 'Status', dataType: ColumnTypeEnum.inputSwitch, isEditable: true }
                ];
            case AdminOperationsEnum.add:
                return [
                    { name: 'name', header: 'Name', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true },
                    { name: 'EmailSubject', header: 'Email Subject', dataType: ColumnTypeEnum.text, isEditable: true, isRequired: true },
                    { name: 'EmailBody', header: 'Email Body', dataType: ColumnTypeEnum.textArea, isEditable: true, isRequired: true },
                    { name: 'fromScreen', header: 'From Screen', dataType: ColumnTypeEnum.staticDropdown, isEditable: true, isRequired: true  },
                    { name: 'emailSender', header: 'Email Sender', dataType: ColumnTypeEnum.staticDropdown, isEditable: true },
                    { name: 'emailCc', header: 'Email Cc', dataType: ColumnTypeEnum.text, isEditable: true },
                    { name: 'isActive', header: 'Status', dataType: ColumnTypeEnum.inputSwitch, isEditable: true }
                ];
            default:
                return [];
        }
    }
}

export default AdminTemplateColumnHelper;

