import React, { Component } from 'react';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { AuthActions } from '../../../store/auth';
import { FlagsActions } from '../../../store/flags';
import { TabView, TabPanel } from 'primereact/tabview';
import { Card } from 'primereact/card';
import './adminContainer.scss';
import routePaths from '../../../shared/constants/routePaths';
import { Link } from 'react-router-dom';

interface ComponentProps {
    isAuthenticated?: boolean;
}

type AdminContainerProps =
    ComponentProps
    & typeof mapDispatchToProps;

class AdminContainerComponent extends Component {

    constructor(props: AdminContainerProps) {
        super(props);
        props.isDashboardLayoutEnable();
        props.requestedPageReset();
    }

    render() {
        return (
            <React.Fragment>
                <div className='card m-0 admin-container'>
                    <h6 className='card-title ml-3 mt-2'>Admin Portal</h6>
                    <TabView>
                        <TabPanel header='Admin Portal'>
                            <div className='gap-3'>
                                <div className='card'>
                                    <Card title='Vendor List' className='text-center'>
                                        <Link type='button' className="p-button p-component link-option p-btn ml-3 p-button-sm" to={routePaths.adminVendor} >View All Vendor</Link>
                                    </Card>
                                </div>
                                <div className='card mt-2'>
                                    <Card title='Station Contact List' className='text-center'>
                                        <Link type='button' className="p-button p-component link-option p-btn ml-3 p-button-sm" to={routePaths.adminStationContact} >View All Station Contact</Link>
                                    </Card>
                                </div>
                                <div className='card mt-2'>
                                    <Card title='Decal Address' className='text-center'>
                                        <Link type='button' className="p-button p-component link-option p-btn ml-3 p-button-sm" to={routePaths.adminDecal} >View All Decal</Link>
                                    </Card>
                                </div>
                                <div className='card mt-2'>
                                    <Card title='Notification Email Template' className='text-center'>
                                        <Link type='button' className="p-button p-component link-option p-btn ml-3 p-button-sm" to={routePaths.adminTemplate} >View All Template</Link>
                                    </Card>
                                </div>
                                <div className='card mt-2'>
                                    <Card title='User List' className='text-center'>
                                        <Link type='button' className="p-button p-component link-option p-btn ml-3 p-button-sm" to={routePaths.adminVendorUser} >View All Users</Link>
                                    </Card>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
    const { internalUser: auth, dashboard } = state;

    return {
        ...auth,
        dashboardPO: dashboard
    };
};

const mapDispatchToProps = {
    requestedPageReset: AuthActions.requestedPageReset,
    isDashboardLayoutEnable: FlagsActions.isDashboardLayoutEnable,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainerComponent as any);