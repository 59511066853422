import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import './notificationPopup.scss';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DateHelper } from '../../helpers';

interface Props {
    onClose?: () => void;
    categories?: any;
    selectedPoData?: any;
    sendResetPasswordEmail?: (SelectedCategory: any, poData: any) => void;
}

interface Category {
    templateId: string;
    emailSubject: string;
    emailBody: string;
    name: string;
    modifiedDate: string;
    emailSender: string;
    emailCc: string;
}

export default function NotificationsPopup({ onClose, categories, selectedPoData, sendResetPasswordEmail }: Props) {

    useEffect(() => {
        setPOData(selectedPoData);
    }, [selectedPoData]);


    const onclosePopup = (event: any) => {
        event.preventDefault();
        if (onClose) {
            onClose();
        }
    };

    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [isEdit, setEdit] = useState<boolean>(false);
    const [isSelect, setSelected] = useState<boolean>(false);
    const [isMultiLine, setMultiLine] = useState<boolean>(false);
    const [isChecked, setChecked] = useState<number>();
    const [PoData, setPOData] = useState<any>(selectedPoData);
    const [emailBody, setEmailBody] = useState<string>('');
    const [emailSubject, setEmailSubject] = useState<string>('');

    const onCheckedChange = (e: any, i: any) => {
        setSelected(false);
        setSelectedCategory(e);
        setChecked(i);
        if (selectedCategory) {
            setEmailBody(selectedCategory.emailBody);
            setEmailSubject(selectedCategory.emailSubject);
        }
    };

    const sendResetEmail = (event: any) => {
        event.preventDefault();
        if (selectedCategory) {
            if (sendResetPasswordEmail) {
                sendResetPasswordEmail(selectedCategory, PoData);
            }
        }
        else {
            setSelected(true);
        }
    };

    const OnEmailBodyChange = (event: any, value: string) => {
        event.preventDefault();
        setEmailBody(value);
        if (selectedCategory)
            selectedCategory.emailBody = emailBody;
    };

    const OnEmailSubjectChange = (event: any, value: string) => {
        event.preventDefault();
        setEmailSubject(value);
        if (selectedCategory)
            selectedCategory.emailSubject = emailSubject;
    };

    const renderTemplateEditSidebar = () => {
        if (selectedCategory) {
            var poCount = Array.from(new Set(PoData.map((item: any) => item.poNumber)));
            if (poCount.length > 1) { 
                setEdit(false);
                setSelected(false);
                setMultiLine(true);
            }
            else {
                setEmailBody(selectedCategory.emailBody);
                setEmailSubject(selectedCategory.emailSubject);
                setEdit(true);
                setSelected(false);
                setMultiLine(false);
            }
        }
        else {
            setEdit(false);
            setSelected(true);
            setMultiLine(false);
        }
    };
    return (
        <React.Fragment>
            {!isEdit &&
                <div id='dvTemplate' className='card flex justify-content-center p-3'>
                    {isSelect &&
                        <label className='lbl-error'>*Please select a template.</label>
                    }
                    {isMultiLine &&
                        <label className='lbl-error'>*Edit is not allowed if multiple PO line item are selected!</label>
                    }
                    <h6 className='mb-4'>Select A Template</h6>
                    <div className='flex flex-column gap-3 text-left'>

                        {categories?.map((category: Category, i: number) => {
                            return (
                                <div key={category?.templateId} className='flex align-items-center'>
                                    <RadioButton inputId={category?.templateId} name='category' value={category} onChange={(e: RadioButtonChangeEvent) => onCheckedChange(e.value, i)} checked={isChecked === i ? true : false} />
                                    <label htmlFor={category?.templateId} className='lbl ml-2'>{category?.name}<br /><span className='spn'>Last updated: {DateHelper.fromUTCDateTimeToLocalDate(category?.modifiedDate, 'MM/DD/YYYY')}</span></label>
                                </div>
                            );
                        })}
                    </div>
                    <div className='text-center mt-2'>
                        <Button label='Send' type='submit' onClick={sendResetEmail} />
                        <Button label='Edit' className='ml-2' onClick={() => renderTemplateEditSidebar()} />
                        <Button label='Cancel' className='ml-2' onClick={(e: React.MouseEvent<HTMLButtonElement>) => onclosePopup(e)} />
                    </div>
                </div>
            }
            {isEdit &&
                <div id='dvEdit'>
                    <form className='frm flex flex-column gap-2 border-0 text-left'>
                        <h6 className='mb-4 text-center'>Edit Template</h6>
                        <label className='label'>Template Name<span className='spnMandatory'>*</span></label>
                        <InputText id='txtTempName' value={selectedCategory?.name ?? ''} disabled />
                        <label className='label'>Subject<span className='spnMandatory'>*</span></label>
                        <InputText id='txtSubject' value={emailSubject} onChange={(e: React.ChangeEvent<HTMLInputElement>) => OnEmailSubjectChange(e, e.target.value)} />
                        <label className='label'>Content<span className='spnMandatory'>*</span></label>
                        <InputTextarea className='emailtextarea' id='txtContent' value={emailBody} autoResize rows={5} cols={50}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => OnEmailBodyChange(e, e.target.value)}
                        />
                        <div className='text-center mt-2'>
                            <Button label='Send' onClick={sendResetEmail} />
                            <Button label='Cancel' className='ml-2' type='submit' onClick={(e: React.MouseEvent<HTMLButtonElement>) => onclosePopup(e)} />
                        </div>
                    </form>
                </div>}
        </React.Fragment>
    );
}



