import React, { Component, Dispatch } from 'react';
import DynamicCrudComponent from '../../../shared/components/dynamic-crud-component/dynamic-crud-component';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';
import { Link } from 'react-router-dom';
import routePaths from '../../../shared/constants/routePaths';
import { DashboardRequest } from '../../../shared/models/dashboard';
import { AdminActions } from '../../../store/admin';
import { HeadCell } from '../../../shared/models/HeadCell';
import { AdminOperationsEnum } from '../../../shared/models/Enums/AdminOperationsEnum';
import { AdminCRUDTypeEnum } from '../../../shared/models/Enums/AdminCRUDTypeEnum';
import { AdminDecalViewModel } from '../../../shared/models/AdminDecalViewModel';
import AdminDecalColumnHelper from '../../../helpers/AdminDecalColumnHelper';

interface ComponentProps {
    getDecals?: (Request: DashboardRequest) => any;
    updateDecal?: (request: any) => any;
    deleteDecal?: (request: string) => any;
    addDecal?: (request: string) => any;
}

class AdminDecalContainer extends Component<ComponentProps> {

    columns: HeadCell<AdminDecalViewModel>[] = AdminDecalColumnHelper.getColumns(AdminOperationsEnum.viewAll);


    render() {
        return (
            <div className="card m-0 flex">
                <h6 className="card-title mx-3 mt-2"><Link className='mr-2' to={routePaths.admin}>Admin</Link>{'> View All Decal'}</h6>
                <TabView>
                    <TabPanel header="Decal Management">
                        <div style={{ position: 'relative', width: '100%', height: '95%' }}>
                            <DynamicCrudComponent columns={this.columns}
                                getDashboard={this.props.getDecals}
                                downloadFilePrefix='Decal_list'
                                editDialogHeader='Decal'
                                deleteApi={this.props.deleteDecal}   
                                updateApi={this.props.updateDecal}
                                adminPageType={AdminCRUDTypeEnum.decal}
                                addApi={this.props.addDecal}
                            />
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
    const { internalUser: auth, dashboard } = state;

    return {
        ...auth,
        dashboardPO: dashboard
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
    getDecals: (request: DashboardRequest) => {
        return dispatch(AdminActions.getAllDecal(request));
    },
    updateDecal: (request: any) => dispatch(AdminActions.updateDecal(request)),
    deleteDecal: (request: string) => dispatch(AdminActions.deleteDecal(request)),
    addDecal: (request: string) => dispatch(AdminActions.addDecal(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDecalContainer as any);

