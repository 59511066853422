import React, { Component, Dispatch, RefObject } from 'react';
import { DashboardTable } from '../dynamic-data-table/dynamic-data-table';
import { HeadCell } from '../../models/HeadCell';
import { Button } from 'primereact/button';
import { DashboardRequest, DashboardViewModel, Filters, PaginationInfo } from '../../models/dashboard';
import { DataTableStateEvent, DataTableFilterMeta, DataTableValue } from 'primereact/datatable';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import DashboardHelper from '../../../helpers/DashboardHelper';
import { DashboardActions, DashboardState } from '../../../store/dashboard';
import { ColumnTypeEnum } from '../../models/ColumnTypeEnum';
import UploadDownloadHelper from '../../../helpers/UploadDownloadHelper';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { DashboardEdit } from '../dashboardEdit/dashboardEdit';
import './dynamic-crud-component.scss';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { AuthActions } from '../../../store/auth';
import { FlagsActions } from '../../../store/flags';
import AdminScreenColumnHelper from '../../../helpers/AdminScreenColumnHelper';
import { AdminCRUDTypeEnum } from '../../models/Enums/AdminCRUDTypeEnum';
import { AdminOperationsEnum } from '../../models/Enums/AdminOperationsEnum';

interface ComponentProps {
  isAuthenticated?: boolean;
  dashboardDataList?: DashboardState;
  filters?: DataTableFilterMeta;
  downloadFilePrefix?: string;
  editDialogHeader?: string;
  columns?: HeadCell<any>[];
  adminPageType?: AdminCRUDTypeEnum;

  isDashboardLayoutEnable?: () => void;
  resetDashboardData?: () => void;
  getDashboard?: (request: DashboardRequest) => any;
  setFilterMetaData?: (dataTableFilterMeta: DataTableFilterMeta | undefined) => void;
  getSpecificFieldDataWithRequest?: (columnName: string, dataType: ColumnTypeEnum | undefined, filters?: Filters[] | undefined, queryType?: AdminCRUDTypeEnum) => void;
  getDropdownDataByColumnName?: (columnName: string, dataType: ColumnTypeEnum | undefined) => void;
  updateApi?: (Request: any) => any;
  resetUpdateApiMessage?: () => void;
  resetDownloadDashboardData?: () => void;
  requestedPageReset?: () => void;
  getSpecDocumentData?: (polId: string, columnName: string) => any;
  deleteApi?: (Request: any) => any;
  addApi?: (Request: any) => any;
}

class DynamicCrudComponent extends Component<ComponentProps> {
  minRecordCount: number = DashboardHelper.getMinRecordCount();
  toast: RefObject<Toast> | undefined = undefined;

  state = {
    showEditDialog: false,
    editRequestData: undefined,
    isReadOnly: false,
    columns: [],
    headerSuffixLabel: '',
    processEvent: undefined
  };

  constructor(props: ComponentProps) {
    super(props);
    this.toast = React.createRef();

    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.onDataTableFilter = this.onDataTableFilter.bind(this);
    this.setFilterMetaData = this.setFilterMetaData.bind(this);
    this.getSpecificFieldData = this.getSpecificFieldData.bind(this);
    this.getDropdownDataByColumnName = this.getDropdownDataByColumnName.bind(this);
    this.onSort = this.onSort.bind(this);
    this.downloadClickEvent = this.downloadClickEvent.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.renderEditDialog = this.renderEditDialog.bind(this);
    this.deleteApi = this.deleteApi.bind(this);
    this.onViewClick = this.onViewClick.bind(this);
    this.AddClickEvent = this.AddClickEvent.bind(this);
  }

  componentDidMount(): void {
    if (this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();

    if (this.props.isDashboardLayoutEnable) {
      this.props.isDashboardLayoutEnable();
    }

    if (this.props.resetDashboardData) {
      this.props.resetDashboardData();
    }

    if (this.props.requestedPageReset) {
      this.props.requestedPageReset();
    }

    this.setState({ columns: this.props.columns ?? [] });
  }

  getDashboardPO() {
    const { dashboardDataList } = this.props;

    if (this.props?.getDashboard && dashboardDataList?.dashboardRequest && !this.props.dashboardDataList?.isFetching) {
      this.props.getDashboard(dashboardDataList?.dashboardRequest);
    }
  }

  onPaginationChange(event: PaginatorPageChangeEvent) {
    const { dashboardDataList } = this.props;
    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);
    if (dashboardDataList?.dashboardRequest) {
      dashboardDataList.dashboardRequest.pagination = pagination;
      dashboardDataList.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  onSort(event: DataTableStateEvent) {
    const { dashboardDataList: dashboardPO } = this.props;

    const sortOptions = DashboardHelper.getSortingOptions(event.sortField, event.sortOrder);
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.sorting = sortOptions;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  onDataTableFilter(event: DataTableStateEvent) {
    if (this.props.setFilterMetaData && event.filters) {
      this.props.setFilterMetaData(event.filters);
    }
    const filters: Filters[] = DashboardHelper.setFilterData(event.filters, this.props.columns ?? []);

    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);

    const { dashboardDataList: dashboardPO } = this.props;

    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.filters = filters;
      dashboardPO.dashboardRequest.pagination = pagination;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  setFilterMetaData(filterMetaData?: DataTableFilterMeta) {
    const { dashboardDataList: dashboardPO } = this.props;
    if (this.props?.setFilterMetaData && filterMetaData) {
      this.props.setFilterMetaData(filterMetaData);

      const filters: Filters[] = DashboardHelper.setFilterData(filterMetaData, this.props.columns ?? []);

      if (dashboardPO?.dashboardRequest) {
        dashboardPO.dashboardRequest.filters = filters;
        dashboardPO.dashboardRequest.isDownload = false;
      }
      setTimeout(() => {
        this.getDashboardPO();
      }, 100);
    }
  }

  getSpecificFieldData(columnName: string) {
    const currentColumn = this.props?.columns?.find(x => x.name === columnName);
    const { dashboardDataList, adminPageType } = this.props;

    if (this.props?.getSpecificFieldDataWithRequest && dashboardDataList?.dashboardRequest) {
      this.props.getSpecificFieldDataWithRequest(columnName, currentColumn?.dataType, dashboardDataList?.dashboardRequest?.filters, adminPageType);
    }
  }

  getDropdownDataByColumnName(columnName: string) {
    const currentColumn = this.props?.columns?.find(x => x.name === columnName);
    const { dashboardDataList } = this.props;

    if (this.props?.getDropdownDataByColumnName && dashboardDataList?.dashboardRequest) {
      this.props.getDropdownDataByColumnName(columnName, currentColumn?.dataType);
    }
  }

  downloadClickEvent() {
    const { dashboardDataList } = this.props;
    if (dashboardDataList?.dashboardRequest) {
      dashboardDataList.dashboardRequest.isDownload = true;
    }
    this.getDashboardPO();
  }

  private downloadExcelAfterResponse(dashboardPO: DashboardState | undefined) {
    const columns = this.props.columns ?? [];
    setTimeout(() => {
      if (!dashboardPO?.downloadUploadResponse.downloadLoading && dashboardPO?.downloadUploadResponse?.dashboardResponse) {
        const response = dashboardPO?.downloadUploadResponse.dashboardResponse;
        let downloadItems: any[] = [];
        if (response?.data) {
          downloadItems = response.data.map((x: any) => (UploadDownloadHelper.setDownloadObject(x, columns, false)));
        }

        UploadDownloadHelper.exportExcel<DashboardViewModel>(downloadItems, this.props.downloadFilePrefix ?? '');
        if (this.props.resetDownloadDashboardData) {
          this.props.resetDownloadDashboardData();
        }
      }
    }, 50);
  }

  onEditClick(data: DataTableValue) {
    if (this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();

    const columns = AdminScreenColumnHelper.getColumns(this.props.adminPageType, AdminOperationsEnum.edit);

    this.setState({
      headerSuffixLabel: 'Edit',
      editRequestData: data,
      showEditDialog: true,
      isReadOnly: false,
      processEvent: AdminOperationsEnum.edit,
      columns: columns ?? []
    });
  }

  onViewClick(data: DataTableValue) {
    const columns = AdminScreenColumnHelper.getColumns(this.props.adminPageType, AdminOperationsEnum.view);

    this.setState({
      headerSuffixLabel: 'View',
      editRequestData: data,
      showEditDialog: true,
      isReadOnly: true,
      processEvent: AdminOperationsEnum.view,
      columns: columns ?? []
    });
  }

  AddClickEvent() {
    const columns = AdminScreenColumnHelper.getColumns(this.props.adminPageType, AdminOperationsEnum.add);
    const request = { isActive: true };
    this.setState({
      headerSuffixLabel: 'Add a new',
      editRequestData: request,
      showEditDialog: true,
      isReadOnly: false,
      processEvent: AdminOperationsEnum.add,
      columns: columns ?? []
    });
  }

  renderEditDialog() {
    const { dashboardDataList } = this.props;
    const { isReadOnly, columns, headerSuffixLabel, processEvent } = this.state;
    const dropdownOptions = dashboardDataList?.dropdownOptions;
    const editResponse = dashboardDataList?.editResponse;
    return (
      <Dialog header={`${headerSuffixLabel} ${this.props.editDialogHeader}`} className='dashboard-edit-dialog admin-edit-dialog rounded text-center ml-0 mb-2'
        visible={this.state.showEditDialog} onHide={() => this.setState({ showEditDialog: false })}>
        <DashboardEdit dashboardEditData={this.state.editRequestData} columns={columns}
          DashboardEditLoading={editResponse?.editLoading ?? false}
          dropdownOptions={dropdownOptions}
          isReadOnly={isReadOnly}
          processEvent={processEvent}
          getSpecificFieldData={this.getDropdownDataByColumnName}
          onClose={() => this.setState({ showEditDialog: false })}
          onSubmit={(request) => this.updateApi(request as any)}
        />
      </Dialog>
    );
  }

  updateApi(request: any) {
    if (this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();

    this.setState({ shouldShowToast: true });
    if (this.state.processEvent === AdminOperationsEnum.add) {
      if (this.props.addApi) {
        this.props.addApi(request).then((editAPIMessage: string) => {
          this.setResponseToast(editAPIMessage, `${this.props.editDialogHeader} Add`);
        });
      }
    } else {
      if (this.props.updateApi) {
        this.props.updateApi(request).then((editAPIMessage: string) => {
          this.setResponseToast(editAPIMessage, `${this.props.editDialogHeader} Update`);
        });
      }
    }
  }

  private setResponseToast(editAPIMessage: string, summary: string) {
    this.setToastFromResponse(editAPIMessage, summary);
    this.getDashboardPO();
    this.setState({ showEditDialog: false });
  }

  deleteApi(request: any) {
    if (this.props.deleteApi) {
      if (request['stationId']) {
        this.props.deleteApi(request['stationId']).then((editAPIMessage: string) => {
          this.setToastFromResponse(editAPIMessage, `${this.props.editDialogHeader} delete`);
          this.getDashboardPO();
        });
      }
      else {
        this.props.deleteApi(request['id']).then((editAPIMessage: string) => {
          this.setToastFromResponse(editAPIMessage, `${this.props.editDialogHeader} delete`);
          this.getDashboardPO();
        });
      }
    }
  }

  private setToastFromResponse(apiResponseMessage: string, summary: string) {
    if (apiResponseMessage) {
      apiResponseMessage?.toLowerCase()?.includes('success')
        ? this.showStickyToast('success', apiResponseMessage, summary)
        : this.showStickyToast('warn', apiResponseMessage, summary);
    }
  }

  showStickyToast(severity: 'info' | 'success' | 'warn' | 'error' | undefined, message: string, summary: string) {
    setTimeout(() => {
      this.toast?.current?.show({ severity: severity, summary: summary, detail: message, sticky: false });
    }, 50);
  }

  render() {
    const { dashboardDataList } = this.props;
    const request = dashboardDataList?.dashboardRequest;
    const response = dashboardDataList?.dashboardResponse;
    const filters = dashboardDataList?.filters;
    const sortOptions = request?.sorting;
    const dropdownOptions = dashboardDataList?.dropdownOptions;
    const downloadUploadResponse = dashboardDataList?.downloadUploadResponse;
    const columns = this.props.columns ?? [];
    const formattedRecords = response?.data?.map((x: any) => (UploadDownloadHelper.setDashboardViewModelObject(x, columns)));

    const paginationOptions: PaginationInfo = DashboardHelper.getPaginationOptions(request, response?.totalCount);
    this.downloadExcelAfterResponse(dashboardDataList);

    return (
      <React.Fragment>
        <Toast ref={this.toast} />
        {this.renderEditDialog()}
        <div className="card m-0">
          <div className="dashboard-container">
            <DashboardTable columns={columns} rows={formattedRecords ?? []}
              loading={this.props.dashboardDataList?.isFetching ?? false}
              paginationOptions={paginationOptions}
              filters={filters}
              sortOptions={sortOptions}
              minRecordCount={this.minRecordCount}
              onPaginationChange={this.onPaginationChange}
              onSort={this.onSort}
              dropdownOptions={dropdownOptions}
              onDataTableFilter={this.onDataTableFilter}
              setFilterMetaData={this.setFilterMetaData}
              getSpecificFieldData={this.getSpecificFieldData}
              isSendNotification={false}
              onEditClick={this.onEditClick}
              onViewClick={this.onViewClick}
              onDeleteClick={this.deleteApi}>
              <React.Fragment>
                <div className="flex justify-content-end p-0">
                  <Button className='ml-4' label={`Add a New ${this.props.editDialogHeader}`}
                    onClick={() => this.AddClickEvent()} size='small' type='button' />
                  <Button className='ml-4' loading={downloadUploadResponse?.downloadLoading} label='Download'
                    onClick={this.downloadClickEvent} size='small' type='button' />
                </div>
              </React.Fragment>
            </DashboardTable>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
  const { internalUser: auth, dashboard } = state;

  return {
    ...auth,
    dashboardDataList: dashboard
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
  isDashboardLayoutEnable: () => dispatch(FlagsActions.isDashboardLayoutEnable()),
  setFilterMetaData: (filters?: DataTableFilterMeta | undefined) => dispatch(DashboardActions.setFilterMetaData(filters)),
  resetDashboardData: () => dispatch(DashboardActions.resetDashboardData()),
  getSpecificFieldDataWithRequest: (columnName: string, columnType: ColumnTypeEnum | undefined, filters?: Filters[] | undefined, queryType?: AdminCRUDTypeEnum) =>
    dispatch(DashboardActions.getSpecificFieldDataWithRequest(columnName, columnType, filters, queryType)),
  resetDownloadDashboardData: () => dispatch(DashboardActions.resetDownloadDashboardData()),
  resetUpdateApiMessage: () => dispatch(DashboardActions.resetUpdateApiMessage()),
  requestedPageReset: () => dispatch(AuthActions.requestedPageReset()),
  getDropdownDataByColumnName: (columnName: string, columnType: ColumnTypeEnum | undefined) =>
    dispatch(DashboardActions.getDropdownDataByColumnName(columnName, columnType))
});

export default connect(mapStateToProps, mapDispatchToProps)(DynamicCrudComponent as any);
