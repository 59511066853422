import { DataTableFilterMeta, DataTableFilterMetaData, DataTableStateEvent } from 'primereact/datatable';
import { DashboardRequest, Filters, PaginationInfo, SortingInfo } from '../shared/models/dashboard';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { DropdownOptions } from '../store/dashboard';
import { TabViewTabChangeEvent } from 'primereact/tabview';
import { ColumnTypeEnum } from '../shared/models/ColumnTypeEnum';
import { HeadCell } from '../shared/models/HeadCell';
import DateHelper from './DateHelper';
import { TagOption } from '../shared/models/TagOptions';
import { TagTypeEnum } from '../shared/models/Enums/TagTypeEnum';
import StringHelper from './StringHelper';
import countriesList from '../assets/countries.json';

class DashboardHelper {
    static poStatus = [
        'Pending Bill',
        'Partially Received',
        'Rejected by Supervisor',
        'Pending Billing/Partially Received',
        'Fully Billed',
        'Pending Supervisor Approval',
        'Pending Receipt',
        'Closed'
    ];

    static tagOptions: TagOption[] = [
        {
            label: '',
            color: '',
            value: TagTypeEnum.noTag
        },
        {
            label: 'Cancelled',
            color: 'black',
            value: TagTypeEnum.cancelled
        },
        {
            label: 'Change Order',
            color: 'purple',
            value: TagTypeEnum.changOrder
        },
        {
            label: 'Completed',
            color: 'green',
            value: TagTypeEnum.completed
        },
        {
            label: 'Delay',
            color: 'red',
            value: TagTypeEnum.delay
        },
        {
            label: 'In Process',
            color: 'yellow',
            value: TagTypeEnum.inProcess
        }
    ];

    static closedStatusText = 'Closed';
    static minRecordCount = 100;

    public static setFilterData<T>(filterMetaData: DataTableFilterMeta | undefined, columns: HeadCell<T>[]) {
        const filters: Filters[] = [];
        if (filterMetaData) {
            Object.keys(filterMetaData).forEach((filter: string) => {
                const filterMeta = filterMetaData[filter] as DataTableFilterMetaData;
                let formattedFilterValues: string[] = [];
                if (filterMeta?.value) {
                    if (columns) {
                        const currentColumn = columns.find(x => x.name === filter);
                        if (currentColumn?.dataType === ColumnTypeEnum.date) {
                            const filterValues = (filterMeta.value as Date[]);
                            formattedFilterValues = filterValues.map(x => DateHelper.formatDate(x));
                        } else {
                            formattedFilterValues = filterMeta.value;
                        }
                    }
                    const filterObj: Filters = {
                        columnName: filter,
                        values: formattedFilterValues ?? []
                    };
                    filters.push(filterObj);
                }
            });
        }
        return filters;
    }

    public static getPagination(event: DataTableStateEvent | PaginatorPageChangeEvent | undefined, minRecordCount: number) {
        return {
            pageNumber: (event?.page ?? 0) + 1,
            pageSize: event?.rows ?? minRecordCount
        };
    }

    public static getPaginationOptions(request: DashboardRequest | undefined, totalCount?: number) {
        const pageSize = request?.pagination?.pageSize ?? 0;
        const paginationOptions: PaginationInfo = {
            first: (((request?.pagination?.pageNumber ?? 0) - 1) * pageSize),
            totalCount: totalCount ?? 0,
            pageSize: pageSize
        };
        return paginationOptions;
    }

    public static getSortingOptions(sortField: string | undefined, sortOrder?: 0 | 1 | -1 | null): SortingInfo {
        return {
            columnName: sortField,
            isAsc: sortOrder === 1 ? true : (sortOrder === -1 ? false : undefined)
        };
    }

    public static getDropDownOptions(columnName: string, dropdownOptions?: DropdownOptions, dataType?: ColumnTypeEnum): any[] {
        if (dropdownOptions?.columnOptions.some(x => x.columnName === columnName && x.options?.length > 0)) {
            const currentDropdownOptions = dropdownOptions?.columnOptions
                .find(x => x.columnName === columnName);
            if (currentDropdownOptions?.options?.length) {
                return currentDropdownOptions.options?.map(x => (DashboardHelper.setDropdownOptionsByType(x, dataType)));
            } else {
                return [];
            }
        }
        return [];
    }

    static setDropdownOptionsByType(actualDropdownValue: string | Date | number, dataType?: ColumnTypeEnum) {
        if (dataType === ColumnTypeEnum.date) {
            return { label: actualDropdownValue, value: new Date(actualDropdownValue?.toString()) };
        } else if (dataType === ColumnTypeEnum.currency) {
            return {
                label: actualDropdownValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                value: isNaN(+actualDropdownValue) ? '' : +actualDropdownValue
            };
        } else if (dataType === ColumnTypeEnum.tag) {
            return {
                label: DashboardHelper.getTagOptionFromValue((isNaN(+actualDropdownValue) ? undefined : +actualDropdownValue))?.label,
                value: +actualDropdownValue
            };
        } else {
            return { label: actualDropdownValue?.toString(), value: actualDropdownValue };
        }
    }

    public static getDashboardOpenPOStatus() {
        return DashboardHelper.poStatus.filter(x => x !== DashboardHelper.closedStatusText);
    }

    public static getDashboardClosedPOstatus() {
        return [DashboardHelper.closedStatusText];
    }

    public static setPOstatusFilter(event: TabViewTabChangeEvent, poStatusFilterArray: string[]) {
        const statusString = 'poStatus';
        const filters: Filters[] = [];
        if (event.index === 0) {
            filters.push({
                columnName: statusString,
                values: poStatusFilterArray?.length > 0 ? poStatusFilterArray : DashboardHelper.getDashboardOpenPOStatus()
            });
        } else if (event.index === 1) {
            filters.push({
                columnName: statusString,
                values: DashboardHelper.getDashboardClosedPOstatus()
            });
        }
        return filters;
    }

    public static setFilterByNameAndValue(columnName: string, columnValue: string) {
        const filters: Filters[] = [];
        filters.push({
            columnName: columnName,
            values: [columnValue]
        });
        return filters;
    }

    static getStatus(data: any) {
        switch (data) {
            case 'TR Sent':
                return 'warning';
            case 'Shipped':
                return 'info';
            case 'Delivered':
                return 'success';
            default:
                return null;
        }
    }

    static getTagDropdownOptions(): TagOption[] {
        return DashboardHelper.tagOptions;
    }

    static getCountryDropdownOptions(): { value: string; name: string }[] {
        return countriesList.sort((a, b) => StringHelper.compare(a.name, b.name)).map((country) => {
            return {
                name: country.name,
                value: country.name
            };
        });
    }

    static getStatesByCountry(country: string | undefined): any[] {
        let states = countriesList.find((a) => a.name === country)?.states;
        states = states !== undefined ? states : [];
        return states.sort((a: any, b: any) => StringHelper.compare(a.name, b.name)).map((state: any) => {
            return {
                value: state.name,
                name: state.name
            };
        });
    }

    static getDropdownOptionsByColumn(column: HeadCell<any>, parentValue: string) {
        let options: any[] = [];
        switch (column.name) {
            case 'country':
            case 'decalCountry':
                options = DashboardHelper.getCountryDropdownOptions();
                break;
            case 'state':
            case 'decalState':
                options = DashboardHelper.getStatesByCountry(parentValue);
                break;
            case 'fromScreen':
                options = DashboardHelper.getFromScreenOption();
                break;
            case 'emailSender':
                options = DashboardHelper.getSenderEmailOption();
                break;
            default:
                break;
        }
        return options;
    }

    static getTagOptionFromValue(value?: number) {
        return DashboardHelper.tagOptions.find(x => x.value === value);
    }

    static getDimensionLabel() {
        return ['Length', 'Width', 'Height'];
    }

    static getMinRecordCount() {
        return DashboardHelper.minRecordCount;
    }

    static getEditColumnSuffix() {
        return '_e';
    }


    static getFromScreenOption() {
        return [{ name: 'PO Tile', value: 'PO Tile' },
        { name: 'Transportation Team View', value: 'Transportation Team View' }];
    }

    static getSenderEmailOption() {
        return [{ name: 'gsetracker@unifiservice.com', value: 'gsetracker@unifiservice.com' },
        { name: 'transportation@unifiservice.com', value: 'transportation@unifiservice.com' }];
    }
}

export default DashboardHelper;
