import React, { Component, Dispatch, RefObject } from 'react';
import './VendorSignIn.scss';
import { Button, Form } from 'react-bootstrap';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { ExternalUserActions } from '../../store/externalUser';
import { Navigate } from 'react-router';
import { UserHelper } from '../../helpers';
import { Toast } from 'primereact/toast';

interface ComponentProps {
    applicationState?: ApplicationState;
    signIn?: (request: any) => any;
}

type SignInPageProps =
    ComponentProps
   & typeof mapDispatchToProps;

interface IState {
    emailId: string,
    pwd: string,  
    shouldShowToast: boolean, 
}

class VendorSignIn extends Component<SignInPageProps, IState>  {
    responseData: any; 
    toast: RefObject<Toast> | undefined = undefined;
    
    constructor(props: Readonly<SignInPageProps>){
        super(props);
        this.toast = React.createRef();
        this.state = {
            emailId: '',
            pwd: '',
            shouldShowToast: false
        }; 
        this.vendorLoginAuth = this.vendorLoginAuth.bind(this);
    }

    vendorLoginAuth(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        var userData = {
            emailAddress: this.state.emailId,
            password: this.state.pwd
        };
        if (this.props.signIn) {
            this.props.signIn(userData).then((response: any) => {
                if (response && response.httpStatusCode !== 200) {                                                           
                         this.showStickyToast('error', response.statusDesc, true);
                }
            });
        }
    }

    showStickyToast(severity: 'info' | 'success' | 'warn' | 'error' | undefined, message: string, isResponseMessage: boolean = false) {
        setTimeout(() => {
            this.toast?.current?.show({ severity: severity, summary: 'Notification Process', detail: message, sticky: false });
            if (isResponseMessage) {
                this.setState({ shouldShowToast: false });
            }
        }, 20);
    }

    render() {   
        const { applicationState } = this.props;
        if(applicationState && UserHelper.isExternalUserValid(applicationState)) {
            const routePath = UserHelper.getLandingPageRoute(applicationState);
            return <Navigate to={routePath} />;
        }
        return (
            <React.Fragment>
                <Toast ref={this.toast} />
                <Form className="rounded p-5 p-sm-4" onSubmit={($event) => this.vendorLoginAuth($event)}>
                    <div className="Login-header">Log In</div>
                    <Form.Group className="mb-2 alignLeft">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" defaultValue={this.state.emailId}
                            onChange={e => this.setState({ emailId: e.target.value })}
                            className="border-0 border-bottom border-dark rounded-0 txt"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-2 alignLeft">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" defaultValue={this.state.pwd}
                            onChange={e => this.setState({ pwd: e.target.value })}
                            className="border-0 border-bottom border-dark rounded-0 txt"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-2 alignLeft">
                        <a href="/user/resetPasswordEmail">Forget you password?</a><br />
                        <a href="/user/resetPasswordEmail">Reset password.</a>
                    </Form.Group>
                    <Button type="submit" className="vendor-sign-in">Sign In
                    </Button>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
    return {
        applicationState: state
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
    signIn: (request: any) => dispatch(ExternalUserActions.signIn(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorSignIn as any); 