const routePaths = {
    root: '/',
    signIn: '/signin',
    signInCallback: '/signin/complete',
    procurementDashboard: '/procurementDashboard',
    VendorSignIn: '/vendor-sign-in',
    ResetPassword: '/user/activate',
    ChangePassword: '/user/resetPassword',
    resetPwdPassword: '/user/resetPasswordEmail',
    transportationVendorDashboard: '/transportation-vendor-dashboard',
    manufacturingVendorDashboard: '/manufacturing-vendor-dashboard',
    internalTransportationDashboard: '/transport-dashboard',
    dashboard: '/Dashboard',
    unAuthorized: '/unAuthorized',
    admin: '/admin',
    adminVendor: '/admin-vendor',
    adminStationContact: '/admin-station',
    adminVendorUser: '/admin-vendor-user',
    adminTemplate: '/adminTemplate',
    adminDecal: '/adminDecal',
};

export default routePaths;