import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import './procurementPopup.scss';
import { DataTableValue } from 'primereact/datatable';
import { DashboardState, DropdownOptions } from '../../../store/dashboard';
import { DateHelper } from '../../../helpers';
import { classNames } from 'primereact/utils';
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { VirtualScrollerTemplateOptions } from 'primereact/virtualscroller';
import DashboardHelper from '../../../helpers/DashboardHelper';
import { ColumnTypeEnum } from '../../../shared/models/ColumnTypeEnum';
import { Skeleton } from 'primereact/skeleton';
import { Dropdown } from 'primereact/dropdown';
import { TagOption } from '../../../shared/models/TagOptions';

interface Props {
    isVisible: boolean;
    data: DataTableValue;
    dashboardPO?: DashboardState;
    updateProcurementPO?: any;
    editResponse: any;
    dropdownOptions?: DropdownOptions;
    getDropdownDataByColumnName?: (columnName: string) => void;
}

export default function ProcurementEditPopup({ isVisible, data, updateProcurementPO, editResponse, dropdownOptions, getDropdownDataByColumnName }: Props) {


    useEffect(() => {
        setAsset(data.assetNumber);
        setdestContract(data.destinationContract);
        setInvNo(data.manufacturerInvoiceNumber);
        setbuild(data.build);
        setReadyDate(data.newReadyDate);
        setTRDate(data.trSent);
        setTransporter(data.transporter);
        setComment(data.procurementTeamComments);
        setIsVisible(isVisible);
        setTagId(data.tagId);

    }, [data.assetNumber, data.destinationContract, data.manufacturerInvoiceNumber, data.build,
    data.newReadyDate, data.trSent, data.transporter, data.procurementTeamComments, isVisible, data.tagId]);


    const onClose = (event: any) => {
        event.preventDefault();
        setIsVisible(false);
    };


    const [asset, setAsset] = useState<string>(data.assetNumber);
    const [destContract, setdestContract] = useState<string>(data.destinationContract);
    const [invNo, setInvNo] = useState<string>(data.manufacturerInvoiceNumber);
    const [build, setbuild] = useState<string>(data.build);
    const [readyDate, setReadyDate] = useState(data.newReadyDate);
    const [trDate, setTRDate] = useState(data.trSent);
    const [transporter, setTransporter] = useState<string>();
    const [procurementTeamComments, setComment] = useState<string>(data.procurementTeamComments);
    const [isVisibleDialog, setIsVisible] = useState<boolean>(isVisible);
    const [tagId, setTagId] = useState(data.tagId);

    const [filteredItems, setfilteredItems] = useState<any[]>([]);

    const updatePO = (event: any) => {
        event.preventDefault();
        const editData = {
            'id': data.polId,
            'tagId': tagId == null ? 0 : tagId,
            'assetNumber': asset?.trim(),
            'destinationContract': destContract?.trim(),
            'manufacturerInvoiceNumber': invNo?.trim(),
            'build': build?.trim(),
            'newReadyDate': readyDate ? DateHelper.formatDate(new Date(readyDate)) : null,
            'transporter': transporter?.trim(),
            'trSent': trDate ? DateHelper.formatDate(new Date(trDate)) : null,
            'procurementTeamComments': procurementTeamComments?.trim()
        };
        updateProcurementPO(editData);
        setIsVisible(false);
    };

    const filterOnshow = (columnName: string) => {
        if (getDropdownDataByColumnName && !dropdownOptions?.isFetching && !(dropdownOptions?.columnOptions
            .some(x => x.columnName === columnName + DashboardHelper.getEditColumnSuffix() && x.options?.length > 0))) {
                getDropdownDataByColumnName(columnName);
        }
    };

    const searchItems = (event: AutoCompleteCompleteEvent, columnName: string) => {
        const items = DashboardHelper.getDropDownOptions(columnName + DashboardHelper.getEditColumnSuffix(), dropdownOptions, ColumnTypeEnum.dropdowns);

        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            if (item?.label?.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                _filteredItems.push(item);
            }
        }
        setfilteredItems(_filteredItems);
    };

    const loadingTemplate = (options: VirtualScrollerTemplateOptions) => {
        const className = classNames('flex align-items-center p-2', {
            odd: options.odd
        });

        return (
            <div className={className} style={{ height: '50px' }}>
                <Skeleton width={options.even ? '60%' : '50%'} height="1.3rem" />
            </div>
        );
    };

    const autoCompleteFieldChangeEvent = (event: AutoCompleteChangeEvent): void => {
        setTransporter(event.value.value);
    };

    const tagOptionTemplate = (option: TagOption) => {
        return (
            <div className="flex align-items-center">
                <div style={{ backgroundColor: option.color, width: '15px' }}>&nbsp;&nbsp;&nbsp;</div>
                <div className='pl-2'>{option.label}</div>
            </div>
        );
    };

    const selectedTagTemplate = (option: TagOption) => {
        if (option) {
            return tagOptionTemplate(option);
        }
        return <span></span>;
    };

    return (

        <Dialog className='dashboard-edit-dialog edit-dialog backgroundPanel rounded ml-0' header='Procurement Edit' visible={isVisibleDialog} onHide={() => setIsVisible(false)}>
            <form className=''>
                <label className='grplabel'>PO Data</label>
                <div className='grpdiv flex flex-column gap-2 '>
                    <label className='label'>TAG</label>
                    <Dropdown value={tagId ?? ''} onChange={(e) => { setTagId(e.value); }}
                        options={DashboardHelper.getTagDropdownOptions()}
                        optionLabel="label"
                        placeholder="Select a Tag"
                        valueTemplate={selectedTagTemplate} itemTemplate={tagOptionTemplate} />

                    <label className='label'>VENDER</label>
                    <InputText id='txtVender' value={data.vendorName ?? ''} disabled />
                    <label className='label'>PO #</label>
                    <InputText id='txtPOnumber' value={data.poNumber ?? ''} disabled />
                    <label className='label'>ASSET #</label>
                    <InputText id='txtAssetNumber' value={asset ?? ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAsset(e.target.value)} />
                    <label className='label'>PO CONTRACT</label>
                    <InputText id='txtPOContact' value={data.poContract ?? ''} disabled />
                    <label className='label'>Dest. Contract</label>
                    <InputText id='txtDestContract' value={destContract ?? ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdestContract(e.target.value)} />
                    <label className='label'>PO STATUS</label>
                    <InputText id='txtPOStatus' value={data.poStatus ?? ''} disabled />
                    <label className='label'>PO DATE (Original delivery Date)</label>
                    <Calendar id='txtPODate' value={data.poDate ?? ''} disabled />
                    <label className='label'>REQUISITIONER</label>
                    <InputText id='txtRequisitioner' value={data.requisitioner ?? ''} disabled />
                    <label className='label'>QUOTE #</label>
                    <InputText id='txtQuoteNo' value={data.quote ?? ''} disabled />
                    <label className='label'>TRANSPORTER</label>

                    <AutoComplete key={'transporter'} value={transporter ?? ''} dropdown
                        suggestions={filteredItems}
                        showEmptyMessage={true}
                        field="label"
                        emptyMessage={dropdownOptions?.isFetching ? 'Fetching...'
                            : (filteredItems !== undefined && filteredItems?.length > 0
                                ? `${filteredItems?.length} values found` : 'No values found')}
                        onClick={() => filterOnshow('transporter')}
                        onDropdownClick={() => filterOnshow('transporter')}
                        dropdownAutoFocus={true}
                        dropdownMode={'current'}
                        virtualScrollerOptions={{
                            itemSize: 40, loadingTemplate: loadingTemplate, showLoader: true, lazy: true, step: 1,
                            loading: dropdownOptions?.isFetching, onLazyLoad: () => filterOnshow('transporter')
                        }}
                        completeMethod={(event: AutoCompleteCompleteEvent) => searchItems(event, 'transporter')}
                        onChange={(e: AutoCompleteChangeEvent) => autoCompleteFieldChangeEvent(e)}
                        placeholder={'Select ...'} className="p-column-filter p-0" />
                    <label className='label'>TR SENT</label>
                    <Calendar id='TrSent' value={trDate ?? ''} onChange={(e: CalendarChangeEvent) => setTRDate(e.value)} showIcon />
                    <label className='label'>New Ready Date</label>
                    <Calendar id='ReadyDate' value={readyDate ?? ''} onChange={(e: CalendarChangeEvent) => setReadyDate(e.value)} showIcon />
                    <label className='label'>GAP</label>
                    <InputText id='txtGAP' value={data.gap ?? ''} disabled />
                    <label className='label'>Comments By Procurement Team</label>
                    <InputTextarea id='txtComment' autoResize value={procurementTeamComments ?? ''} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)} rows={2} cols={30} />
                </div>
                <hr />
                <label className='grplabel'>ITEM</label>
                <div className='grpdiv flex flex-column gap-2 '>
                    <label className='label'>ITEM</label>
                    <InputText id='txtItem' value={data.items ?? ''} disabled />
                    <label className='label'>QTY</label>
                    <InputText id='txtQTY' value={data.qty ?? ''} disabled />
                    <label className='label'>COST</label>
                    <InputText id='txtCost' value={data.equipmentCost ?? ''} disabled />
                    <label className='label'>IREC</label>
                    <InputText id='txtIREC' value={data.irec ?? ''} disabled />
                    <label className='label'>REC'D</label>
                    <InputText id='txtREC' value={data.isReceived ?? ''} disabled />
                </div><hr />

                <label className='grplabel'>INVOICE</label>
                <div className='grpdiv flex flex-column gap-2 '>
                    <label className='label'>MANUFACTURING INVOICE #</label>
                    <InputText id='txtManufactutingInvoice' value={invNo ?? ''} disabled />
                    <label className='label'>MANUFACTURING INVOICE STATUS</label>
                    <InputText id='txtInvStatus' value={data.manufacturerInvoiceStatus ?? ''} disabled />
                    <label className='label'>BUILD</label>
                    <InputText id='txtBuild' value={build ?? ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setbuild(e.target.value)} />
                </div>
                <div className='text-center mt-2'>
                    <Button label='Save' type='submit' onClick={updatePO} loading={editResponse?.editLoading} />
                    <Button label='Cancel' className='ml-2' onClick={onClose} />
                </div>
            </form>
        </Dialog>
    );
}
