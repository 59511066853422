import { AdminVendorViewModel } from '../shared/models/AdminVendorViewModel';
import { ColumnTypeEnum } from '../shared/models/ColumnTypeEnum';
import { AdminOperationsEnum } from '../shared/models/Enums/AdminOperationsEnum';
import { HeadCell } from '../shared/models/HeadCell';

class AdminVendorColumnHelper {
    public static getColumns(operation: AdminOperationsEnum): HeadCell<AdminVendorViewModel>[] {
        switch (operation) {
            case AdminOperationsEnum.viewAll:
                return [
                    { name: 'edit', header: 'Edit', isSortable: false, filter: false, dataType: ColumnTypeEnum.edit },
                    { name: 'delete', header: 'Delete', isSortable: false, filter: false, dataType: ColumnTypeEnum.delete },
                    { name: 'vendorName', header: 'Vendor Name' },
                    { name: 'vendorEmailId', header: 'Vendor EmailId' },
                    { name: 'mailingAddress', header: 'Mailing Address', isSortable: false, filter: false },
                    { name: 'vendorTypeName', header: 'Vendor Type' },
                    { name: 'isActive', header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },
                    { name: 'view', dataType: ColumnTypeEnum.view }
                ];
            case AdminOperationsEnum.view:
            case AdminOperationsEnum.add:
            case AdminOperationsEnum.edit:
                return [
                    { name: 'vendorTypeName', header: 'Title', dataType: ColumnTypeEnum.dynamicDropdown, isEditable: true, isRequired: true },
                    { name: 'vendorName', header: 'Vendor Name', isEditable: true, isRequired: true, dataType: ColumnTypeEnum.text },
                    { name: 'office', header: 'Office', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'website', header: 'Website', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'vendorEmailId', header: 'Vendor EmailId', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true },
                    { name: 'address1', header: 'Address Line 1', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true, },
                    { name: 'address2', header: 'Address Line 2(optional)', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'country', header: 'Country', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.staticDropdown },
                    { name: 'state', header: 'State', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.staticDropdown, parentColumnName: 'country' },
                    { name: 'city', header: 'City', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.text },
                    { name: 'zipCode', header: 'Zip/Postal Code', isEditable: true, isPresentInMultipleColumn: true, isRequired: true, dataType: ColumnTypeEnum.text },
                    { name: 'fax', header: 'Fax', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'comments', header: 'Comments(if any)', isEditable: true, dataType: ColumnTypeEnum.textArea },
                    { name: 'isActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },
                ];
            default:
                return [];
        }
    }
}

export default AdminVendorColumnHelper;

