const GSEDisplayTextConst = {
    landingPage: {
        welcomeText: 'Welcome to GSE Tracker App',
        chooseTeamText: 'Click team button option to select your team',
        internalUserLoginText: 'Internal User Login',
        vendorLoginText: 'Vendor Login'

    },
    dashboardLayoutId:{
        procurementId: 'procurementDashboard',
        transportId: 'transportationDashboard',
        manufacturingVendorId: 'manufacturingVendor',
        transportationVendorId: 'transportationVendor',
        dashboardId: 'dashboard',
        adminId: 'admin'
    },
    accessPopup: {
        goToHomeText: 'Back To Home'
    }
};

export default GSEDisplayTextConst;