
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Button } from 'primereact/button';
import './pieChart.scss';

interface Props {
    getPieChart?: (fromStatus: string) => void;
    labels?: any;
    pieChartData?: any;
    statusText?: string;
}

export default function PieChart({ getPieChart, labels, pieChartData, statusText }: Props) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});    

    useEffect(() => {
        onStatusBtnClick('POStatus');
    }, []);

    useEffect(() => {

        const data = {
            labels: labels,
            datasets: [
                {
                    data: pieChartData,
                    backgroundColor: [
                        'rgb(93, 173, 226)',
                        'rgb(0, 0, 255)',
                        'rgb(142, 68, 173)',
                        'rgb(28, 40, 51)',
                        'rgb(128, 139, 150)',
                        'rgb(202, 111, 30)',
                        'rgb(0, 0, 128)',
                        'rgb(0, 153, 153)'
                    ],

                }
            ]
        };
        const options = {
            cutout: '60%',
            layout: {
                padding: {
                    top: 0,
                    bottom: 0,
                },
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'right',
                    labels: {
                        generateLabels: (chart: any) => {
                            const datasets = chart.data.datasets;
                            return datasets[0].data.map((data: any, i: number) => ({
                                text: `${chart.data.labels[i]} - ${data}`,
                                fillStyle: datasets[0].backgroundColor[i],
                                fontSize: window.innerWidth > 350 ? 20 : 10,
                                index: i,
                            }));
                        }
                    },
                }
            }
        };

        setChartData(data);
        setChartOptions(options);

    }, [pieChartData, labels]);

    const onStatusBtnClick = (status: string) => {
        if (getPieChart)
            getPieChart(status);       
    };   

   
    return (

        <div className='p-4'>
            <Button type='button' label='PO Status' className='w-2 mr-2' onClick={() => onStatusBtnClick('POStatus')} />
            <Button type='button' label='Shipping Status' className='w-2 mr-2' onClick={() => onStatusBtnClick('ShippingStatus')} />
            <Button type='button' label='Unit Status' className='w-2' onClick={() => onStatusBtnClick('UnitStatus')} />
            <div id='pieChart' className='card flex justify-content-center p-0 border-0'>             
               <h4 className='mt-5 mb-0'>{statusText}:</h4>
                <Chart type='doughnut' data={chartData} options={chartOptions} className='chart-css' />
            </div>
        </div>
    );
}