import { UserTypeEnum } from '../../shared/models/UserTypeEnum';

/* eslint-disable no-unused-vars */
export enum FlagsActionTypes {
    UPDATE_FLAG = '@@flags/UPDATE_FLAG',
    FLAGS_READY = '@@flags/FLAGS_READY',
    ISDASHBOARDLAYOUT_SET = '@@FLAGS/ISDASHBOARDLAYOUT_SET',
    ISDASHBOARDLAYOUT_RESET = '@@FLAGS/ISDASHBOARDLAYOUT_RESET',
}

export interface FlagsState {
    flagsReady: boolean;
    isDashboardLayout: boolean;
    userType: UserTypeEnum;
}
