/* eslint-disable no-prototype-builtins */
import { IdToken } from 'msal/lib-commonjs/IdToken';
import { StringDict } from 'msal/lib-commonjs/MsalTypes';

class TokenHelper {
    public static isAuthenticated = (idToken: IdToken | StringDict | any): boolean => {
        if (idToken === undefined || idToken === null) {
            return false;
        } else {
            let expiresAt;
            if (idToken instanceof IdToken) {
                expiresAt = Date.parse(idToken.expiration);
            } else if (idToken.hasOwnProperty('exp')) {
                expiresAt = idToken.exp * 1000;
            } else if (idToken.hasOwnProperty('expiration')) {
                expiresAt = idToken.expiration * 1000;
            } else {
                expiresAt = 0;
            }
            const now = Date.now();
            return now < expiresAt;
        }
    };

    public static getExpires = (idToken: IdToken | StringDict | any): number => {
        let expires: number;
        if (idToken instanceof IdToken) {
            expires = Date.parse(idToken.expiration);
        } else if (idToken.hasOwnProperty('exp')) {
            expires = idToken.exp  * 1000;
        } else {
            expires = 0;
        }

        return expires;
    };
}

export default TokenHelper;
