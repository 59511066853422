import { DataTableValue } from 'primereact/datatable';
import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { ColumnTypeEnum } from '../../models/ColumnTypeEnum';
import { HeadCell } from '../../models/HeadCell';
import './dashboardEdit.scss';
import { DropdownOptions } from '../../../store/dashboard';
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { Skeleton } from 'primereact/skeleton';
import { VirtualScrollerTemplateOptions } from 'primereact/virtualscroller';
import DashboardHelper from '../../../helpers/DashboardHelper';
import { FileUpload, FileUploadHandlerEvent, FileUploadHeaderTemplateOptions } from 'primereact/fileupload';
import { PrimeIcons } from 'primereact/api';
import UploadDownloadHelper from '../../../helpers/UploadDownloadHelper';
import { UploadFileColumn, uploadFile } from '../../models/UploadFile';
import { Dropdown, DropdownChangeEvent, DropdownFilterEvent } from 'primereact/dropdown';
import { TagOption } from '../../models/TagOptions';
import { TagTypeEnum } from '../../models/Enums/TagTypeEnum';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { AdminOperationsEnum } from '../../models/Enums/AdminOperationsEnum';

interface ComponentProps {
    dashboardEditData: DataTableValue | undefined;
    columns: HeadCell<any>[];
    DashboardEditLoading: boolean;
    dashboardEditHeader?: string;
    dropdownOptions?: DropdownOptions;
    uploadFiles?: UploadFileColumn[];
    isReadOnly?: boolean;
    processEvent?: AdminOperationsEnum;
    getSpecificFieldData?: (columnName: string) => void;
    onClose?: () => void;
    onSubmit?: (dashboardEditData: DataTableValue | undefined) => void;
}

type DashboardEditProps =
    ComponentProps;

interface ComponentState {
    dashboardEditData: DataTableValue | undefined,
    DashboardEditLoading: boolean,
    filteredItems: any;
    errors?: Record<string, string>;
}

export class DashboardEdit extends Component<DashboardEditProps, ComponentState> {
    state = {
        dashboardEditData: undefined,
        DashboardEditLoading: false,
        filteredItems: [],
        errors: undefined
    };

    constructor(props: DashboardEditProps) {
        super(props);
        this.updateDashboardEdit = this.updateDashboardEdit.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.setChangedValueInState = this.setChangedValueInState.bind(this);
        this.invoiceUploadHandler = this.invoiceUploadHandler.bind(this);
        this.deleteEntryItem = this.deleteEntryItem.bind(this);
        this.onAddEntryItem = this.onAddEntryItem.bind(this);
    }

    componentDidMount(): void {
        const dashboardEditData: any = this.props.dashboardEditData;
        const dimensionLabelArray = DashboardHelper.getDimensionLabel();
        if (this.props.dashboardEditData && this.props.dashboardEditData['dimensions']) {
            const dimensionArray = this.props.dashboardEditData['dimensions']?.split('*') ?? [];
            if (dimensionArray.length === dimensionLabelArray.length) {
                dimensionLabelArray.forEach((dim, index) => {
                    const dimValue = dimensionArray[index] ? (isNaN(+dimensionArray[index]?.trim()) ? undefined
                        : +dimensionArray[index].trim()) : undefined;
                    dashboardEditData[dim.toLowerCase()] = dimValue;
                });
            }
        } else {
            dimensionLabelArray.forEach((dim) => {
                dashboardEditData[dim.toLowerCase()] = undefined;
            });
        }
        this.setState({ dashboardEditData });
    }

    componentDidUpdate(prevProps: Readonly<ComponentProps>): void {
        if (this.props.dropdownOptions !== prevProps.dropdownOptions) {
            const { dropdownOptions, columns } = this.props;
            const filteredItems: any = this.state.filteredItems;
            dropdownOptions?.columnOptions.forEach(column => {
                const currentColumn = columns.find(x => x.name + DashboardHelper.getEditColumnSuffix() === column.columnName);
                const _filteredItems = DashboardHelper.getDropDownOptions(column.columnName, dropdownOptions, currentColumn?.dataType);
                if (currentColumn) {
                    filteredItems[currentColumn?.name] = _filteredItems;
                }
            });
            this.setState({ filteredItems });
        }
    }

    updateDashboardEdit(event: any) {
        event.preventDefault();
        this.validateForm();
    }

    onClose(event: any) {
        event.preventDefault();
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    loadingTemplate(options: VirtualScrollerTemplateOptions) {
        const className = classNames('flex align-items-center p-2', {
            odd: options.odd
        });

        return (
            <div className={className} style={{ height: '50px' }}>
                <Skeleton width={options.even ? '60%' : '50%'} height="1.3rem" />
            </div>
        );
    }

    filterOnshow(column: HeadCell<any>) {
        const { getSpecificFieldData, dropdownOptions } = this.props;
        if (getSpecificFieldData && !dropdownOptions?.isFetching && !(dropdownOptions?.columnOptions
            .some(x => x.columnName === column.name + DashboardHelper.getEditColumnSuffix() && x.options?.length > 0))) {
            getSpecificFieldData(column.name);
        }
    }

    searchItems(query: string, column: HeadCell<any>) {
        const { dropdownOptions } = this.props;
        const filteredItems: any = this.state.filteredItems;
        const columnName = column.name + DashboardHelper.getEditColumnSuffix();
        const items = DashboardHelper.getDropDownOptions(columnName, dropdownOptions, column.dataType);

        filteredItems[column.name] = this.getFilterItems(query, [...items]);

        if (filteredItems[column.name].length === 0 && column.dataType === ColumnTypeEnum.dynamicDropdown) {
            filteredItems[column.name] = [{ label: 'No values found', value: undefined }];
        }
        this.setState({ filteredItems });
    }

    private getFilterItems(query: string, items: any[]) {
        return items.filter((option) =>
            option.label.toLowerCase().includes(query.toLowerCase())
        );
    }

    getFormErrorMessage(columnName: string) {
        const { errors } = this.state;
        return errors && errors[columnName] ? <small className="p-error">{errors[columnName]}</small>
            : <small className="p-error">&nbsp;</small>;
    }

    isNullOrEmpty(input?: string) {
        const trimmed = input?.trim();
        if (trimmed === undefined || trimmed === null || trimmed.length === 0) {
            return true;
        }

        return false;
    }

    private validateFormControl(value: any, errors: any, column: HeadCell<any> | undefined) {
        let regex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
        let regexMobile = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        let regexZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

        if (!errors) {
            errors = {};
        }

        if (column?.name) {
            if (column?.isRequired && this.isNullOrEmpty(value)) {
                errors[column?.name] = `${column.header} is required`;
            }
            else if (column?.isValidEmail) {
                if (!regex.test(value)) {
                    errors[column?.name] = 'Invalid email address';
                }
                else {
                    delete errors[column?.name];
                }
            }
            else if (column?.isValidMobile) {
                if (!regexMobile.test(value)) {
                    errors[column?.name] = 'Invalid number';
                }
                else {
                    delete errors[column?.name];
                }
            }
            else if (column?.isValidZip) {
                if (!regexZip.test(value)) {
                    errors[column?.name] = 'Invalid zip code';
                }
                else {
                    delete errors[column?.name];
                }
            }
            else {
                delete errors[column?.name];
            }
        }
        return errors;
    }

    private validateForm() {
        let errors: any = this.state.errors ?? {};
        let dashboardEditData = this.state.dashboardEditData;

        const { columns } = this.props;
        columns.forEach(col => {
            if (col?.isRequired || col?.isValidEmail || col?.isValidMobile) {
                errors = this.validateFormControl((dashboardEditData && dashboardEditData[col.name]) ?? '', errors, col);
            }
            if (col?.childColumns) {
                let parentCol = col;
                const data = (dashboardEditData && dashboardEditData[parentCol.name]) ?? [];
                col?.childColumns.forEach(childCol => {
                    if (childCol?.isRequired || childCol?.isValidEmail || childCol?.isValidMobile) {
                        data.forEach(col1 => {
                            errors = this.validateFormControl(col1[childCol.name], errors, childCol);
                        });
                    }
                });
            }
        });
        this.setState({ errors });

        setTimeout(() => {
            if (this.props.onSubmit && (!errors || Object.keys(errors).length === 0)) {
                this.props.onSubmit(this.state.dashboardEditData);
            }
        }, 200);
    }

    renderControlFieldAndLabel(column: HeadCell<any>) {
        const { dashboardEditData, errors } = this.state;
        const isEditable = !this.props.isReadOnly && column.isEditable;
        return (
            <React.Fragment key={column.name}>
                <div key={column.name + 'div-section'} className={column.isPresentInMultipleColumn ? 'py-0 col-6' : 'py-1 col-12'}>
                    <label key={column.name + 'label'} className={classNames('label mb-1 ', { 'p-error': errors && errors[column.name] })}>{column.header}
                        {column.isRequired && <span>&nbsp;*</span>}                       
                    </label>
                    {this.renderControlByColumn(column, dashboardEditData, isEditable, errors)}
                    {(column.name === 'stationCode') && <span className='span-content'>*Max 10 charaters allowed.</span>}
                    {(column.name === 'emailCc') && <span className='span-content'>(Enter multiple email IDs separated by ",") </span>}
                    {(column.isRequired || column.isValidEmail || column.isValidMobile || column.isValidZip) && this.getFormErrorMessage(column.name)}
                </div>
            </React.Fragment>
        );
    }

    headerTemplate(options: FileUploadHeaderTemplateOptions, value: string) {
        const { chooseButton } = options;
        return (
            <div className="p-inputgroup">
                <InputText disabled className='file-upload-btn' value={value} placeholder="File name" />
                {chooseButton}
            </div>
        );
    }

    setChangedValueInState(value: any, columnName: string, parentColumnName?: string, parentIndex?: number) {
        let dashboardEditData: any = this.state.dashboardEditData;
        const { columns } = this.props;
        const currentColumn = columns.find(x => x.name === columnName);
        if (parentColumnName && parentIndex !== undefined) {
            const newValue = value?.value ? value.value : value;
            const childArray = dashboardEditData[parentColumnName];
            const currentChildObject = childArray[parentIndex];
            currentChildObject[columnName] = newValue;
            const parentColumn = columns.find(x => x.name === parentColumnName);
            const childColumn = parentColumn?.childColumns?.find(x => x.name === columnName);
            if (columnName === 'isMainContact') {
                childArray.forEach((element: { isMainContact: boolean; }, index: any) => {
                    if (index !== parentIndex) {
                        element.isMainContact = false;
                    }
                });
            }
            if (childColumn?.isValidMobile) {
                if (value.length > 0) {
                    const match = value.replace(/[^\d]/g, '')
                        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');                       
                    currentChildObject[columnName] = match;
                }
            }
            if (childColumn?.isRequired || childColumn?.isValidEmail || childColumn?.isValidMobile || childColumn?.isValidZip) {
                let errors: any = this.state.errors;
                errors = this.validateFormControl(value, errors, childColumn);
                this.setState({ errors });
            }
        } else {
            if (dashboardEditData) {
                dashboardEditData[columnName] = value?.value ? value.value : value;
            }
            if (currentColumn?.isRequired || currentColumn?.isValidEmail || currentColumn?.isValidMobile || currentColumn?.isValidZip) {
                let errors: any = this.state.errors;
                errors = this.validateFormControl(value, errors, currentColumn);
                this.setState({ errors });
            }
            if (currentColumn?.isValidMobile) {
                if (value.length > 0) {
                    const match = value.replace(/\D+/g, '')
                        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                    dashboardEditData[columnName] = match;
                }
            }                       
        }

        this.setState({ dashboardEditData });
    }

    invoiceUploadHandler(event: FileUploadHandlerEvent, columnName: string) {
        const { uploadFiles } = this.props;
        const valueColumn = UploadDownloadHelper.getFileDataColumnByDisplayColumn(columnName, uploadFiles ?? []);
        UploadDownloadHelper.specDocumentUploadHandler(event).then((formData: uploadFile) => {
            let dashboardEditData: any = this.state.dashboardEditData;
            if (dashboardEditData) {
                dashboardEditData[columnName] = formData.fileName;
                dashboardEditData[valueColumn] = formData.fileData;
            }
            this.setState({ dashboardEditData });
        });
    }

    private renderControlByColumn(column: HeadCell<any>, dashboardData: any, isEditable?: boolean, errors?: any, parentColumnName?: string, parentIndex?: number): React.ReactNode {
        const { dropdownOptions } = this.props;
        const filteredItems = this.state.filteredItems;
        if (!dashboardData) {
            return <React.Fragment></React.Fragment>;
        }

        const value = dashboardData[column.name] ?? '';

        switch (column.dataType) {
            case ColumnTypeEnum.date:
                return (
                    <Calendar
                        disabled={!isEditable}
                        key={column.name + 'calender'}
                        id={column.name}
                        value={value}
                        showIcon
                        className={classNames('col-12 p-0', { 'p-invalid': errors && errors[column.name] })}
                        onChange={(e: AutoCompleteChangeEvent) => this.setChangedValueInState(e.value, column.name, parentColumnName, parentIndex)}
                    />
                );
            case ColumnTypeEnum.file:
                return (
                    <FileUpload
                        disabled={!isEditable}
                        name="demo[]"
                        accept=".pdf"
                        maxFileSize={1000000}
                        chooseOptions={{ iconOnly: true, icon: PrimeIcons.UPLOAD }}
                        auto
                        uploadHandler={(event) => this.invoiceUploadHandler(event, column.name)}
                        customUpload={true}
                        headerTemplate={(event) => this.headerTemplate(event, value ?? '')}
                    />
                );
            case ColumnTypeEnum.number: {
                const defaultValue = value && value !== 0 ? value : undefined;
                return this.renderInputNumber(defaultValue, isEditable, column.name, parentColumnName, parentIndex);
            }
            case ColumnTypeEnum.dimensions:
                return this.renderDimensionsControls(column);
            case ColumnTypeEnum.tag:
                return (
                    <Dropdown
                        disabled={!isEditable}
                        value={value ? value : TagTypeEnum.noTag}
                        onChange={(e) => this.setChangedValueInState(e.value, column.name, parentColumnName, parentIndex)}
                        options={DashboardHelper.getTagDropdownOptions()}
                        optionLabel="label"
                        placeholder="Select a Tag"
                        className={classNames({ 'p-invalid': errors && errors[column.name] })}
                        valueTemplate={this.selectedTagTemplate}
                        itemTemplate={this.tagOptionTemplate}
                    />
                );
            case ColumnTypeEnum.staticDropdown: {
                const parentValue = column.parentColumnName ? dashboardData[column.parentColumnName] : '';
                return (
                    <Dropdown
                        disabled={!isEditable}
                        value={value}
                        onChange={(e) => this.setChangedValueInState(e.value, column.name, parentColumnName, parentIndex)}
                        filter
                        options={DashboardHelper.getDropdownOptionsByColumn(column, parentValue)}
                        optionLabel="name"
                        optionValue="value"
                        placeholder={`Select a ${column.header}`}
                        className={classNames({ 'p-invalid': errors && errors[column.name] })}
                    />
                );
            }
            case ColumnTypeEnum.dynamicDropdown: {
                return this.renderDynamicDropdown(column, value ?? '', filteredItems, isEditable, errors, parentColumnName, parentIndex, dropdownOptions);
            }
            case ColumnTypeEnum.inputSwitch: {
                const booleanValue = dashboardData[column.name] ?? false;
                return (
                    <InputSwitch
                        className={classNames('row ml-1', { 'p-invalid': errors && errors[column.name] })}
                        checked={booleanValue}
                        onChange={(e) => this.setChangedValueInState(e.value, column.name, parentColumnName, parentIndex)}
                        disabled={!isEditable}
                    />
                );
            }
            case ColumnTypeEnum.textArea:
                return (
                    <InputTextarea
                        key={column.name + 'control'}
                        id={column.name}
                        value={value ?? ''}
                        disabled={!isEditable}
                        className={classNames('col-12 p-0', { 'p-invalid': errors && errors[column.name] })}
                        onChange={(e: any) => this.setChangedValueInState(e.target.value, column.name, parentColumnName, parentIndex)}
                        rows={5}
                        cols={30}
                    />
                );
            case ColumnTypeEnum.text:
                return this.renderInputText(column, value, isEditable, errors, parentColumnName, parentIndex);
            case ColumnTypeEnum.additionalContact:
                return this.renderAdditionalContactArray(column, value ?? [], errors ?? {});
            default:
                if (isEditable) {
                    return this.renderEditableAutocomplete(column, value, filteredItems, dropdownOptions);
                } else {
                    return <InputText key={column.name + 'control'} id={column.name} value={value ?? ''} disabled={!isEditable} />;
                }
        }
    }

    renderDynamicDropdown(column: HeadCell<any>, value?: string, filteredItems?: any, isEditable?: boolean, errors?: any, parentColumnName?: string, parentIndex?: number, dropdownOptions?: DropdownOptions) {
        return <Dropdown key={column.name} value={value} filter filterBy='value'
            options={(filteredItems[column.name]?.length > 0) ? filteredItems[column.name] : [{ label: value, value: value }]}
            optionLabel="label"
            optionValue="value"
            emptyFilterMessage='No values found'
            virtualScrollerOptions={{
                itemSize: 43, loadingTemplate: this.loadingTemplate, showLoader: true, lazy: true,
                loading: dropdownOptions?.isFetching, onLazyLoad: () => this.filterOnshow(column)
            }}
            onChange={(e: DropdownChangeEvent) => this.setChangedValueInState(e.value, column.name, parentColumnName, parentIndex)}
            disabled={!isEditable}
            placeholder={`Select a ${column.header}`}
            className={classNames('col-12', { 'p-invalid': errors && errors[column.name] })}
            onFilter={(event: DropdownFilterEvent) => this.searchItems(event.filter, column)}
        />;
    }

    private renderAdditionalContactArray(column: HeadCell<any>, additionalContactArray: any[], errors: any): React.ReactNode {
        const isEditable = !this.props.isReadOnly && column.isEditable;
        if (!additionalContactArray || additionalContactArray.length === 0) {
            additionalContactArray = [];
        }
        return (
            <div className='row' key={column.name}>
                {additionalContactArray.map((_value: any, index: number) => {
                    return (
                        <div className='col-12 mb-2 ml-0 px-0 row user-entry' key={column.name + index + '-user-entry'}>
                            <div className='flex justify-content-between' key={column.name + index + '-content-div'}>
                                <label className='pl-0 label mb-1' key={column.name + index + '-label'}>
                                    {(column?.header ?? '') + ' ' + (index + 1)}
                                </label>
                                <span className="pi pi-times-circle mx-2 delete-entry" key={column.name + index + '-span'}
                                    onClick={() => this.deleteEntryItem(column.name, index)} title="Delete"></span>
                            </div>
                            {this.renderAdditionalContactEntry(column, index)}
                        </div>
                    );
                })}
                <Button link style={{ textDecoration: 'none' }} disabled={!isEditable}
                    onClick={(event: any) => this.onAddEntryItem(event, column, errors)}>
                    <div className='flex gap-1'>
                        <i className={'pi pi-plus'} style={{ fontSize: '1rem' }}></i>
                        <span>{`Add ${column.header}`}</span>
                    </div>
                </Button>
            </div>
        );
    }

    onAddEntryItem(event: any, column: HeadCell<any>, errors: any): void {
        event.preventDefault();

        const dashboardEditData: any = this.state.dashboardEditData;
        let additionalContactArray = dashboardEditData[column.name]?.length ? [...dashboardEditData[column.name]] : [];
        if (errors) {
            additionalContactArray.push({ ...column.childNewObject });
        }
        this.setChangedValueInState(additionalContactArray, column.name);
    }

    private deleteEntryItem(columnName: string, index: number) {
        const dashboardEditData: any = this.state.dashboardEditData;
        let additionalContactArray = dashboardEditData[columnName]?.length ? [...dashboardEditData[columnName]] : [];
        additionalContactArray.splice(index, 1);
        this.setChangedValueInState(additionalContactArray, columnName);
    }

    private renderAdditionalContactEntry(column: HeadCell<any>, parentIndex: number) {
        const columns: HeadCell<any>[] = column.childColumns ?? [];
        const { dashboardEditData, errors } = this.state;
        return (
            <React.Fragment key={'main-fragment-' + parentIndex}>
                {columns.map((childColumn: HeadCell<any>, index: number) => {
                    const isEditable = !this.props.isReadOnly && childColumn.isEditable;
                    return (
                        <div className='col-6' key={childColumn.name + index + parentIndex + '-div'}>
                            <label className='pl-0 label mb-1' key={childColumn.name + index + parentIndex + '-label'}>
                                {childColumn.header}
                                {childColumn.isRequired && <span>&nbsp;*</span>}
                            </label>
                            {this.renderControlByColumn(childColumn,
                                dashboardEditData?.[column.name]?.[parentIndex], isEditable, errors?.[column.name]?.[parentIndex], column.name, parentIndex)}
                            {(childColumn.isRequired || childColumn.isValidEmail || childColumn.isValidMobile || childColumn.isValidZip) && this.getFormErrorMessage(childColumn.name)}
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }

    private renderInputText(column: HeadCell<any>, value?: string, isEditable?: boolean, errors?: any, parentColumnName?: string, parentIndex?: number): React.ReactNode {
        if (column?.isValidZip) {
            return (
                <InputText
                    key={column.name + parentIndex + '-input'}
                    id={column.name}
                    value={value ?? ''}
                    disabled={!isEditable}
                    maxLength={5}
                    className={classNames({ 'p-invalid': errors && errors[column.name] })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setChangedValueInState(e.target.value, column.name, parentColumnName, parentIndex)}
                />
            );
        }
        else if(column?.isValidMobile){
            return (
                <InputText
                    key={column.name + parentIndex + '-input'}
                    id={column.name}
                    value={value ?? ''}
                    disabled={!isEditable}
                    maxLength={14}
                    className={classNames({ 'p-invalid': errors && errors[column.name] })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setChangedValueInState(e.target.value, column.name, parentColumnName, parentIndex)}
                />
            );
        }
        else if(column?.name === 'stationCode'){
            return (
                <InputText
                    key={column.name + parentIndex + '-input'}
                    id={column.name}
                    value={value ?? ''}
                    disabled={!isEditable}
                    maxLength={10}
                    className={classNames({ 'p-invalid': errors && errors[column.name] })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setChangedValueInState(e.target.value, column.name, parentColumnName, parentIndex)}
                />
            );
        }
        else {
            return (
                <InputText
                    key={column.name + parentIndex + '-input'}
                    id={column.name}
                    value={value ?? ''}
                    disabled={!isEditable}
                    className={classNames({ 'p-invalid': errors && errors[column.name] })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setChangedValueInState(e.target.value, column.name, parentColumnName, parentIndex)}
                />
            );
        }
    }

    private renderInputNumber(value: number | undefined, isEditable: boolean | undefined, columnName: string, parentColumnName?: string, parentIndex?: number): React.ReactNode {
        const { errors } = this.state;
        return <InputNumber value={value} disabled={!isEditable} key={columnName + 'input'}
            className={classNames('col-12 p-0', { 'p-invalid': errors && errors[columnName] })}
            onValueChange={(e) => this.setChangedValueInState(e.value, columnName, parentColumnName, parentIndex)}
            minFractionDigits={2} maxFractionDigits={2} />;
    }

    private renderDimensionsControls(column: HeadCell<any>): React.ReactNode {
        const dimensionsLegend = DashboardHelper.getDimensionLabel();
        const isEditable = this.props.isReadOnly ? false : column.isEditable;
        return dimensionsLegend.map((dim) => {
            const dashboardEditData: any = this.state.dashboardEditData;
            return (<React.Fragment key={dim + '-section'}>
                <label key={dim + '-label'} className='col-12 pl-0 label mb-1'>{dim}</label>
                {this.renderInputNumber(dashboardEditData[dim.toLowerCase()], isEditable, dim.toLowerCase())}
            </React.Fragment>);
        });
    }

    tagOptionTemplate(option: TagOption) {
        return (
            <div className="flex align-items-center">
                <div style={{ backgroundColor: option.color, width: '15px' }}>&nbsp;&nbsp;&nbsp;</div>
                <div className='pl-2'>{option.label}</div>
            </div>
        );
    }

    selectedTagTemplate(option: TagOption) {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div style={{ backgroundColor: option.color, width: '15px' }}>&nbsp;&nbsp;&nbsp;</div>
                    <div className='pl-2'>{option.label}</div>
                </div>
            );
        }
        return <span>&nbsp;</span>;
    }

    private renderEditableAutocomplete(column: HeadCell<any>, value: any, filteredItems: any, dropdownOptions: DropdownOptions | undefined): React.ReactNode {
        const { errors } = this.state;
        return <AutoComplete key={column.name} value={value} dropdown
            suggestions={filteredItems[column.name]}
            showEmptyMessage={true}
            field="label"
            className={classNames('p-column-filter p-0', { 'p-invalid': errors && errors[column.name] })}
            emptyMessage={dropdownOptions?.isFetching ? 'Fetching...'
                : (filteredItems[column.name]?.length > 0
                    ? `${filteredItems[column.name]?.length} values found` : 'No values found')}
            onClick={() => this.filterOnshow(column)}
            onDropdownClick={() => this.filterOnshow(column)}
            dropdownAutoFocus={true}
            dropdownMode={'current'}
            virtualScrollerOptions={{
                itemSize: 40, loadingTemplate: this.loadingTemplate, showLoader: true, lazy: true, step: 1,
                loading: dropdownOptions?.isFetching, onLazyLoad: () => this.filterOnshow(column)
            }}
            completeMethod={(event: AutoCompleteCompleteEvent) => this.searchItems(event.query, column)}
            onChange={(e: AutoCompleteChangeEvent) => this.setChangedValueInState(e.value, column.name)}
            placeholder={'Select ...'} />;
    }

    render() {
        const { columns, DashboardEditLoading, dashboardEditHeader, isReadOnly: isReadonly, processEvent } = this.props;
        return (
            <React.Fragment>
                {columns && <div className='dashboard-edit text-left'>
                    {dashboardEditHeader && <h6>{dashboardEditHeader}</h6>}
                    <form className='border-0 text-left'>
                        <div key='form-section' className='row'>
                            {columns.filter(column => column.dataType !== ColumnTypeEnum.edit
                                && column.dataType !== ColumnTypeEnum.delete && column.header)
                                .map(x => { return this.renderControlFieldAndLabel(x); })}
                        </div>
                        <div className='col-12 text-center mt-2 footer'>
                            {(!isReadonly && (!processEvent || processEvent !== AdminOperationsEnum.add)) && <Button label='Save' onClick={(event) => this.updateDashboardEdit(event)}
                                loading={DashboardEditLoading} />}
                            {(!isReadonly && processEvent && processEvent === AdminOperationsEnum.add) && <Button label='Add' onClick={(event) => this.updateDashboardEdit(event)}
                                loading={DashboardEditLoading} />}
                            <Button label='Cancel' onClick={(event) => this.onClose(event)} className='ml-2' />
                        </div>
                    </form>
                </div>}
            </React.Fragment>
        );
    }
}