import React, { Component, Dispatch, RefObject } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { FlagsActions } from '../../store/flags';
import { DashboardTable } from '../../shared/components/dynamic-data-table/dynamic-data-table';
import { HeadCell } from '../../shared/models/HeadCell';
import { DataTableFilterMeta, DataTableStateEvent, DataTableValue } from 'primereact/datatable';
import { TabView, TabPanel, TabViewTabChangeEvent } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { DashboardRequest, DashboardViewModel, Filters, PaginationInfo } from '../../shared/models/dashboard';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { ProcurementViewModel } from '../../shared/models/ProcurementViewModel';
import { DashboardActions, DashboardState } from '../../store/dashboard';
import DashboardHelper from '../../helpers/DashboardHelper';
import { ColumnTypeEnum } from '../../shared/models/ColumnTypeEnum';
import { Dialog } from 'primereact/dialog';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import UploadDownloadHelper from '../../helpers/UploadDownloadHelper';
import { Toast } from 'primereact/toast';
import { SplitButton } from 'primereact/splitbutton';
import { PrimeIcons } from 'primereact/api';
import { MenuItem } from 'primereact/menuitem';
import { Link } from 'react-router-dom';
import ProcurementEditPopup from './ProcurementEditPopup/procurementEditPopup';
import POContractTile from '../POContractTile/poContractTile';
import { AuthActions } from '../../store/auth';
import './procurementContainer.scss';
import { PdfViewer } from '../../shared/components/pdf-viewer/pdf-viewer';

interface ComponentProps {
  isAuthenticated?: boolean;
  dashboardPO?: DashboardState;
  filters?: DataTableFilterMeta;
  isDashboardLayoutEnable?: () => void;
  resetDashboardData?: () => void;
  getDashboardPO?: (request: DashboardRequest) => void;
  setFilterMetaData?: (dataTableFilterMeta: DataTableFilterMeta | undefined) => void;
  getSpecificFieldDataWithRequest?: (columnName: string, dataType: ColumnTypeEnum | undefined, filters?: Filters[] | undefined) => void;
  getDropdownDataByColumnName?: (columnName: string, dataType: ColumnTypeEnum | undefined) => void;
  updateProcurementPO?: (Request: any) => any;
  resetUploadApiMessage?: () => void;
  resetUpdateApiMessage?: () => void;
  resetDownloadDashboardData?: () => void;
  uploadProcurementFile?: (Request: any) => any;
  requestedPageReset?: () => void;
  getSpecDocumentData?: (polId: string, columnName: string) => any;
}
type ProcurementDashboardProps =
  ComponentProps
  & typeof mapDispatchToProps;

class ProcurementContainer extends Component<ProcurementDashboardProps> {
  minRecordCount: number = DashboardHelper.getMinRecordCount();
  toast: RefObject<Toast> | undefined = undefined;
  templateItems: MenuItem[] | undefined = undefined;
  statusFieldName = 'poStatus';

  state = {
    selectedTabIndex: 0,
    isUploadDialogVisible: false,
    shouldShowToast: false,
    isEditDialogVisible: false,
    rowData: [],
    shouldShowPOContractTile: false,
    selectedColumn: {
      columnName: '',
      columnValue: ''
    },
    showPdfViewerDialog: false,
    specFormData: '',
    pdfViewerFetching: false,
    specDocName: 'Specification Document'
  };

  constructor(props: ProcurementDashboardProps) {
    super(props);
    if (props.resetDashboardData) {
      props.resetDashboardData();
    }

    this.toast = React.createRef();

    if(props.requestedPageReset)
      props.requestedPageReset();

    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.onDataTableFilter = this.onDataTableFilter.bind(this);
    this.setFilterMetaData = this.setFilterMetaData.bind(this);
    this.getSpecificFieldData = this.getSpecificFieldData.bind(this);
    this.getDropdownDataByColumnName = this.getDropdownDataByColumnName.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onPOTabChange = this.onPOTabChange.bind(this);
    this.uploadClickEvent = this.uploadClickEvent.bind(this);
    this.invoiceUploadHandler = this.invoiceUploadHandler.bind(this);
    this.downloadClickEvent = this.downloadClickEvent.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.poLinkClickEvent = this.poLinkClickEvent.bind(this);
    this.updateProcurementPO = this.updateProcurementPO.bind(this);
    this.onPOContractTileHide = this.onPOContractTileHide.bind(this);
    this.getSpecFormData = this.getSpecFormData.bind(this);

    this.templateItems = [
      {
        label: 'Download Template',
        icon: PrimeIcons.DOWNLOAD,
        template: () => {
          return (
            <Link className='p-menuitem-link' to='/Template/PO_Logistics_Master.xlsx'
              target='_blank' download>
                 <i className={'pi pi-download mr-2'} style={{ fontSize: '1rem' }}></i>
                <span>Download Template</span>
            </Link>
          );
        },
        command: () => {
          this.toast?.current?.show({ severity: 'warn', summary: 'Download', detail: 'Upload template file downloaded' });
        }
      }
    ];
  }

  componentDidMount(): void {
    if(this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();
    
    if(this.props.resetUploadApiMessage)
      this.props.resetUploadApiMessage();

    if(this.props.isDashboardLayoutEnable) {
      this.props.isDashboardLayoutEnable();
    }
  }

  getDashboardPO() {
    const { dashboardPO } = this.props;
    const poStatusFilterArray = dashboardPO?.dashboardRequest?.filters?.find(x => x.columnName === this.statusFieldName)?.values ?? [];
    const poStatusFilter: Filters[] = DashboardHelper.setPOstatusFilter({ index: this.state.selectedTabIndex } as TabViewTabChangeEvent, poStatusFilterArray);
    if (dashboardPO?.dashboardRequest) {
        if(!dashboardPO?.dashboardRequest?.filters) {
          dashboardPO.dashboardRequest['filters'] = [];
        } else {
          if (dashboardPO.dashboardRequest.filters.some(x => x.columnName === this.statusFieldName)) {
            dashboardPO.dashboardRequest.filters = dashboardPO.dashboardRequest.filters.filter((x) => x.columnName !== this.statusFieldName);
          }
        }
        dashboardPO.dashboardRequest.filters = dashboardPO.dashboardRequest.filters.concat(...poStatusFilter);
      }

    if (this.props?.getDashboardPO && dashboardPO?.dashboardRequest && !this.props.dashboardPO?.isFetching) {
      this.props.getDashboardPO(dashboardPO?.dashboardRequest);
    }
  }

  // define columns
  columns: HeadCell<ProcurementViewModel>[] = [
    { name: 'edit', header: 'Edit', isSortable: false, filter: false, dataType: ColumnTypeEnum.edit },
    { name: 'tagId', header: 'Tag', dataType: ColumnTypeEnum.tag, isEditable: true },
    { name: 'vendorName', header: 'Vendor' },
    { name: 'poNumber', header: 'PO#', dataType: ColumnTypeEnum.link },
    { name: 'assetNumber', header: 'Asset#' },
    { name: 'lineId', header: 'Line Id', dataType: ColumnTypeEnum.text, isEditable: false },
    { name: 'poContract', header: 'PO Contract' },
    { name: 'items', header: 'Item' },
    { name: 'make', header: 'Make'},
    { name: 'model', header: 'Model'},
    { name: 'serial', header: 'Serial'},
    { name: 'qty', header: 'QTY', dataType: ColumnTypeEnum.number },
    { name: 'equipmentCost', header: 'Cost', dataType: ColumnTypeEnum.currency },
    { name: 'unitStatus', header: 'Unit Status'},
    { name: 'newReadyDate', header: 'New Ready Date', dataType: ColumnTypeEnum.date },
    { name: 'gap', header: 'GAP (InDays)', isSortable: false, filter: false },
    { name: 'destinationContract', header: 'Dest. Contract', dataType: ColumnTypeEnum.link },
    { name: 'isReceived', header: 'REC\'D', dataType: ColumnTypeEnum.boolean },
    { name: 'poStatus', header: 'PO Status', isSortable: true, filter: true },
    { name: 'irec', header: 'IREC' },
    { name: 'manufacturerInvoiceNumber', header: 'Invoice' },
    { name: 'manufacturerInvoiceStatus', header: 'Invoice Status' },
    { name: 'build', header: 'Build' },
    { name: 'poDate', header: 'PO Date', dataType: ColumnTypeEnum.date },
    { name: 'trSent', header: 'TR sent', dataType: ColumnTypeEnum.date },
    //{ name: 'updatedStatus', header: 'Updated Status'},   
   // { name: 'requisitioner', header: 'Requisitioner' },
    //{ name: 'quote', header: 'Quote#' },
    //{ name: 'transporter', header: 'Transporter' },
    //{ name: 'specFileName', header: 'Spec Form/ Attachments', dataType: ColumnTypeEnum.file, isEditable: false },
    { name: 'procurementTeamComments', header: 'Comments' }
  ];

  onPaginationChange(event: PaginatorPageChangeEvent) {
    const { dashboardPO } = this.props;
    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.pagination = pagination;
    }
    this.getDashboardPO();
  }

  onSort(event: DataTableStateEvent) {
    const { dashboardPO } = this.props;

    const sortOptions = DashboardHelper.getSortingOptions(event.sortField, event.sortOrder);
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.sorting = sortOptions;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  onDataTableFilter(event: DataTableStateEvent) {
    if(this.props.setFilterMetaData && event.filters){
      this.props.setFilterMetaData(event.filters);
    }
    const filters: Filters[] = DashboardHelper.setFilterData(event.filters, this.columns);

    const pagination = DashboardHelper.getPagination(event, this.minRecordCount);

    const { dashboardPO } = this.props;

    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.filters = filters;
      dashboardPO.dashboardRequest.pagination = pagination;
      dashboardPO.dashboardRequest.isDownload = false;
    }
    this.getDashboardPO();
  }

  setFilterMetaData(filterMetaData?: DataTableFilterMeta) {
    const { dashboardPO } = this.props;
    if (this.props?.setFilterMetaData && filterMetaData) {
      this.props.setFilterMetaData(filterMetaData);

      const filters: Filters[] = DashboardHelper.setFilterData(filterMetaData, this.columns);

      if (dashboardPO?.dashboardRequest) {
        dashboardPO.dashboardRequest.filters = filters;
        dashboardPO.dashboardRequest.isDownload = false;
      }
      setTimeout(() => {
        this.getDashboardPO();
      }, 100);
    }
  }

  getSpecificFieldData(columnName: string) {
    const currentColumn = this.columns.find(x => x.name === columnName);
    const { dashboardPO } = this.props;

    if (this.props?.getSpecificFieldDataWithRequest && dashboardPO?.dashboardRequest) {
      this.props.getSpecificFieldDataWithRequest(columnName, currentColumn?.dataType, dashboardPO?.dashboardRequest?.filters);
    }
  }

  getDropdownDataByColumnName(columnName: string) {
    const currentColumn = this.columns?.find(x => x.name === columnName);

    if (this.props?.getDropdownDataByColumnName) {
      this.props.getDropdownDataByColumnName(columnName, currentColumn?.dataType);
    }
  }

  uploadClickEvent() {
    this.setState({isUploadDialogVisible: true});
  }

  invoiceUploadHandler(event: FileUploadHandlerEvent) {
    if(this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();
    
    if(this.props.resetUploadApiMessage)
      this.props.resetUploadApiMessage();

    this.setState({shouldShowToast: true});
    UploadDownloadHelper.invoiceUploadHandler(event).then((formData: FormData) => {
      this.setState({isUploadDialogVisible: false});
      if(this.props.uploadProcurementFile) {
        this.props.uploadProcurementFile(formData).then((uploadApiMessage: string) => {
          this.setToastFromResponse(uploadApiMessage, 'Upload Process'); 
        });
      }
    });
  }

  onEditClick(data: DataTableValue) {    
    if(this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();
    
    if(this.props.resetUploadApiMessage)
      this.props.resetUploadApiMessage();

    this.setState({rowData: data});
    this.setState({isEditDialogVisible: true});
  }

  downloadClickEvent() {
    const { dashboardPO } = this.props;
    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.isDownload = true;
    }
    this.getDashboardPO();
  }

  poLinkClickEvent(event: any, columnName: string, columnValue: string) {
    if(this.props.resetUpdateApiMessage)
      this.props.resetUpdateApiMessage();
    
    if(this.props.resetUploadApiMessage)
      this.props.resetUploadApiMessage();

    event?.preventDefault();
    event?.stopPropagation();
    this.setState({
      shouldShowPOContractTile: true,
      selectedColumn: {
        columnName: columnName,
        columnValue: columnValue
      },
      isEditDialogVisible: false
    });
  }

  private downloadExcelAfterResponse(dashboardPO: DashboardState | undefined) {
    setTimeout(() => {
      if (!dashboardPO?.downloadUploadResponse.downloadLoading && dashboardPO?.downloadUploadResponse?.dashboardResponse) {
        const response = dashboardPO?.downloadUploadResponse.dashboardResponse;
        let downloadColumns: HeadCell<ProcurementViewModel>[] = [
          { name: 'tagId', header: 'Tag', dataType: ColumnTypeEnum.tag },
          { name: 'vendorName', header: 'Vendor' },
          { name: 'poNumber', header: 'PO#', dataType: ColumnTypeEnum.link},
          { name: 'assetNumber', header: 'Asset#' },
          { name: 'lineId', header: 'Line Id', dataType: ColumnTypeEnum.text},
          { name: 'poContract', header: 'PO Contract' },
          { name: 'items', header: 'Item' },
          { name: 'make', header: 'Make'},
          { name: 'model', header: 'Model'},
          { name: 'serial', header: 'Serial'},
          { name: 'qty', header: 'QTY', dataType: ColumnTypeEnum.number },
          { name: 'equipmentCost', header: 'Cost', dataType: ColumnTypeEnum.currency },
          { name: 'unitStatus', header: 'Unit Status'},
          { name: 'destinationContract', header: 'Dest. Contract', dataType: ColumnTypeEnum.link },
          { name: 'isReceived', header: 'REC\'D', dataType: ColumnTypeEnum.boolean},
          { name: 'poStatus', header: 'PO Status'},
          { name: 'irec', header: 'IREC' },
          { name: 'manufacturerInvoiceNumber', header: 'Invoice' },
          { name: 'manufacturerInvoiceStatus', header: 'Invoice Status' },
          { name: 'build', header: 'Build' },
          { name: 'poDate', header: 'PO Date', dataType: ColumnTypeEnum.date },
          { name: 'requisitioner', header: 'Requisitioner' },
          { name: 'trSent', header: 'TR sent', dataType: ColumnTypeEnum.date },
          { name: 'quote', header: 'Quote#' }
        ];

        let downloadItems: any[] = [];
        if(response?.data) {
          downloadItems = response.data.map((x: any) => (UploadDownloadHelper.setDownloadObject(x, downloadColumns, false)));
        }
        UploadDownloadHelper.exportExcel<DashboardViewModel>(downloadItems, 'procurement');
        if(this.props.resetDownloadDashboardData) {
          this.props.resetDownloadDashboardData();
        }
      }
    }, 50);
  }

  private setToastFromResponse(apiResponseMessage: string, summary: string) {
    if (apiResponseMessage) {
      apiResponseMessage.includes('success')
        ? this.showStickyToast('success', apiResponseMessage, summary)
        : this.showStickyToast('warn', apiResponseMessage, summary);
    }
  }
  
  showStickyToast(severity: 'info' | 'success' | 'warn' | 'error' | undefined, message: string, summary: string) {
    setTimeout(() => {
      this.toast?.current?.show({ severity: severity, summary: summary, detail: message, sticky: true });
    }, 50);
  }
 
  onPOContractTileHide() {
    this.setState({ shouldShowPOContractTile: false });
    this.getDashboardPO();
  }
  
  renderPOContractTile() {
    let header = '';
    if(this.state.selectedColumn.columnName) {
      header = this.state.selectedColumn.columnName === 'destinationContract' ? 'PO Contract Tile > ' + this.state.selectedColumn.columnValue :
          (this.state.selectedColumn.columnName === 'poNumber' ? 'PO # Tile' : this.state.selectedColumn.columnName);
    }

    return (
      <Dialog className='procurement-container dashboard-edit-dialog' header={header} visible={this.state.shouldShowPOContractTile} style={{ width: '95vw', height: '95vh' }}
        onHide={() => this.onPOContractTileHide()}>
        <div className="card procurement-container">
          <POContractTile selectedColumn={this.state.selectedColumn}/>
        </div>
      </Dialog>
    );
  }

  updateProcurementPO(request: any) {
    this.setState({shouldShowToast: true});
    if(this.props.updateProcurementPO) {
      this.props.updateProcurementPO(request).then((editAPIMessage: string) => {
        this.setToastFromResponse(editAPIMessage, 'Update Process'); 
        this.getDashboardPO();
        this.setState({isEditDialogVisible: false});
      });
    }
  }

  getSpecFormData(event: any, columnName: string, rowValue: any) {
    event?.preventDefault();
    this.setState({ pdfViewerFetching: true });
    this.setState({ showPdfViewerDialog: true });
    this.setState({ specDocName: rowValue[columnName] });
    if (this.props.getSpecDocumentData) {
      const columnDataName = UploadDownloadHelper.getValueColumnByDisplayColumn(columnName);
      this.props.getSpecDocumentData(rowValue['polId'], columnDataName).then((specFormData: string[]) => {
        this.setState({ specFormData: specFormData ? specFormData : '' });
        this.setState({ pdfViewerFetching: false });
      });
    }
  }

  renderPdfViewer() {
    const { showPdfViewerDialog, specFormData, specDocName, pdfViewerFetching } = this.state;
    return (
      <Dialog header={specDocName} className='rounded text-center pdf-viewer-dialog'
        visible={showPdfViewerDialog}
        onHide={() => this.setState({ showPdfViewerDialog: false })}>
        <PdfViewer base64String={specFormData} loading={pdfViewerFetching} fileName={specDocName}
          onClose={() => this.setState({ showPdfViewerDialog: false })} />
      </Dialog>
    );
  }

  renderTabView() {
    const { dashboardPO } = this.props;
    const request = dashboardPO?.dashboardRequest;
    const response = dashboardPO?.dashboardResponse;

    const formattedRecords = response?.data?.map((x: any) => (UploadDownloadHelper.setDashboardViewModelObject(x, this.columns)));
    const filters = dashboardPO?.filters;
    const sortOptions = request?.sorting;
    const dropdownOptions = dashboardPO?.dropdownOptions;
    const downloadUploadResponse = dashboardPO?.downloadUploadResponse;
    const editResponse = dashboardPO?.editResponse;

    const paginationOptions: PaginationInfo = DashboardHelper.getPaginationOptions(request, response?.totalCount);
    this.downloadExcelAfterResponse(dashboardPO);

    return(
      <React.Fragment>
        <ProcurementEditPopup isVisible={this.state.isEditDialogVisible} data={this.state.rowData}
          updateProcurementPO={this.updateProcurementPO} editResponse={editResponse} dropdownOptions={dashboardPO?.dropdownOptions} getDropdownDataByColumnName={this.getDropdownDataByColumnName} />
        <div className='dashboard-container'>
          <DashboardTable columns={this.columns} rows={formattedRecords ?? []}
            loading={this.props.dashboardPO?.isFetching ?? false}
            paginationOptions={paginationOptions}
            filters={filters}
            sortOptions={sortOptions}
            minRecordCount={this.minRecordCount}
            onPaginationChange={this.onPaginationChange}
            onSort={this.onSort}
            dropdownOptions={dropdownOptions}
            onDataTableFilter={this.onDataTableFilter}
            setFilterMetaData={this.setFilterMetaData}
            getSpecificFieldData={this.getSpecificFieldData}
            isSendNotification={false} 
            poLinkClick={this.poLinkClickEvent}
            onEditClick={this.onEditClick}
            specFileLinkClick={this.getSpecFormData} >
            <div className="flex justify-content-end p-0">
              {this.state.selectedTabIndex === 0 && <SplitButton loading={downloadUploadResponse?.uploadLoading} className='mr-3'
                label='Upload' onClick={this.uploadClickEvent} size='small' model={this.templateItems} />}
              <Button loading={downloadUploadResponse?.downloadLoading} label='Download'
                onClick={this.downloadClickEvent} size='small' type='button' />
              {this.renderUploadDialog()}
              {this.renderPOContractTile()}
            </div>
          </DashboardTable>
        </div>
      </React.Fragment>
    );
  }

  private renderUploadDialog() {
    return <Dialog header="Upload File" visible={this.state.isUploadDialogVisible} style={{ width: '50vw' }}
      onHide={() => this.setState({ isUploadDialogVisible: false })}>
      <div className="card">
        <FileUpload name="procurementUpload" accept=".xls, .xlsx"
          chooseLabel="Select a File"
          uploadHandler={this.invoiceUploadHandler} customUpload={true}
          maxFileSize={15000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
      </div>
    </Dialog>;
  }

  onPOTabChange(event: TabViewTabChangeEvent) {
    const { dashboardPO } = this.props;
    this.setState({selectedTabIndex: event.index});
    const poColumn = this.columns.find(x => x.name === this.statusFieldName);
    if(poColumn) {
      if(event.index === 0) {
        poColumn.isSortable = true;
        poColumn.filter = true;
      } else if(event.index === 1) {
        poColumn.isSortable = false;
        poColumn.filter = false;
      }
    }

    const sortOptions = DashboardHelper.getSortingOptions(undefined, null);
    const pagination = DashboardHelper.getPagination(undefined, this.minRecordCount);

    if (dashboardPO?.dashboardRequest) {
      dashboardPO.dashboardRequest.filters = [];
      dashboardPO.dashboardRequest.sorting = sortOptions;
      dashboardPO.dashboardRequest.pagination = pagination;
    }

    setTimeout(() => {
      this.getDashboardPO();
    }, 100);
  }

  /**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
  render() {
    const tabView = this.renderTabView();
    return (
      <React.Fragment>
        <Toast ref={this.toast} />
        {this.renderPdfViewer()}
        <div className='card m-0 procurement-container'>
          <h6 className='card-title ml-3 mt-2'>Procurement Team</h6>
          <TabView activeIndex={ this.state.selectedTabIndex } onTabChange={(e) => this.onPOTabChange(e)}> 
            <TabPanel header='Active PO'>
              {tabView}
            </TabPanel>
            <TabPanel header='Closed PO'>
              {tabView}
            </TabPanel>
          </TabView>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
  const { internalUser: auth, dashboard } = state;

  return {
    ...auth,
    dashboardPO: dashboard
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
  isDashboardLayoutEnable: () => dispatch(FlagsActions.isDashboardLayoutEnable()),
  getDashboardPO: (request: DashboardRequest) => {
    return dispatch(DashboardActions.getProcurementPO(request));
  },
  setFilterMetaData: (filters?: DataTableFilterMeta | undefined) => dispatch(DashboardActions.setFilterMetaData(filters)),
  resetDashboardData: () => dispatch(DashboardActions.resetDashboardData()),
  getSpecificFieldDataWithRequest: (columnName: string, columnType: ColumnTypeEnum | undefined, filters?: Filters[] | undefined) =>
    dispatch(DashboardActions.getSpecificFieldDataWithRequest(columnName, columnType, filters)),
  resetUploadApiMessage: () => dispatch(DashboardActions.resetUploadApiMessage()),
  resetDownloadDashboardData: () => dispatch(DashboardActions.resetDownloadDashboardData()),
  updateProcurementPO: (request: any) => dispatch(DashboardActions.updateProcurementPO(request)),
  resetUpdateApiMessage: () => dispatch(DashboardActions.resetUpdateApiMessage()),
  uploadProcurementFile: (request: any) => {
    return dispatch(DashboardActions.uploadProcurementFile(request));
  },
  requestedPageReset: () => dispatch(AuthActions.requestedPageReset()),
  getDropdownDataByColumnName: (columnName: string, columnType: ColumnTypeEnum | undefined) =>
    dispatch(DashboardActions.getDropdownDataByColumnName(columnName, columnType)),
  getSpecDocumentData: (polId: string, columnName: string) => dispatch(DashboardActions.getSpecDocumentData(polId, columnName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcurementContainer as any);