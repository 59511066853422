import React, { useEffect, useState } from 'react';
import { DataTable, DataTableFilterMeta, DataTableStateEvent, DataTableValue } from 'primereact/datatable';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { DashboardViewModel, PaginationInfo, SortingInfo } from '../../models/dashboard';

import  './dynamic-data-table.scss';
import { VirtualScrollerTemplateOptions } from 'primereact/virtualscroller';
import { classNames } from 'primereact/utils';
import { Skeleton } from 'primereact/skeleton';
import { DropdownOptions } from '../../../store/dashboard';
import DashboardHelper from '../../../helpers/DashboardHelper';
import { ColumnTypeEnum } from '../../models/ColumnTypeEnum';
import { DateHelper } from '../../../helpers';
import { HeadCell } from '../../models/HeadCell';
import { Tag } from 'primereact/tag';
import { TagTypeEnum } from '../../models/Enums/TagTypeEnum';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

type TableProps<DataType> = {
    columns: HeadCell<DataType>[];
    rows: DashboardViewModel;
    loading?: boolean;
    paginationOptions?: PaginationInfo;
    filters?: DataTableFilterMeta;
    sortOptions?: SortingInfo;
    dropdownOptions?: DropdownOptions;    
    minRecordCount: number;
    children?: React.ReactElement;
    scrollHeight?: string;
    onPaginationChange?: (event: PaginatorPageChangeEvent) => void;
    onDataTableFilter?: (event: DataTableStateEvent) => void;
    onSort?: (event: DataTableStateEvent) => void;
    setFilterMetaData?: (filters?: DataTableFilterMeta) => void;
    getSpecificFieldData?: (columnName: string) => void;
    poLinkClick?: (event: any, columnName: string, columnValue: string) => void;
    isSendNotification: boolean;
    onViewClick?: (data: DataTableValue) => void;
    onEditClick?: (data: DataTableValue) => void;
    sendNotification?: (selectData: any) => void;
    specFileLinkClick?: (event: any, columnName: string, rowValue: any) => void;
    onDeleteClick?: (rowData: DataType) => void;
};


export function DashboardTable<T extends DataTableValue>({ columns, rows, loading, paginationOptions,
    filters, sortOptions, minRecordCount, children, scrollHeight,
    dropdownOptions, onPaginationChange, onSort, onDataTableFilter, setFilterMetaData, getSpecificFieldData, isSendNotification,
    onEditClick, onViewClick, poLinkClick, sendNotification, specFileLinkClick, onDeleteClick }: TableProps<T>) {

    const [selectedCells, setSelectedCells] = useState<any>(null);

    useEffect(() => {
        initFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEditCellClick = (event: any, data: T) =>{
        event?.stopPropagation();
        event?.preventDefault();
        if(onEditClick)
            onEditClick(data);
    };

    const onViewCellClick = (event: any, data: T) => {
        event?.stopPropagation();
        event?.preventDefault();
        if (onViewClick)
            onViewClick(data);
    };

    const onSendNotificationClick = (event: any) => {
        event?.stopPropagation();
        event?.preventDefault();        
        if(sendNotification)
            sendNotification(selectedCells);
    };
    
    const poLinkClickEvent = (event: any, columnName: string, columnValue: string) =>{
        event?.stopPropagation();
        event?.preventDefault();
        if(poLinkClick)
            poLinkClick(event, columnName, columnValue);
    };

    const specFileLinkClickEvent = (event: any, columnName: string, rowValue: any) => {
        event?.stopPropagation();
        event?.preventDefault();
        if(specFileLinkClick)
            specFileLinkClick(event, columnName, rowValue);
    };

    const rowBodyTemplate = (rowData: T, columnName: string) => {
        const dataType = columns.find(x => x.name === columnName)?.dataType;
        if (dataType === ColumnTypeEnum.edit) {
            return  (
                <Button link style={{textDecoration : 'none'}} onClick = {(event: any) => onEditCellClick(event, rowData)}>
                    <div className='flex align-items-center gap-1'>
                        <i className={'pi pi-pencil'} style={{ fontSize: '1rem' }}></i>
                        {dataType === ColumnTypeEnum.edit && <span>{'Edit'}</span>}
                    </div>
                </Button>
            );
        } else if (dataType === ColumnTypeEnum.view) {
            return (
                <Button link style={{ textDecoration: 'none' }} onClick={(event: any) => onViewCellClick(event, rowData)}>
                    <div className='flex align-items-center gap-1'>
                        <i className={'pi pi-pencil'} style={{ fontSize: '1rem' }}></i>
                        {dataType === ColumnTypeEnum.view && <span>{'View'}</span>}
                    </div>
                </Button>
            );
        } else if (dataType === ColumnTypeEnum.tag) {
            const currentTagOption = DashboardHelper.getTagOptionFromValue(rowData[columnName]);
            if (currentTagOption) {
                if (currentTagOption.value !== TagTypeEnum.noTag) {
                    return (
                        <div className="flex align-items-center">
                            <div style={{ backgroundColor: currentTagOption.color, width: '15px' }}>&nbsp;&nbsp;&nbsp;</div>
                            <div className='pl-2'>{currentTagOption.label}</div>
                        </div>
                    );
                }
                return <span></span>;
            }
            return renderCellSection(rowData[columnName]);
        } else if (dataType === ColumnTypeEnum.delete) {
            return deleteBodyTemplate(rowData);
        } else if (dataType === ColumnTypeEnum.shippingStatus) {  
            if(rowData[columnName] != null) {            
            return (                            
                    <Tag className='tag-css' value={rowData[columnName]} severity={DashboardHelper.getStatus(rowData[columnName])}></Tag>  );
            }
                else{
                    return rowData[columnName];
                }
           
        }
        else if(dataType === ColumnTypeEnum.link) {
            if(rowData[columnName]) {
                return  (
                    <Button link onClick={(event: any) => poLinkClickEvent(event, columnName, rowData[columnName])}>
                        <div className="flex align-items-center gap-1">
                            <span title={rowData[columnName]}>{rowData[columnName]}</span>
                        </div>
                    </Button>                
                );
            }
            return renderCellSection(rowData[columnName]);
        } else if(dataType === ColumnTypeEnum.file) {
            if(rowData[columnName]) {
                return  (
                    <Button link onClick={(event: any) => specFileLinkClickEvent(event, columnName, rowData)}>
                        <div className="flex align-items-center gap-1">
                            <span>{rowData[columnName]}</span>
                        </div>
                    </Button>                
                );
            }
            return renderCellSection(rowData[columnName]);
        }         
        else if(dataType === ColumnTypeEnum.date) {
            const cellValue = rowData[columnName] ? DateHelper.formatDate(new Date(rowData[columnName])) : rowData[columnName];
            return renderCellSection(cellValue);
        } else if (dataType === ColumnTypeEnum.currency) {
            const cellValue = rowData[columnName] ? currencyBodyTemplate(rowData[columnName]) : rowData[columnName];
            return renderCellSection(cellValue);
        } else if (dataType === ColumnTypeEnum.boolean || dataType === ColumnTypeEnum.inputSwitch) {
            return rowData[columnName] !== undefined ? booleanBodyTemplate(rowData[columnName]) : '';
        }
        return renderCellSection(rowData[columnName]);
    };

    const deleteBodyTemplate = (rowData: T) => {
        return (
            <span className="pi pi-trash mx-2 delete-icon" onClick={() => deleteConfirm(rowData)} title="Delete"></span>
        );
    };

    const deleteConfirm = (rowData: T) => {
        confirmDialog({
            message: 'Are you sure you want to remove?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deletePoLineItemClick(rowData),
            reject: () => { }
        });
    };

    const deletePoLineItemClick = (rowData: T) => {
        if (onDeleteClick) {
            onDeleteClick(rowData);
        }
    };

    const renderCellSection = (cellValue: string) => {
        return(
            <span onClick={(event: any) => {event.stopPropagation(); event.preventDefault();}} title={cellValue}>{cellValue}</span>
        );
    };

    const formatCurrency = (value: number) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const filterTemplate = (options: ColumnFilterElementTemplateOptions, columnName: string) => {
        const dataType = columns.find(x => x.name === columnName)?.dataType;
        return dropdownFilterTemplate(options, dataType);
    };

    const loadingTemplate = (options: VirtualScrollerTemplateOptions) => {
        const className = classNames('flex align-items-center p-2', {
            odd: options.odd
        });

        return (
            <div className={className} style={{ height: '50px' }}>
                <Skeleton width={options.even ? '60%' : '50%'} height="1.3rem" />
            </div>
        );
    };

    const dropdownFilterTemplate = (options: ColumnFilterElementTemplateOptions, dataType?: ColumnTypeEnum) => {
        return <MultiSelect key={options.field} value={options.value} filter options={DashboardHelper.getDropDownOptions(options.field, dropdownOptions, dataType) ?? []}
            optionValue="value" optionLabel="label"
            maxSelectedLabels={1} emptyFilterMessage='No values found'
            virtualScrollerOptions={{ itemSize: 43, loadingTemplate: loadingTemplate, showLoader: true, lazy: true,
                 loading: dropdownOptions?.isFetching, onLazyLoad: () => filterOnshow(options.field) }}
            onChange={(e: MultiSelectChangeEvent) => options.filterCallback(e.value)} placeholder={'Select ...'} className="p-column-filter" />;
    };

    const currencyBodyTemplate = (options: number) => {
        return options ? formatCurrency(options) : '';
    };

    const booleanBodyTemplate = (value: boolean) => {
        return (
            <div onClick={(event: any) => {event.stopPropagation(); event.preventDefault();}} className='flex align-items-center '>
                <i className={classNames('pi',
                        { 'true-icon pi-check-circle': value, 'false-icon pi-times-circle': !value })}></i>
            </div>
        );
    };

    const filterOnshow = (columnName: string) => {
        if (getSpecificFieldData && !dropdownOptions?.isFetching && !(dropdownOptions?.columnOptions
          .some(x => x.columnName === columnName && x.options?.length > 0))) {
            getSpecificFieldData(columnName);
        }
    };

    const initFilters = () => {
        const filtersObjects: DataTableFilterMeta = {};
        columns.forEach((column) => {
            if(column.name) {
                filtersObjects[column.name] = { value: null, matchMode: FilterMatchMode.IN };
            }
        });
        if(setFilterMetaData) {
            setFilterMetaData(filtersObjects);
        }
    };

    const clearFilter = () => {
        initFilters();
    };

    const renderFooter = () => {
        if(paginationOptions) {
            return (
                <Paginator first={paginationOptions.first} rows={paginationOptions.pageSize}
                template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" 
                    totalRecords={paginationOptions.totalCount} rowsPerPageOptions={[5, 10, 25, 50, 100, 200]} onPageChange={onPaginationChange} />
            );
        }
        return <React.Fragment></React.Fragment>;
    };

   

    const isCellSelectable = (event: any) => {
        return (isSendNotification && event.data.field === 'sendNotification' ? true : true);
    };

    const renderHeader = () => {
        return (
            <React.Fragment>
                <div className='flex justify-content-between'>
                    <Button type="button" icon="pi pi-filter-slash" size='small' label="Clear" outlined onClick={clearFilter} />
                    <div className='flex'>
                        {children}
                        {isSendNotification ?
                            <Button type="button" size='small' className="ml-4 flex justify-content-end" label="Send Notification" onClick={onSendNotificationClick} />
                            : ''
                        }
                    </div>
                </div>           
            </React.Fragment>
        );
    };

    const header = renderHeader();
    const footer = renderFooter();
    const defaultSortOrder = sortOptions?.isAsc === true ? 1 : (sortOptions?.isAsc === false ? -1 : 0);
    const selectionMode = isSendNotification ? 'checkbox' : undefined;
    return (
        <React.Fragment>
            <ConfirmDialog />
            <DataTable className='dataTable' key="DashboardTable" id="tableName" value={rows}
                scrollable scrollHeight={scrollHeight ?? '62vh'}
                tableStyle={{ width: 'max-content' }}
                rowGroupMode="rowspan"
                showGridlines rows={minRecordCount} loading={loading}
                filters={filters}
                header={header} size="small" footer={footer}
                onFilter={onDataTableFilter} sortField={sortOptions?.columnName} sortOrder={defaultSortOrder}
                onSort={onSort} removableSort 
                cellSelection={isSendNotification} selectionMode={selectionMode} selection={selectedCells} onSelectionChange={(e: any) => setSelectedCells(e.value)}
                isDataSelectable={isCellSelectable}
                emptyMessage="No Records Found.">
                {columns.map((col, index) => {
                    const columnName = col.name ?? (col.dataType ?  ColumnTypeEnum[col.dataType] : '');
                    return (                    
                        <Column key={columnName + index}
                        sortable= {col.isSortable ?? true} field={col.name} header={col.header ?? columnName} filterField={columnName}
                        showFilterMatchModes={false} filter={col.filter ?? true}
                        body={(row) => rowBodyTemplate(row, columnName)}
                        dataType={col.dataType ? ColumnTypeEnum[col.dataType] : undefined}
                        filterElement={(options) => filterTemplate(options ?? [], columnName)}                          
                        selectionMode={col.selectionMode}                    
                    />
                );})}
            </DataTable>
        </React.Fragment>
    );
}
