import { ColumnTypeEnum } from '../shared/models/ColumnTypeEnum';
import { AdminOperationsEnum } from '../shared/models/Enums/AdminOperationsEnum';
import { HeadCell } from '../shared/models/HeadCell';
import { UserViewModel } from '../shared/models/UserViewModel';

class AdminUserColumnHelper {
    public static getColumns(operation: AdminOperationsEnum): HeadCell<UserViewModel>[] {
        switch (operation) {
            case AdminOperationsEnum.viewAll:
            case AdminOperationsEnum.view:
                return [
                    { name: 'edit', header: 'Edit', isSortable: false, filter: false, dataType: ColumnTypeEnum.edit },
                    { name: 'delete', header: 'Delete', isSortable: false, filter: false, dataType: ColumnTypeEnum.delete },
                    { name: 'name', header: 'Name', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'emailAddress', header: 'Email Address' },
                    { name: 'mobilePhoneNo', header: 'Mobile Phone No', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'officePhoneNo', header: 'Office Phone No', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'vendorName', header: 'Vendor Name', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'userType', header: 'User Type', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'vendorType', header: 'Vendor Type', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'isActivated', header: 'Is Activated', dataType: ColumnTypeEnum.inputSwitch },
                    { name: 'isActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },
                    { name: 'view', dataType: ColumnTypeEnum.view }
                ];
            case AdminOperationsEnum.edit:
                return [
                    { name: 'name', header: 'Name', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true },
                    { name: 'vendorName', header: 'Vendor Name', isEditable: true, dataType: ColumnTypeEnum.dynamicDropdown, isRequired: true },
                    { name: 'userType', header: 'User Type', isEditable: true, dataType: ColumnTypeEnum.dynamicDropdown, isRequired: true },
                    { name: 'vendorType', header: 'Vendor Type', dataType: ColumnTypeEnum.text },
                    { name: 'emailAddress', header: 'Email Address' },
                    { name: 'mobilePhoneNo', header: 'Mobile Phone No', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true, isPresentInMultipleColumn: true, isValidMobile:true },
                    { name: 'officePhoneNo', header: 'Office Phone No', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true, isPresentInMultipleColumn: true, isValidMobile:true },
                    { name: 'isActivated', header: 'Is Activated', isEditable: true, dataType: ColumnTypeEnum.inputSwitch },
                    { name: 'isActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },
                ];
            case AdminOperationsEnum.add:
                return [
                    { name: 'name', header: 'Name', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true },
                    { name: 'vendorName', header: 'Vendor Name', isEditable: true, dataType: ColumnTypeEnum.dynamicDropdown, isRequired: true },
                    { name: 'userType', header: 'User Type', isEditable: true, dataType: ColumnTypeEnum.dynamicDropdown, isRequired: true },
                    { name: 'emailAddress', header: 'Email Address', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true, isValidEmail: true },
                    { name: 'mobilePhoneNo', header: 'Mobile Phone No', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true, isPresentInMultipleColumn: true, isValidMobile: true },
                    { name: 'officePhoneNo', header: 'Office Phone No', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true, isPresentInMultipleColumn: true, isValidMobile: true },
                    { name: 'isActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },
                ];
            default:
                return [];
        }
    }
}

export default AdminUserColumnHelper;

