import { appSettings } from '..';
import { AdminDecalViewModel } from '../shared/models/AdminDecalViewModel';
import { AdminTemplateViewModel } from '../shared/models/AdminTemplateViewModel';
import { AdminVendorViewModel } from '../shared/models/AdminVendorViewModel';
import { StationViewModel } from '../shared/models/StationViewModel';
import { UserViewModel } from '../shared/models/UserViewModel';
import { DashboardRequest } from '../shared/models/dashboard';
import BaseApiClient from './BaseApi';
import { DashboardServiceApi } from './DashboardServiceApi';

export class AdminServiceApi extends BaseApiClient {
    public static scopes: string[] = [
        'User.Read'
    ];

    public static auth(enabled?: boolean, isInternal?: boolean) {
        const authenticated = (enabled === undefined) ? true : enabled;
        const isInternalValue = (isInternal === undefined) ? true : isInternal;
        return new this(authenticated, isInternalValue);
    }

    protected constructor(authenticated?: boolean, isInternal?: boolean) {
        const { gseTrackerApi } = appSettings;
        super(gseTrackerApi.host, authenticated, isInternal);
    }

    public getAllVendors(request: DashboardRequest): Promise<AdminVendorViewModel[]> {
        return this._post<AdminVendorViewModel[]>(this.baseUrl + '/Admin/GetVendors', request, DashboardServiceApi.scopes);
    }

    public getAllStationContact(request: DashboardRequest): Promise<StationViewModel[]> {
        return this._post<StationViewModel[]>(this.baseUrl + '/Admin/GetAllStation', request, DashboardServiceApi.scopes);
    }

    public deleteVendor(vendorId: string): Promise<string> {
        return this._post<string>(this.baseUrl + '/Admin/DeleteVendor?vendorId=' + vendorId, '', DashboardServiceApi.scopes);
    }

    public deleteStationContact(stationId: string): Promise<string> {
        return this._post<string>(this.baseUrl + '/Admin/DeleteStation?stationId=' + stationId, '', DashboardServiceApi.scopes);
    }

    public deleteVendorUser(userId: string) {
        return this._post<string>(this.baseUrl + '/Admin/DeleteUser?userId=' + userId, '', DashboardServiceApi.scopes);
    }

    public getAllVendorUser(request: DashboardRequest): Promise<UserViewModel[]> {
        return this._post<UserViewModel[]>(this.baseUrl + '/Admin/GetUsers', request, DashboardServiceApi.scopes);
    }

    public getAllTemplate(request: DashboardRequest): Promise<AdminTemplateViewModel[]> {
        return this._post<AdminTemplateViewModel[]>(this.baseUrl + '/Admin/GetAllTemplates', request, DashboardServiceApi.scopes);
    }

    public deleteTemplate(templateId: string): Promise<string> {
        return this._post<string>(this.baseUrl + '/Admin/DeleteTemplate?templateId=' + templateId, '', DashboardServiceApi.scopes);
    }
    
    public updateUser(request: any) {
        return this._post<string>(this.baseUrl + '/Admin/EditVendorUser', request, DashboardServiceApi.scopes);
    }

    public updateVendor(request: AdminVendorViewModel) {
        return this._post<string>(this.baseUrl + '/Admin/UpdateVendor', request, DashboardServiceApi.scopes);
    }

    public addUser(request: any) {
        return this._post<string>(this.baseUrl + '/Admin/CreateVendorUser', request, DashboardServiceApi.scopes);
    }

    public updateTemplate(request: any) {
        return this._post<string>(this.baseUrl + '/Admin/EditTemplate', request, DashboardServiceApi.scopes);
    }
    
    public updateStation(request: StationViewModel) {
        return this._post<string>(this.baseUrl + '/Admin/UpdateStation', request, DashboardServiceApi.scopes);
    }

    public addTemplate(request: any) {
        return this._post<string>(this.baseUrl + '/Admin/CreateTemplate', request, DashboardServiceApi.scopes);
    }

    public getAllDecal(request: DashboardRequest): Promise<AdminDecalViewModel[]> {
        return this._post<AdminDecalViewModel[]>(this.baseUrl + '/Admin/GetDecals', request, DashboardServiceApi.scopes);
    }

    public deleteDecal(templateId: string): Promise<string> {
        return this._post<string>(this.baseUrl + '/Admin/DeleteDecal?stationId=' + templateId, '', DashboardServiceApi.scopes);
    }

    public updateDecal(request: AdminDecalViewModel) {
        return this._post<string>(this.baseUrl + '/Admin/UpdateDecal', request, DashboardServiceApi.scopes);
    }  
}