import { FileUploadHandlerEvent } from 'primereact/fileupload';
import { HeadCell } from '../shared/models/HeadCell';
import { ColumnTypeEnum } from '../shared/models/ColumnTypeEnum';
import { DownloadModel } from '../shared/models/downloadModel';
import { UploadFileColumn, uploadFile } from '../shared/models/UploadFile';

class UploadDownloadHelper {

    static uploadFiles = [
        {
            displayNameColumn: 'proBOLFileName',
            valueColumn: 'proBOLFileData'
        },
        {
            displayNameColumn: 'transportInvoiceFileName',
            valueColumn: 'transportInvoiceFileData'
        },
        {
            displayNameColumn: 'specFileName',
            valueColumn: 'specFileData'
        }
    ];

    static async invoiceUploadHandler(event: FileUploadHandlerEvent) : Promise<FormData> {
        // convert file to base64 encoded
        const file = event.files[0];
        const base64Data = await this.getBase64FromFile(file);
        let formData = new FormData();
        if (typeof base64Data === 'string') {
            formData.append('formFile', base64Data);
        }
        formData.append('fileName', file.name);
        return formData;
    }

    static async specDocumentUploadHandler(event: FileUploadHandlerEvent) : Promise<uploadFile> {
        // convert file to base64 encoded
        const file = event.files[0];
        const base64Data = await this.getBase64FromFile(file) as string;
        return Promise.resolve({fileName: file.name, fileData: base64Data});
    }

    static getBase64FromFile(file: File) {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = function () {
                if (typeof reader.result === 'string') {
                    resolve(reader.result?.split(',')[1]);
                }
            };
            reader.readAsDataURL(file);
        });
    }

    static exportExcel<T>(items: T, fileName: string) {
        import('xlsx').then((xlsx) => {
            const sheetName = 'POMaster';
            const worksheet = xlsx.utils.json_to_sheet(items as any[]);
            const workbook = { Sheets: { POMaster: worksheet }, SheetNames: [sheetName]  };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            UploadDownloadHelper.saveAsExcelFile(excelBuffer, fileName);
        });
    }

    static setDownloadObject<T>(listItem: T, columns: HeadCell<T>[], isAdditionalColumnRequired: boolean = true) {
        const downloadITem: DownloadModel = {};
        let exportColumns = [...columns];
        if (isAdditionalColumnRequired) {
            const additionalColumns: HeadCell<any>[] = [
                { name: 'polId', header: 'PO Line Id' },
                { name: 'lineId', header: 'Line Id' },
                { name: 'vehicleType', header: 'Vehicle Type' }
            ];
            exportColumns = exportColumns.concat(additionalColumns);
        }

        exportColumns.filter(column => column.dataType !== ColumnTypeEnum.edit && column.dataType !== ColumnTypeEnum.delete && column.header).forEach(column => {
            const header = column.header?.toUpperCase() ?? column.name?.toUpperCase();
            if (column.dataType === ColumnTypeEnum.date) {
                downloadITem[header] = listItem[column.name] ? new Date(listItem[column.name] as string | Date) : '';
            } else {
                downloadITem[header] = listItem[column.name];
            }
        });
        return downloadITem;
    }

    
    static setDashboardViewModelObject<T>(listItem: T, columns: HeadCell<T>[]): T {
        columns.forEach(column => {
            const header: Extract<keyof T, string> = column.name;
            if (column.dataType === ColumnTypeEnum.date) {
                listItem[header] = (listItem[header] ? new Date(listItem[header] as string | Date) : undefined) as any;
            }
        });
        return listItem;
    }

    static saveAsExcelFile(buffer: any, fileName: string) {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    static getFileDataColumnByDisplayColumn(columnName: string, uploadFiles: UploadFileColumn[]) {
        return (uploadFiles.find(file => file.displayNameColumn === columnName)?.valueColumn) ?? '';
    }

    static getUploadFiles(): UploadFileColumn[] {
        return UploadDownloadHelper.uploadFiles;
    }

    static getValueColumnByDisplayColumn(displayColumn: string) {
        return UploadDownloadHelper.uploadFiles.find(x => x.displayNameColumn === displayColumn)?.valueColumn ?? '';
    }
}

export default UploadDownloadHelper;