import { Dispatch } from 'react';
import { DashboardRequest } from '../../shared/models/dashboard';
import { AdminServiceApi } from '../../api/AdminServiceApi';
import { dashboardPOSuccess, isDownloadLoading, isFetchingDashboard } from '../dashboard/actions';
import { StationViewModel } from '../../shared/models/StationViewModel';

function setIsLoadingBasedOnRequestType(dispatch: Dispatch<any>, isDownload: boolean, isLoading: boolean) {
    if (isDownload) {
        dispatch(isDownloadLoading(isLoading));
    } else {
        dispatch(isFetchingDashboard(isLoading));
    }
}

export function getAllVendors(dashboardRequest: DashboardRequest) {
    return (dispatch: Dispatch<any>) => {
        setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, true);
        return AdminServiceApi.auth().getAllVendors(dashboardRequest)
            .then((response: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, response));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, undefined));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                // handle error
            });
    };
}

export function getAllStationContact(dashboardRequest: DashboardRequest) {
    return (dispatch: Dispatch<any>) => {
        setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, true);
        return AdminServiceApi.auth().getAllStationContact(dashboardRequest)
            .then((response: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, response));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, undefined));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                // handle error
            });
    };
}

export function deleteVendor(vendorId: string) {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().deleteVendor(vendorId)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function deleteStationContact(stationId: string) {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().deleteStationContact(stationId)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function getAllUser(dashboardRequest: DashboardRequest) {
    return (dispatch: Dispatch<any>) => {
        setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, true);
        return AdminServiceApi.auth().getAllVendorUser(dashboardRequest)
            .then((response: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, response));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, undefined));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                // handle error
            });
    };
}

export function deleteUser(userId: string) {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().deleteVendorUser(userId)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function getAllTemplate(dashboardRequest: DashboardRequest) {
    return (dispatch: Dispatch<any>) => {
        setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, true);
        return AdminServiceApi.auth().getAllTemplate(dashboardRequest)
            .then((response: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, response));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, undefined));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                // handle error
            });
    };
}

export function deleteTemplate(templateId: string) {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().deleteTemplate(templateId)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function addUser(request: any): any {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().addUser(request)
            .then((response: any) => {
                if (response) {
                    return response?.statusDesc;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function updateUser(request: any): any {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().updateUser(request)
            .then((response: any) => {
                if (response) {
                    return response?.statusDesc;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function updateVendor(request: any): any {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().updateVendor(request)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function updateStation(request: StationViewModel): any {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().updateStation(request)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function addTemplate(request: any): any {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().addTemplate(request)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function updateTemplate(request: any): any {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().updateTemplate(request)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function getAllDecal(dashboardRequest: DashboardRequest) {
    return (dispatch: Dispatch<any>) => {
        setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, true);
        return AdminServiceApi.auth().getAllDecal(dashboardRequest)
            .then((response: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, response));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, undefined));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                // handle error
            });
    };
}

export function deleteDecal(stationId: string) {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().deleteDecal(stationId)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function addDecal(request: any): any {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().updateDecal(request)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}

export function updateDecal(request: any): any {
    return (_dispatch: Dispatch<any>) => {
        return AdminServiceApi.auth().updateDecal(request)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // handle error
            });
    };
}
