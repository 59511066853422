/* eslint-disable no-unused-vars */
export enum DashboardPOActionTypes {
    DASHBOARD_PO_SUCCESS = '@@dashboard/DASHBOARD_PO_SUCCESS',
    DASHBOARD_PO_LOADING = '@@dashboard/DASHBOARD_PO_LOADING',
    SET_DASHBOARD_REQUEST = '@@dashboard/SET_DASHBOARD_REQUEST',
    SET_FILTER_METADATA = '@@dashboard/SET_FILTER_METADATA',
    RESET_DASHBOARD_DATA = '@@dashboard/RESET_DASHBOARD_DATA',
    DASHBOARD_DROPDOWN_LOADING = '@@dashboard/DASHBOARD_DROPDOWN_LOADING',
    DASHBOARD_DROPDOWN_SUCCESS = '@@dashboard/DASHBOARD_DROPDOWN_SUCCESS',
    DASHBOARD_DOWNLOAD_LOADING = '@@dashboard/DASHBOARD_DOWNLOAD_LOADING',
    DASHBOARD_UPLOAD_SUCCESS = '@@dashboard/DASHBOARD_UPLOAD_SUCCESS',
    RESET_UPLOAD_API_MESSAGE = '@@dashboard/RESET_UPLOAD_API_MESSAGE',
    RESET_DOWNLOAD_DASHBOARD_DATA = '@@dashboard/RESET_DOWNLOAD_DASHBOARD_DATA',
    DASHBOARD_UPLOAD_LOADING = '@@dashboard/DASHBOARD_UPLOAD_LOADING',
    PO_TILE_SUCCESS = '@@dashboard/PO_TILE_SUCCESS',
    PO_TILE_LOADING = '@@dashboard/PO_TILE_LOADING',
    DASHBOARD_EDIT_LOADING = '@@dashboard/DASHBOARD_EDIT_LOADING',
    DASHBOARD_EDIT_SUCCESS = '@@dashboard/DASHBOARD_EDIT_SUCCESS',
    RESET_PO_CONTRACT_TILE_DATA = '@@dashboard/RESET_PO_CONTRACT_TILE_DATA',
    RESET_UPDATE_API_MESSAGE = '@@dashboard/RESET_UPDATE_API_MESSAGE',
    RESET_DROPDOWN_WITH_FILTER = '@@dashboard/RESET_DROPDOWN_WITH_FILTER'
}