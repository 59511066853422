import { Dispatch } from 'redux';
import { StringDict } from 'msal/lib-commonjs/MsalTypes';
import { appInsights } from '../..';
import { GraphApi, VendorAuthServiceApi } from '../../api';
import { UserActionTypes, UserTypes } from '../userState';
import { ActivationRequest, ResetPwdRequest, UserAuthRequest, UserAuthResponse } from '../../shared/models/user-auth-response';
import ApiError from '../../shared/models/ApiError';
import { store } from '..';

export type UserAction = ExtSignInStart | ExtSignInSuccess | ExtSignInFailure | ExtSignOutStart
    | ExtAuthorizationSuccess | ExtAuthorizationFailure | ExtInitializeStart | ExtInitializeComplete
    | ExtSessionValidated | ExtSessionInvalidated | ExtUserNameSet | ExtUserNameReset | ExtUserTypeSet | ExtUserTypeReset
    | ExtIsAuthenticatedSet | ExtIsAuthenticatedReset;

interface ExtInitializeStart {
    type: UserActionTypes.INITIALIZE_START;
}

interface ExtInitializeComplete {
    type: UserActionTypes.INITIALIZE_COMPLETE;
}

interface ExtSignInStart {
    type: UserActionTypes.SIGNIN_START;
}

interface ExtResetPwdStart {
    type: UserActionTypes.RESET_PASSWORD_START;
}

interface ExtSignInSuccess {
    payload: {
        idToken?: any;
        accessToken?: any;
    };
    type: UserActionTypes.SIGNIN_SUCCESS;
}

interface ExtSignInFailure {
    error: ApiError;
    type: UserActionTypes.SIGNIN_FAIL;
}

interface ExtAuthorizationSuccess {
    payload: {
        accessToken?: any;
    };
    type: UserActionTypes.AUTHORIZE_SUCCESS;
}

interface ExtAuthorizationFailure {
    error: any;
    type: UserActionTypes.AUTHORIZE_FAILURE;
}

interface ExtSignOutStart {
    type: UserActionTypes.SIGNOUT_START;
}

interface ExtSessionValidated {
    idToken: StringDict;
    type: UserActionTypes.SESSION_UPDATE;
}

interface ExtSessionInvalidated {
    type: UserActionTypes.SESSION_INVALIDATE;
}

interface ExtUserNameSet {
    payload: {
        userName: string;
    };
    type: UserActionTypes.EXT_NAME_SET;
}

interface ExtUserNameReset {
    type: UserActionTypes.EXT_NAME_RESET;
}

interface ExtUserTypeSet {
    type: UserActionTypes.EXT_TYPE_SET;
    payload: {
        userType: UserTypes | undefined;
    };
}

interface ExtUserTypeReset {
    type: UserActionTypes.EXT_TYPE_RESET;
}

interface ExtIsAuthenticatedSet {
    type: UserActionTypes.EXT_ISAUTHENTICATED_SET;
    payload: {
        isAuthenticated: boolean;
    };
}

interface ExtIsAuthenticatedReset {
    type: UserActionTypes.EXT_ISAUTHENTICATED_RESET;
}

const extUserTypeSet = (userType: UserTypes): ExtUserTypeSet => {
    return {
        payload: {
            userType: userType,
        },
        type: UserActionTypes.EXT_TYPE_SET
    };
};

const extIsAuthenticatedSet = (isAuthenticated: boolean): ExtIsAuthenticatedSet => {
    return {
        payload: {
            isAuthenticated: isAuthenticated,
        },
        type: UserActionTypes.EXT_ISAUTHENTICATED_SET
    };
};

const extSignInStart = (_username?: string): ExtSignInStart => {
    return {
        type: UserActionTypes.SIGNIN_START,
    };
};

const extSignInSuccess = (token: string): ExtSignInSuccess => {
    return {
        payload: {
            idToken: token,
        },
        type: UserActionTypes.SIGNIN_SUCCESS,
    };
};

const extSignOutStart = () => {
    return {
        type: UserActionTypes.SIGNOUT_START,
    };
};

const extAuthorizationSuccess = (accessToken: string): ExtAuthorizationSuccess => {
    return {
        payload: {
            accessToken: accessToken
        },
        type: UserActionTypes.AUTHORIZE_SUCCESS,
    };
};

const handleTokenReceived = (response: UserAuthResponse) => {
    if (store) {
        if (response.isValidUser) {
            store.dispatch(extAuthorizationSuccess(response.authenticationToken));
        }
        store.dispatch(extUserTypeSet(UserTypes.external));
        store.dispatch(extSignInSuccess(response.authenticationToken));
        store.dispatch(extIsAuthenticatedSet(response.isValidUser));
    }
};

/**
 * Logs user into the app
 */
export function signIn(userDetails: UserAuthRequest, silent?: boolean) {
    return (dispatch: Dispatch<any>) => {
        dispatch(extSignInStart(userDetails?.emailAddress));
        if (silent) {
            // silent login
            return undefined;
        } else {
            return VendorAuthServiceApi.auth(false).vendorLoginAuth(userDetails)
                .then((response: any) => {                    
                    handleTokenReceived(response);
                    return response;
                }, (_error: any) => {
                    console.log(_error);
                });
        }
    };
}

export function authenticateUser(request: ActivationRequest, id: string, activate: string) {
    return (dispatch: Dispatch<any>) => {
        dispatch(extResetPwdstart(id));
        return VendorAuthServiceApi.auth(false).authenticateUser(request, id, activate)
            .then((response: any) => {
                dispatch(editSuccess(response.httpStatusCode));
                return response;
            }, (_error: any) => {
                console.log(_error);
                dispatch(editSuccess(_error.code));
            });

    };
}

export function ResetPassword(request: ResetPwdRequest) {
    return (dispatch: Dispatch<any>) => {
        dispatch(extResetPwdstart(request.userId));
        return VendorAuthServiceApi.auth(false).resetPassword(request)
            .then((response: any) => {
                dispatch(editSuccess(response.httpStatusCode));
                return response;
            }, (_error: any) => {
                console.log(_error);
                dispatch(editSuccess(_error.code));
            });

    };
}


export function resetPwdEmail(email: string) {
    return (dispatch: Dispatch<any>) => {
        dispatch(extResetPwdstart(email));
        return VendorAuthServiceApi.auth(false).resetPwdEmail(email)
            .then((response: any) => {
                dispatch(editSuccess(response.httpStatusCode));
                return response;
            }, (_error: any) => {
                console.log(_error);
                dispatch(editSuccess(_error.code));
            });

    };
}

export const editSuccess = (responseMessage: number) => {
    return (dispatch: Dispatch<EditSuccess>) => {
        dispatch({
            payload: {
                httpStatusCode: responseMessage
            },
            type: UserActionTypes.RESET_PASSWORD_SUCCESS
        });
    };
};
const extResetPwdstart = (_username?: string): ExtResetPwdStart => {
    return {
        type: UserActionTypes.RESET_PASSWORD_START,
    };
};
interface EditSuccess {
    payload: {
        httpStatusCode: number;
    },
    type: UserActionTypes.RESET_PASSWORD_SUCCESS
}

export const signOut = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch(extSignOutStart());
        dispatch(extIsAuthenticatedSet(false));
        setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
        }, 110);
    };
};

export const getOrg = () => {
    return (_dispatch: Dispatch<any>) => {
        GraphApi.auth(true).getOrganization()
            .then((result) => {
                console.log(result);
            })
            .catch((apiFailReason) => {
                appInsights.trackException({
                    error: apiFailReason,
                });
                console.error(apiFailReason);
            });
    };
};

export const acquireToken = () => {
    return new Promise<string>(
        (resolve) => {
            const token: string = store.getState().externalUser.rawToken ?? '';
            resolve(token);
        },
    );
};
