/* eslint-disable no-prototype-builtins */
import { Dispatch } from 'redux';
import * as Msal from 'msal';
import { IdToken } from 'msal/lib-commonjs/IdToken';
import { StringDict } from 'msal/lib-commonjs/MsalTypes';
import { AuthActionTypes } from './types';
import { TokenHelper, UrlHelper } from '../../helpers';
import { ApplicationState, store } from '..';
import { appInsights } from '../..';
import { GraphApi } from '../../api';
import Constants from '../../Constants';
import routePaths from '../../shared/constants/routePaths';
import ApiError from '../../shared/models/ApiError';
import { UserTypes } from '../userState';

let msalInstance: Msal.UserAgentApplication;

export type AuthAction = SignInStart | SignInSuccess | SignInFailure | SignOutStart
    | AuthorizationSuccess | AuthorizationFailure | InitializeStart | InitializeComplete
    | SessionValidated | SessionInvalidated | UserNameSet | UserNameReset | UserTypeSet | UserTypeReset
    | RequestedPageSet | RequestedPageReset | IsAuthenticatedSet | IsAuthenticatedReset;

const initializeStart = (): InitializeStart => {
    return {
        type: AuthActionTypes.INITIALIZE_START,
    };
};

interface InitializeStart {
    type: AuthActionTypes.INITIALIZE_START;
}

interface InitializeComplete {
    type: AuthActionTypes.INITIALIZE_COMPLETE;
}

interface SignInStart {
    type: AuthActionTypes.SIGNIN_START;
}

interface SignInSuccess {
    payload: {
        idToken?: any;
        accessToken?: any;
    };
    type: AuthActionTypes.SIGNIN_SUCCESS;
}

interface SignInFailure {
    error: ApiError;
    type: AuthActionTypes.SIGNIN_FAIL;
}

interface AuthorizationSuccess {
    payload: {
        accessToken?: any;
    };
    type: AuthActionTypes.AUTHORIZE_SUCCESS;
}

interface AuthorizationFailure {
    error: any;
    type: AuthActionTypes.AUTHORIZE_FAILURE;
}

interface SignOutStart {
    type: AuthActionTypes.SIGNOUT_START;
}

interface SessionValidated {
    idToken: StringDict;
    type: AuthActionTypes.SESSION_UPDATE;
}

interface SessionInvalidated {
    type: AuthActionTypes.SESSION_INVALIDATE;
}

interface UserNameSet {
    payload: {
        userName: string;
    };
    type: AuthActionTypes.NAME_SET;
}

interface UserNameReset {
    type: AuthActionTypes.NAME_RESET;
}

interface UserTypeSet {
    type: AuthActionTypes.TYPE_SET;
    payload: {
        userType: UserTypes | undefined;
    };
}

interface UserTypeReset {
    type: AuthActionTypes.TYPE_RESET;
}

interface RequestedPageSet {
    type: AuthActionTypes.REQUESTED_PAGESET;
    payload: {
        requestedPage: string;
    };
}

interface RequestedPageReset {
    type: AuthActionTypes.REQUESTED_PAGERESET;
}

interface IsAuthenticatedSet {
    type: AuthActionTypes.ISAUTHENTICATED_SET;
    payload: {
        isAuthenticated: boolean;
    };
}

interface IsAuthenticatedReset {
    type: AuthActionTypes.ISAUTHENTICATED_RESET;
}


const userNameSet = (userName: string): UserNameSet => {
    return {
        payload: {
            userName: userName,
        },
        type: AuthActionTypes.NAME_SET
    };
};

const userNameReset = (): UserNameReset => {
    return {
        type: AuthActionTypes.NAME_RESET
    };
};

const userTypeSet = (userType: UserTypes): UserTypeSet => {
    return {
        payload: {
            userType: userType,
        },
        type: AuthActionTypes.TYPE_SET
    };
};

const isAuthenticatedSet = (isAuthenticated: boolean): IsAuthenticatedSet => {
    return {
        payload: {
            isAuthenticated: isAuthenticated,
        },
        type: AuthActionTypes.ISAUTHENTICATED_SET
    };
};

const isAuthenticatedReset = (): IsAuthenticatedReset => {
    return {
        type: AuthActionTypes.ISAUTHENTICATED_RESET
    };
};

const initializeComplete = (): InitializeComplete => {
    return {
        type: AuthActionTypes.INITIALIZE_COMPLETE,
    };
};

const signInStart = (_username?: string): SignInStart => {
    return {
        type: AuthActionTypes.SIGNIN_START,
    };
};

const signInSuccess = (token: string | IdToken | StringDict): SignInSuccess => {
    return {
        payload: {
            idToken: token,
        },
        type: AuthActionTypes.SIGNIN_SUCCESS,
    };
};

const signInFailure = (error?: any): SignInFailure => {
    return {
        error,
        type: AuthActionTypes.SIGNIN_FAIL,
    };
};

const signOutStart = () => {
    return {
        type: AuthActionTypes.SIGNOUT_START,
    };
};

const authorizationSuccess = (response: Msal.AuthResponse): AuthorizationSuccess => {
    return {
        payload: {
            accessToken: response.accessToken
        },
        type: AuthActionTypes.AUTHORIZE_SUCCESS,
    };
};

const authority = 'https://login.microsoftonline.com/2d3eb0f7-88b2-45e2-8569-2516fcce5e83';

const handleTokenReceived = (response: Msal.AuthResponse) => {
    if (store) {
        if (response.tokenType === 'id_token' && response.idToken) {
            store.dispatch(signInSuccess(response.idToken.claims));
            store.dispatch(userTypeSet(UserTypes.internal));
            store.dispatch(userNameSet(response?.account?.userName));
            store.dispatch(isAuthenticatedSet(true));
        } else if (response.tokenType === 'access_token' && response.accessToken) {
            store.dispatch(authorizationSuccess(response));
        }
    }
};

const handleTokenError = (reason: any) => {
    if (store) {
        store.dispatch(signInFailure(reason));
        store.dispatch(userTypeSet(UserTypes.internal));
        store.dispatch(userNameReset());
        store.dispatch(isAuthenticatedReset());
    }

    signIn(undefined, false);
};

const handleAuthenticationError = (error: Msal.AuthError, accountState: string | null) => {
    console.error(error.errorCode + ':' + error.errorMessage, accountState);
    store.dispatch(userNameReset());
    store.dispatch(isAuthenticatedReset());
    if (error.errorCode.indexOf('invalid_client:AADSTS650053') !== -1) {
        console.error('Invalid client error encountered.');
        throw error;
    }
};

const signInSilently = (account?: Msal.Account) => {
    const clientId = msalInstance.getCurrentConfiguration().auth.clientId;
    const signInRequest: Msal.AuthenticationParameters = {
        scopes: [clientId],
        authority,
        account,
        sid: (account) && account.sid,
        loginHint: (account) && account.userName,
    };
    msalInstance.acquireTokenSilent(signInRequest).then(handleTokenReceived).catch(handleTokenError);
};

export const initMsalAgent = (clientId: string) => {
    const redirectUri = UrlHelper.getAbsoluteUrlForPath(routePaths.signInCallback);

    return (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
        if (msalInstance === undefined || msalInstance === null) {
            dispatch(initializeStart());
            const msalConfig: Msal.Configuration = {
                auth: {
                    authority,
                    clientId,
                    redirectUri,
                    navigateToLoginRequestUrl: false,
                    validateAuthority: false,
                },
                cache: {
                    cacheLocation: 'sessionStorage',
                },
            };

            msalInstance = new Msal.UserAgentApplication(msalConfig);
            msalInstance.handleRedirectCallback(handleTokenReceived, handleAuthenticationError);
        }
        dispatch(initializeComplete());

        const isCallback = msalInstance.isCallback(window.location.hash);
        const authenticating = getState().internalUser.authenticating;
        const currentAccount = msalInstance.getAccount();
        if (isCallback && !authenticating) {
            if (currentAccount) {
                dispatch(signInStart(currentAccount.userName));
            } else {
                dispatch(signInStart());
            }
        }

        if (currentAccount && currentAccount.idToken && !isCallback) {
            if (TokenHelper.isAuthenticated(currentAccount.idToken)) {
                dispatch(signInSuccess(currentAccount.idToken));
            } else {
                signInSilently(currentAccount);
            }
        } else if (currentAccount === null) {
            dispatch(signInFailure(null));
        }
    };
};

/**
 * Logs user into the app
 */
export function signIn(username?: string, silent?: boolean) {
    return (dispatch: Dispatch<any>) => {
        // const { authenticating } = getState().internalUser;
        if (msalInstance === undefined || msalInstance === null) {
            console.error('msal is not defined');
            dispatch(initializeStart());
            return;
        }

        const isCallback = msalInstance.isCallback(window.location.hash);
        if (isCallback) {
            console.log('sign-in cancelled due to in-progress sign-in');
            return;
        }

        const extraQueryParameters: StringDict = {};
        if (username) {
            if (username === '___switch___') {
                // tslint:disable-next-line: no-string-literal
                extraQueryParameters['prompt'] = 'select_account';
            } else {
                // tslint:disable-next-line: no-string-literal
                extraQueryParameters['login_hint'] = username;
            }
        }

        const loginRequest: Msal.AuthenticationParameters = {
            extraQueryParameters,
            scopes: ['user.read'],
        };

        let postSignInRedirectUrl: string;
        const qPs = UrlHelper.getQueryParams();
        if (qPs.hasOwnProperty('redirect_uri')) {
            postSignInRedirectUrl = qPs.redirect_uri as string;
        } else {
            postSignInRedirectUrl = window.location.protocol + '//' + window.location.host + routePaths.signInCallback;
        }
        sessionStorage.setItem(Constants.postSignInRedirectUrl, postSignInRedirectUrl);

        dispatch(signInStart(username));
        setTimeout(() => {
            if (silent) {
                const account = msalInstance.getAccount();
                const clientId = msalInstance.getCurrentConfiguration().auth.clientId;
                const signInRequest: Msal.AuthenticationParameters = {
                    scopes: [clientId],
                    authority,
                    account,
                    loginHint: username,
                };
                msalInstance.acquireTokenSilent(signInRequest).then((response) => {
                    handleTokenReceived(response);
                }).catch((error: Msal.AuthError) => {
                    if (error instanceof Msal.InteractionRequiredAuthError) {
                        msalInstance.loginRedirect(signInRequest);
                    } else {
                        handleAuthenticationError(error, null);
                        dispatch(signInFailure(error));
                    }
                });
            } else {
                msalInstance.loginRedirect(loginRequest);
            }
        }, 210);
    };
}

export const switchAccount = () => signIn('___switch___', false);

export const signOut = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch(signOutStart());
        setTimeout(() => {
            msalInstance.logout();
        }, 110);
    };
};

const updateSessionProps = (idToken: StringDict) => {
    return {
        idToken,
        type: AuthActionTypes.SESSION_UPDATE,
    };
};

const invalidateSession = () => {
    return {
        type: AuthActionTypes.SESSION_INVALIDATE,
    };
};

export const validateSession = () => {
    return (dispatch: Dispatch<any>) => {
        if (msalInstance === undefined) {
            dispatch(initializeStart());
        }
        const msalAccount = msalInstance.getAccount();
        if (msalAccount) {
            dispatch(updateSessionProps(msalAccount.idToken));
        } else {
            dispatch(invalidateSession);
        }
    };
};

export const getOrg = () => {
    return (_dispatch: Dispatch<any>) => {
        GraphApi.auth(true).getOrganization()
            .then((result) => {
                console.log(result);
            })
            .catch((apiFailReason) => {
                appInsights.trackException({
                    error: apiFailReason,
                });
                console.error(apiFailReason);
            });
    };
};

export const acquireToken = (scopes: string[]) => {
    return new Promise<string>(
        (resolve, reject) => {
            if (msalInstance === undefined || msalInstance === null) {
                reject({
                    code: 500,
                    name: 'msal_undefined',
                    description: 'msalAgent is null or not defined',
                    message: 'Well this is embarrassing. Please contact support for assistance.',
                } as ApiError);
                return;
            }

            const account = msalInstance.getAccount();
            const tokenRequest: Msal.AuthenticationParameters = {
                account,
                scopes,
            };

            msalInstance.acquireTokenSilent(tokenRequest)
                .then((response) => {
                    handleTokenReceived(response);
                    resolve(response?.idToken?.rawIdToken);
                })
                .catch((error) => {
                    if (error instanceof Msal.InteractionRequiredAuthError) {
                        return msalInstance.acquireTokenRedirect(tokenRequest);
                    } else {
                        reject(error);
                    }
                });
        },
    );
};

export const requestedPageSet = (requestedPage: string) => {
    return (dispatch: Dispatch<RequestedPageSet>) => {
        dispatch({
            payload: {
                requestedPage: requestedPage,
            },
            type: AuthActionTypes.REQUESTED_PAGESET
        });
    };
};

export const requestedPageReset = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: AuthActionTypes.REQUESTED_PAGERESET
        });
    };
};
