/* eslint-disable no-undef */
import React, { Component } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route, Routes,
} from 'react-router-dom';

import routePaths from './shared/constants/routePaths';
import LandingPage from './components/MainLanding/landingPage';
import TransportationVendorContainer from './components/vendorContainer/transportationVendorContainer/transportationVendorContainer';
import ManufacturingVendorContainer from './components/vendorContainer/manufacturingVendorContainer/manufacturingVendorContainer';
import ProcurementContainer from './components/procurementContainer/procurementContainer';
import VendorSignIn from './components/VendorSignIn/VendorSignIn';
import TransportationContainer from './components/transportationContainer/transportationContainer';
import Dashboard from './components/dashboardContainer/Dashboard';
import { ApplicationState } from './store';
import { connect } from 'react-redux';
import { Header } from './components/layout';
import DashboardHeader from './components/layout/dashboard-layout/dashboardHeader';
import { SideBar } from './components/layout/sidebar/SideBar';
import AccessPopup from './components/AccessPopup/AccessPopup';
import { UserHelper } from './helpers';
import AdminContainerComponent from './components/admin/admin-container/AdminContainer';
import AdminVendorContainer from './components/admin/VendorContainer/VendorContainer';
import AdminStationContainer from './components/admin/StationContainer/StationContainer';
import PrivateRoute from './components/login/useTokenExpiration';
import { AuthActions } from './store/auth';
import { ExternalUserActions } from './store/externalUser';
import ResetPassword from './components/VendorSignIn/ResetPassword';
import ChangePassword from './components/VendorSignIn/ChangePassword';
import ResetPwdEmail from './components/VendorSignIn/resetPwdEmail';
import VendorUserContainer from './components/admin/VendorUserContainer/UserContainer';
import AdminTemplateContainer from './components/admin/TemplateContainer/TemplateContainer';
import AdminDecalContainer from './components/admin/decalContainer/decalContainer';

interface ComponentProps {
  idToken?: any;
  applicationState?: ApplicationState;
  isDashboardLayout?: boolean;
}

type LayoutProps =
  ComponentProps
  & typeof mapDispatchToProps;

class AppRoutes extends Component<React.PropsWithChildren<LayoutProps>> {

  renderDashboardLayoutPage(element: JSX.Element, username: string | undefined): JSX.Element {
    const { applicationState } = this.props;
    return (
      <React.Fragment>
        <DashboardHeader username={username} />
        <div className="row mx-0 d-flex justify-content-center">
          <div className='mt-2' style={{ width: '4%' }}>
              <SideBar applicationState={applicationState} />
            </div>
          <div className='mt-1' style={{ width: '96%' }}>
              {element}
            </div>
        </div>
      </React.Fragment>
    );
  }

  public renderLayout(element: JSX.Element, userName: string | undefined) {
    const { isDashboardLayout } = this.props;

    if (isDashboardLayout) {
      return (
        <React.Fragment>
          <PrivateRoute>
            {this.renderDashboardLayoutPage(element, userName)}
          </PrivateRoute>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Header />
        {element}
      </React.Fragment>
    );
  }

  renderSign() : JSX.Element {
    return (
      <React.Fragment>
        <Header />
        <LandingPage />
      </React.Fragment>
    );
  }

  renderUnAuthorized(): JSX.Element {
    const { applicationState, internalSignOut, externalSignOut } = this.props;
    return (
      <React.Fragment>
        <Header />
        <AccessPopup isInternalUserAuthenticated={applicationState?.internalUser.isAuthenticated}
          internalSignOut={internalSignOut} externalSignOut={externalSignOut} />
      </React.Fragment>
    );
  }
  
  renderVendorSign() : JSX.Element {
    return (
      <React.Fragment>
        <Header />
        <VendorSignIn />
      </React.Fragment>
    );
  }

  renderResetPassword() : JSX.Element {
    return (
      <React.Fragment>
        <Header />
        <ResetPassword params={ window.location.search.substring(1) }/>
      </React.Fragment>
    );
  }
  renderChangePassword() : JSX.Element {
    return (
      <React.Fragment>
        <Header />
        <ChangePassword params={ window.location.search.substring(1) }/>
      </React.Fragment>
    );
  }
  renderResetPwdEmail() : JSX.Element {
    return (
      <React.Fragment>
        <Header />
        <ResetPwdEmail/>
      </React.Fragment>
    );
  }

  redirectInternalUserPageBasedAuth(element: JSX.Element, pageName: string) : JSX.Element {
    const { applicationState } = this.props;
    if(applicationState && UserHelper.isInternalUserValid(applicationState) 
          && UserHelper.isInternalUserAuthorized(applicationState, pageName)) {
      return this.renderLayout(element, applicationState?.internalUser?.userName);
    } else {
      return <Navigate to={routePaths.root} />;
    }
  }

  redirectExternalUserPageBasedAuth(element: JSX.Element, pageName: string) : JSX.Element {
    const { applicationState } = this.props;
    if(applicationState && UserHelper.isInternalUserValid(applicationState) 
          && UserHelper.isInternalUserAuthorized(applicationState, pageName)) {
        return this.renderLayout(element, applicationState?.internalUser?.userName);
    } else if(applicationState && UserHelper.isExternalUserValid(applicationState)  
          && UserHelper.isExternalUserAuthorized(applicationState, pageName)) {
        return this.renderLayout(element, applicationState?.externalUser?.userName);
    } else {
        return <Navigate to={routePaths.root} />;
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={routePaths.root} element={this.renderSign()} />
          <Route path={routePaths.signIn} element={this.renderSign()} />
          <Route path={routePaths.signInCallback} element={<Navigate to={routePaths.signIn} />} />
          <Route path={routePaths.procurementDashboard} element={this.redirectInternalUserPageBasedAuth(<ProcurementContainer />, routePaths.procurementDashboard)} />
          <Route path={routePaths.internalTransportationDashboard} element={this.redirectInternalUserPageBasedAuth(<TransportationContainer />, routePaths.internalTransportationDashboard)} />
          <Route path={routePaths.manufacturingVendorDashboard} element={this.redirectExternalUserPageBasedAuth(<ManufacturingVendorContainer />, routePaths.manufacturingVendorDashboard)} />
          <Route path={routePaths.transportationVendorDashboard} element={this.redirectExternalUserPageBasedAuth(<TransportationVendorContainer />, routePaths.transportationVendorDashboard)} />
          <Route path={routePaths.dashboard} element={this.redirectInternalUserPageBasedAuth(<Dashboard />, routePaths.dashboard)} />
          <Route path={routePaths.VendorSignIn} element={this.renderVendorSign()} />
          <Route path={routePaths.ResetPassword} element={this.renderResetPassword()} />
          <Route path={routePaths.ChangePassword} element={this.renderChangePassword()} />
          <Route path={routePaths.resetPwdPassword} element={this.renderResetPwdEmail()} />
          <Route path={routePaths.adminVendor} element={this.redirectInternalUserPageBasedAuth(<AdminVendorContainer />, routePaths.adminVendor)} />
          <Route path={routePaths.adminStationContact} element={this.redirectInternalUserPageBasedAuth(<AdminStationContainer />, routePaths.adminStationContact)} />
          <Route path={routePaths.adminVendorUser} element={this.redirectInternalUserPageBasedAuth(<VendorUserContainer />, routePaths.adminVendorUser)} />
          <Route path={routePaths.admin} element={this.redirectInternalUserPageBasedAuth(<AdminContainerComponent />, routePaths.admin)} />
          <Route path={routePaths.unAuthorized} element={this.renderUnAuthorized()} />
          <Route path={routePaths.adminTemplate} element={this.redirectInternalUserPageBasedAuth(<AdminTemplateContainer />, routePaths.adminTemplate)} />
          <Route path={routePaths.adminDecal} element={this.redirectInternalUserPageBasedAuth(<AdminDecalContainer />, routePaths.adminDecal)} />
        
        </Routes>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: ComponentProps) => {
  const { idToken } = state.internalUser;

  return {
    ...ownProps,
    idToken,
    applicationState: state,
    isDashboardLayout: state?.flags?.isDashboardLayout
  };
};

const mapDispatchToProps = {
  internalSignOut: AuthActions.signOut,
  externalSignOut: ExternalUserActions.signOut
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes as any);