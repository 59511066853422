import React, { Component } from 'react';
import './dashboardHeader.scss';
import { Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import routePaths from '../../../shared/constants/routePaths';
import { AuthActions } from '../../../store/auth';
import { ExternalUserActions } from '../../../store/externalUser';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';

interface ComponentProps {
    username?: string;
    state?: ApplicationState;
}

type DashboardLayoutProps =
    ComponentProps
    & typeof mapDispatchToProps;

class DashboardHeader extends Component<DashboardLayoutProps> {
    appLogo = require('../../../assets/Horizontal- black background.png');
    appIcon = require('../../../assets/GSE Tracker Icons/Notification-0.png');    

    async componentDidMount() {
        this.update();
        window.addEventListener('resize', this.update.bind(this));
    }
    
    private update = () => {
        this.setState({ userScreenWidth: window.innerWidth });
    };

    onSignOut = () => {
        if(this.props.state?.internalUser.isAuthenticated) {
            this.props.internalSignOut();
            this.props.externalSignOut();
        }
        if(this.props.state?.externalUser.isAuthenticated) {
            this.props.externalSignOut();
        }
    };

    render() {
        const { username } = this.props;
        return (
            <>
                <div className="dashboard-app-bar">
                    <NavLink className="appLogoContainer col-2" to={routePaths.root}>
                        <img className="appLogo" alt="Unifi Icon" src={this.appLogo} />
                    </NavLink>
                    <div className="dashboard-appTitle col-3">
                        <NavLink className="dashboard-appTitle" to={routePaths.root}>
                            <span className="dashboard-appTitle">GSE Tracker APP</span>
                        </NavLink>
                    </div>                
                    {username && (
                    <>
                        <Dropdown className="user-name-section col-7">
                            <img className="appIcon" alt='appIcon' src={this.appIcon} /> 
                            <Dropdown.Toggle variant="">
                            <div>                                                      
                                <span className="user-name">Hi, {username}</span>
                            </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={this.onSignOut}>
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>                       
                    </>
                    )}
                </div>                   
             </>
           
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): DashboardLayoutProps => {

    return {
        ...state,
        state: state,
        ...ownProps,
    };
};

const mapDispatchToProps = {
    internalSignOut: AuthActions.signOut,
    externalSignOut: ExternalUserActions.signOut
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
