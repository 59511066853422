class StringHelper {
    public static isWhitespace(value: string) {
        if (value === undefined || value === null || value.length === 0) {
            return false;
        }

        let pos = 0;
        while (pos < value.length) {
            const char = value[pos];
            if (char !== ' ') {
                return false;
            }
            pos++;
        }

        return true;
    }

    public static compare(a: string, b: string): number {
        if (a > b) {
            return 1;
        } else if (a < b) {
            return -1;
        } else {
            return 0;
        }
    }

    /**
     * Indicates whether a specified string is null, empty, or consists only of white-space characters.
     *
     * @static
     * @param {(string | null)} value The string to test.
     * @returns {boolean} true if the value parameter is null, empty, or if value consists exclusively of white-space characters.
     * @memberof StringHelper
     */
    public static isNullOrWhitespace(value: string | null): boolean {
        if (value === undefined || value === null || value.length === 0) {
            return true;
        }

        for (const ch of value) {
            if (!StringHelper.isWhitespace(ch)) {
                return false;
            }
        }

        return true;
    }

    public static isEmailAddress(input: string) {
        const pattern = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,})+$/;
        if (pattern.test(input)) {
            return true;
        }

        return false;
    }

    public static stripLeadingSlash(str: string) {
        if (str.substr(0) === '/') {
            return str.substr(1, str.length);
        }

        return str;
    }

    public static stripTrailingSlash(str: string) {
        if (str.substr(-1) === '/') {
            return str.substr(0, str.length - 1);
        }

        return str;
    }

    public static toCamelCase(input: string) {
        return input.split(' ').map((word, index) => {
            // If it is the first word make sure to lowercase all the chars.
            if (index === 0) {
                return word.toLowerCase();
            }

            // If it is not the first word only upper case the first char and lowercase the rest.
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join('');
      }
}

export default StringHelper;
