import StringHelper from './StringHelper';

class UrlHelper {
	public static getAbsoluteUrlForPath(path: string) {
		return UrlHelper.buildUrlFromComponents(path, window.location.host, window.location.protocol);
	}

	public static buildUrlFromComponents(path?: string, host?: string, protocol?: string): string {
		protocol = protocol || window.location.protocol;
		host = host || window.location.host;
		path = path?.replace(/^\/+/g, '');

		const url = `${protocol}//${host}/${path}`;
		return url;
	}

	public static buildUrl(baseUrl: string, paths?: string[], queryString?: string): string {
		let url = StringHelper.stripTrailingSlash(baseUrl);

		paths?.forEach((path) => {
			let scrubbedPath = StringHelper.stripLeadingSlash(path);
			scrubbedPath = StringHelper.stripTrailingSlash(scrubbedPath);

			url += '/' + scrubbedPath;
		});

		if (queryString) {
			url += (queryString?.substring(0, 1) === '?') ? queryString : ('?' + queryString);
		}

		return url;
	}

	public static getQueryParams(): Record<string, string | number | boolean | null> {
		const dict: { [key: string]: string | number | boolean | null } = {};
		try {
			const url = window.location.href;
			const qStringPosIndex = url.indexOf('?');

			if (qStringPosIndex !== -1) {
				const queryString = url.substr(qStringPosIndex + 1, url.length - 1);
				const keyValuePairs = queryString.split('&');
				for (const key in keyValuePairs) {
					if (Object.prototype.hasOwnProperty.call(keyValuePairs, key)) {
						const keyValuePair = keyValuePairs[key].split('=');
						dict[keyValuePair[0]] = keyValuePair[1];
					}
				}
			}

			return dict;
		} catch (e) {
			return {};
		}
	}
}

export default UrlHelper;
