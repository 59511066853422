/* eslint-disable no-unused-vars */
export enum ColumnTypeEnum {
  date,
  dropdowns,
  text,
  currency,
  number,
  edit,
  boolean,
  link,
  file,
  shippingStatus,
  tag,
  delete,
  dimensions,
  inputSwitch,
  view,
  textArea,
  lookupOptions,
  staticDropdown,
  additionalContact,
  dynamicDropdown
}