import { appSettings } from '..';
import { ActivationRequest, ActivationResponse, ResetPwdRequest, UserAuthRequest, UserAuthResponse } from '../shared/models/user-auth-response';
import BaseApiClient from './BaseApi';

export class VendorAuthServiceApi extends BaseApiClient {

    public static scopes: string[] = [
        'User.Read',
    ];

    public static auth(enabled?: boolean) {
        const authenticated = (enabled === undefined) ? true : enabled;
        return new this(authenticated);
    }

    protected constructor(authenticated?: boolean) {
        const { gseTrackerApi } = appSettings;
        super(gseTrackerApi.host + '/vendor', authenticated);
    }

    public vendorLoginAuth(request: UserAuthRequest): Promise<UserAuthResponse> {
        return this._post<UserAuthResponse>(this.baseUrl + '/authenticate', request, VendorAuthServiceApi.scopes);
    }

    public authenticateUser(request: ActivationRequest, id: string, activate: string): Promise<ActivationResponse> {
        console.log(this.baseUrl);
        return this._post<ActivationResponse>(this.baseUrl + 'user/' + id + '/activate/' + activate, request, VendorAuthServiceApi.scopes);
    }

    public resetPassword(request: ResetPwdRequest): Promise<ActivationResponse> {
        console.log(this.baseUrl);
        return this._post<ActivationResponse>(this.baseUrl + 'user/resetpwd', request, VendorAuthServiceApi.scopes);
    }

    public resetPwdEmail(email: string): Promise<ActivationResponse> {
        console.log(this.baseUrl);
        return this._post<ActivationResponse>(this.baseUrl + 'user/SendResetPasswordLink/' + email, '', VendorAuthServiceApi.scopes);
    }
}