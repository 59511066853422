/* eslint-disable no-prototype-builtins */
import JwtDecode from 'jwt-decode';
import initialState from '../initialState';
import { ExternalUserState } from '.';
import { UserAction } from './actions';
import { UserActionTypes } from '../userState';
import { UserHelper } from '../../helpers';

export const UserReducer = (state = initialState.externalUser, action: UserAction): ExternalUserState => {
    switch (action.type) {
        case UserActionTypes.SIGNIN_START: {
            return {
                ...state,
                authenticating: true,
            };
        }
        case UserActionTypes.SIGNIN_SUCCESS: {
            return {
                ...state,
                authenticating: false,
            };
        }
        case UserActionTypes.SIGNIN_FAIL: {
            return {
                ...state,
                authenticating: false,
            };
        }
        case UserActionTypes.AUTHORIZE_SUCCESS: {
            const { payload } = action;
            const accessTokens = state.accessTokens || {};
            let decodedToken: any;

            let roles = state.roles || [];
            if (payload.accessToken) {
                decodedToken = JwtDecode(payload.accessToken) as any;
                if (decodedToken && decodedToken.hasOwnProperty('userId')) {
                    accessTokens[decodedToken.userId] = payload.accessToken;
                    if (decodedToken.hasOwnProperty('roles') && decodedToken.roles) {
                        roles = decodedToken.roles.split(',');
                        roles = roles ? UserHelper.rolesSort(roles) : [];
                    }
                }
            }

            return {
                ...state,
                accessTokens,
                roles,
                idToken: decodedToken,
                userName: decodedToken.emailAddress,
                rawToken: payload.accessToken
            };
        }
        case UserActionTypes.SIGNOUT_START: {
            return {
                ...state,
                authenticating: false,
                accessTokens: undefined,
            };
        }
        case UserActionTypes.SESSION_UPDATE: {
            return {
                ...state,
            };
        }
        case UserActionTypes.SESSION_INVALIDATE: {
            return {
                ...state,
                accessTokens: undefined,
            };
        }
        case UserActionTypes.EXT_NAME_SET: {
            const { payload } = action;
            return {
                ...state,
                userName: payload.userName
            };
        }
        case UserActionTypes.EXT_NAME_RESET: {
            return {
                ...state,
                userName: undefined
            };
        }
        case UserActionTypes.EXT_TYPE_SET: {
            const { payload } = action;
            return {
                ...state,
                userType: payload.userType
            };
        }
        case UserActionTypes.EXT_TYPE_RESET: {
            return {
                ...state,
                userType: undefined
            };
        }
        case UserActionTypes.EXT_ISAUTHENTICATED_SET: {
            const { payload } = action;
            return {
                ...state,
                isAuthenticated: payload.isAuthenticated
            };
        }
        case UserActionTypes.EXT_ISAUTHENTICATED_RESET: {
            return {
                ...state,
                isAuthenticated: false
            };
        }
        default:
            return state;
    }
};
