import React, { useState } from 'react';
import './AccessPopup.scss';
import GSEDisplayTextConst from '../../shared/constants/gseDisplayTextConst';
import routePaths from '../../shared/constants/routePaths';
import { Link, Navigate } from 'react-router-dom';
import { Button } from 'primereact/button';

interface ComponentProps {
    isInternalUserAuthenticated?: boolean;
    internalSignOut: () => void;
    externalSignOut: () => void;
}

export default function AccessPopup({ isInternalUserAuthenticated, internalSignOut, externalSignOut }: ComponentProps) {
    const [shouldRedirectToRoot, setShouldRedirectToRoot] = useState(false);
    const onSignOut = () => {
        if (isInternalUserAuthenticated) {
            internalSignOut();
        }
        externalSignOut();
        setShouldRedirectToRoot(true);
    };

    const redirectToRoot = () => {
        if (shouldRedirectToRoot) {
            return <Navigate to={routePaths.root} />;
        }
        return undefined;
    };

    const footerContent = (
        <React.Fragment>
            <div className='flex justify-content-center'>
                <Link className="col-12 pt-1 button-option shadow-lg rounded" autoFocus to={routePaths.root} >{GSEDisplayTextConst.accessPopup.goToHomeText}</Link>
            </div>
            <div className='flex justify-content-center'>
                <Button className="col-12 button-option pt-3" text onClick={onSignOut}>Logout</Button>
            </div>
        </React.Fragment>
    );

    return (
        <div className="row justify-content-center access-popup">
            {redirectToRoot()}
            <div className='col-12 card rounded p-5 p-sm-4 text-center'>
                <p className="m-0 text-center">
                    You don’t have permissions to access this team.
                    <br /><br />Contact an administrator to get permissions or go to the home page and browse other teams.
                    <br /><br /></p>
                {footerContent}
            </div>
        </div>
    );
}
