import { Dispatch } from 'react';
import { DashboardServiceApi } from '../../api/DashboardServiceApi';
import { DashboardRequest, DashboardResponse, Filters } from '../../shared/models/dashboard';
import { DashboardPOActionTypes } from './types';
import { DataTableFilterMeta } from 'primereact/datatable';
import { ColumnTypeEnum } from '../../shared/models/ColumnTypeEnum';
import { DateHelper, UserHelper } from '../../helpers';
import routePaths from '../../shared/constants/routePaths';
import { store } from '..';
import { TagTypeEnum } from '../../shared/models/Enums/TagTypeEnum';
import DashboardHelper from '../../helpers/DashboardHelper';
import { AdminCRUDTypeEnum } from '../../shared/models/Enums/AdminCRUDTypeEnum';

export type DashboardAction = DashboardPOSuccess | IsFetchingDashboardPO | SetDashboardRequest | SetFilterMetaData
    | ResetDashboardData | IsFetchingDropdownOptions | SpecificFieldDataSuccess
    | IsDownloadLoading | UploadSuccess | ResetUploadApiMessage | ResetDownloadDashboardData | IsUploadLoading | EditSuccess | IsEditLoading
    | IsFetchingPOTile | POTileViewSuccess | ResetPOContractTiledData | ResetUpdateApiMessage
    | ResetDropdownOptionsBasedOnFilters;

interface DashboardPOSuccess {
    payload: {
        request?: DashboardRequest;
        response?: DashboardResponse;
    };
    type: DashboardPOActionTypes.DASHBOARD_PO_SUCCESS;
}

interface POTileViewSuccess {
    payload: {
        request?: DashboardRequest;
        response?: DashboardResponse;
    };
    type: DashboardPOActionTypes.PO_TILE_SUCCESS;
}

interface IsFetchingPOTile {
    payload: {
        isFetching: boolean
    };
    type: DashboardPOActionTypes.PO_TILE_LOADING;
}

interface IsFetchingDashboardPO {
    payload: {
        isFetching: boolean
    };
    type: DashboardPOActionTypes.DASHBOARD_PO_LOADING;
}

interface IsDownloadLoading {
    payload: {
        isFetching: boolean
    };
    type: DashboardPOActionTypes.DASHBOARD_DOWNLOAD_LOADING;
}

interface IsUploadLoading {
    payload: {
        isFetching: boolean
    };
    type: DashboardPOActionTypes.DASHBOARD_UPLOAD_LOADING;
}

interface IsEditLoading {
    payload: {
        isFetching: boolean
    };
    type: DashboardPOActionTypes.DASHBOARD_EDIT_LOADING;
}


interface IsFetchingDropdownOptions {
    payload: {
        isFetchingDropdownOptions: boolean
    };
    type: DashboardPOActionTypes.DASHBOARD_DROPDOWN_LOADING;
}

interface SetDashboardRequest {
    payload: {
        request?: DashboardRequest;
    };
    type: DashboardPOActionTypes.SET_DASHBOARD_REQUEST;
}

interface SetFilterMetaData {
    payload: {
        request?: DataTableFilterMeta;
    };
    type: DashboardPOActionTypes.SET_FILTER_METADATA;
}

interface ResetDashboardData {
    type: DashboardPOActionTypes.RESET_DASHBOARD_DATA;
}

interface SpecificFieldDataSuccess {
    payload: {
        columnName: string,
        DropdownOptions?: string[] | number[],
    },
    type: DashboardPOActionTypes.DASHBOARD_DROPDOWN_SUCCESS
}

interface UploadSuccess {
    payload: {
        uploadAPIMessage: string;
    },
    type: DashboardPOActionTypes.DASHBOARD_UPLOAD_SUCCESS
}
interface EditSuccess {
    payload: {
        editAPIMessage: string;
    },
    type: DashboardPOActionTypes.DASHBOARD_EDIT_SUCCESS
}

interface ResetUploadApiMessage {
    type: DashboardPOActionTypes.RESET_UPLOAD_API_MESSAGE
}

interface ResetDownloadDashboardData {
    type: DashboardPOActionTypes.RESET_DOWNLOAD_DASHBOARD_DATA
}

interface ResetPOContractTiledData {
    type: DashboardPOActionTypes.RESET_PO_CONTRACT_TILE_DATA
}

interface ResetUpdateApiMessage {
    type: DashboardPOActionTypes.RESET_UPDATE_API_MESSAGE
}

interface ResetDropdownOptionsBasedOnFilters {
    payload: {
        filters: Filters[] | undefined;
    },
    type: DashboardPOActionTypes.RESET_DROPDOWN_WITH_FILTER
}

/**
 * Logs user into the app
 */
export function getDashboardPO(dashboardRequest: DashboardRequest) {
    return (dispatch: Dispatch<any>) => {
        setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, true);
        dispatch(resetDropdownOptionsBasedOnFilters(dashboardRequest.filters));
        DashboardServiceApi.auth().getDashboardPO(dashboardRequest)
            .then((response: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, response));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                if (response.status === 200) {
                    return response.json();
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                dispatch(dashboardPOSuccess(dashboardRequest, undefined));
            });
    };
}

export function getPieChart(forStatus: string, request: any) {
   return (dispatch: Dispatch<any>) => {
        return DashboardServiceApi.auth().getPieChart(forStatus, request)
            .then((response: any) => {               
                    return response;               
            }, (_error: any) => {
                return dispatch(forStatus);
            });
    };
}

export function getPOTileView(dashboardRequest: DashboardRequest) {
    return (dispatch: Dispatch<any>) => {
        dispatch(isFetchingPOTile(true));
        dispatch(resetDropdownOptionsBasedOnFilters(dashboardRequest.filters));
        DashboardServiceApi.auth().getContractTile(dashboardRequest)
            .then((response: any) => {
                dispatch(poTileViewSuccess(dashboardRequest, response));
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(poTileViewSuccess(dashboardRequest, undefined));
            });
    };
}

function setIsLoadingBasedOnRequestType(dispatch: Dispatch<any>, isDownload: boolean, isLoading: boolean) {
    if (isDownload) {
        dispatch(isDownloadLoading(isLoading));
    } else {
        dispatch(isFetchingDashboard(isLoading));
    }
}

export function uploadProcurementFile(request: any) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(isUploadLoading(true));
        return DashboardServiceApi.auth().uploadProcurementFile(request)
            .then((response: any) => {
                dispatch(isUploadLoading(false));
                dispatch(uploadSuccess(response));
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isUploadLoading(false));
                dispatch(uploadSuccess(_error.message));
            });
    };
}

export function updateProcurementPO(request: any) {
    return (dispatch: Dispatch<any>) => {
        dispatch(isEditLoading(true));
        return DashboardServiceApi.auth().updateProcurementPO(request)
            .then((response: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(response));
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(_error.message));
            });
    };
}

export function updatePOContract(request: any) {
    return (dispatch: Dispatch<any>) => {
        dispatch(isEditLoading(true));
        return DashboardServiceApi.auth().updatePOContract(request)
            .then((response: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(response));
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(_error.message));
            });
    };
}

export function updateTransportationPO(request: any) {
    return (dispatch: Dispatch<any>) => {
        dispatch(isEditLoading(true));
        return DashboardServiceApi.auth().updateTransportationPO(request)
            .then((response: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(response));
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(_error.message));
            });
    };
}

export function updateTransportationVendorPO(request: any) {
    return (dispatch: Dispatch<any>) => {
        const isInternalValue = isInternal(routePaths.transportationVendorDashboard);
        dispatch(isEditLoading(true));
        return DashboardServiceApi.auth(true, isInternalValue).updateTransportationVendorPO(request, isInternalValue)
            .then((response: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(response));
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(_error.message));
            });
    };
}

export function updateManufacturingVendorPO(request: any) {
    return (dispatch: Dispatch<any>) => {
        const isInternalValue = isInternal(routePaths.manufacturingVendorDashboard);
        dispatch(isEditLoading(true));
        return DashboardServiceApi.auth(true, isInternalValue).updateManufacturingVendorPO(request, isInternalValue)
            .then((response: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(response));
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(_error.message));
            });
    };
}

export function uploadTransportationFile(request: any) {
    return (dispatch: Dispatch<any>) => {
        dispatch(isUploadLoading(true));
        return DashboardServiceApi.auth().uploadTransportationFile(request)
            .then((response: any) => {
                dispatch(isUploadLoading(false));
                dispatch(uploadSuccess(response));
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isUploadLoading(false));
                dispatch(uploadSuccess(_error.message));
            });
    };
}

export function getProcurementPO(dashboardRequest: DashboardRequest) {
    return (dispatch: Dispatch<any>) => {
        setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, true);
        dispatch(resetDropdownOptionsBasedOnFilters(dashboardRequest.filters));
        DashboardServiceApi.auth().getProcurementPO(dashboardRequest)
            .then((response: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, response));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                if (response.status === 200) {
                    return response.json();
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isFetchingDashboard(false));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
            });
    };
}

export function getTransportationPO(dashboardRequest: DashboardRequest) {
    return (dispatch: Dispatch<any>) => {
        setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, true);
        dispatch(resetDropdownOptionsBasedOnFilters(dashboardRequest.filters));
        DashboardServiceApi.auth().getTransportationPO(dashboardRequest)
            .then((response: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, response));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                if (response.status === 200) {
                    return response.json();
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                dispatch(dashboardPOSuccess(dashboardRequest, undefined));
            });
    };
}


function isInternal(pageName: string) {
    let isInternal: boolean | undefined = undefined;
    if (UserHelper.isInternal(store.getState(), pageName)) {
        isInternal = true;
    } else if (UserHelper.isExternal(store.getState(), pageName)) {
        isInternal = false;
    }
    return isInternal;
}

export function getManufacturingVendorPO(dashboardRequest: DashboardRequest) {
    return (dispatch: Dispatch<any>) => {
        setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, true);
        dispatch(resetDropdownOptionsBasedOnFilters(dashboardRequest.filters));
        const isInternalValue = isInternal(routePaths.manufacturingVendorDashboard);
        DashboardServiceApi.auth(true, isInternalValue).getManufacturingVendorPO(dashboardRequest, isInternalValue)
            .then((response: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, response));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                if (response.status === 200) {
                    return response.json();
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                dispatch(dashboardPOSuccess(dashboardRequest, undefined));
            });
    };
}

export function getTransportVendorPO(dashboardRequest: DashboardRequest) {
    return (dispatch: Dispatch<any>) => {
        setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, true);
        dispatch(resetDropdownOptionsBasedOnFilters(dashboardRequest.filters));
        const isInternalValue = isInternal(routePaths.transportationVendorDashboard);
        DashboardServiceApi.auth(true, isInternalValue).getTransportVendorPO(dashboardRequest, isInternalValue)
            .then((response: any) => {
                dispatch(dashboardPOSuccess(dashboardRequest, response));
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                if (response.status === 200) {
                    return response.json();
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                setIsLoadingBasedOnRequestType(dispatch, dashboardRequest.isDownload, false);
                dispatch(dashboardPOSuccess(dashboardRequest, undefined));
            });
    };
}
export function getSpecDocumentData(polId: string, columnName: string) {
    return (dispatch: Dispatch<any>) => {
        const isInternalValue = isInternal(routePaths.manufacturingVendorDashboard);
        return DashboardServiceApi.auth(true, isInternalValue).getSpecDocumentData(polId, columnName, isInternalValue)
            .then((response: any) => {
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                // error Handling
                dispatch(isUploadLoading(false));
                return undefined;
            });
    };
}

function formatDropdownOptions(response: any[], dispatch: Dispatch<any>, columnName: string, columnType?: ColumnTypeEnum) {
    const dropdownOptions = response;
    const filteredList = dropdownOptions.filter(x => x !== undefined && x !== null && x !== '');
    let formattedUniqueList = ([...new Set((filteredList) ?? [])]);
    if (columnType === ColumnTypeEnum.date) {
        formattedUniqueList = formattedUniqueList?.map((option: string) => DateHelper.formatDate(new Date(option)));
        formattedUniqueList = formattedUniqueList.sort((a: string, b: string) => {
            return +new Date(a) - +new Date(b);
        });
    } else if (columnType === ColumnTypeEnum.number || columnType === ColumnTypeEnum.currency) {
        formattedUniqueList = formattedUniqueList.sort((a, b) => a - b);
    } else if (columnType === ColumnTypeEnum.tag) {
        formattedUniqueList = formattedUniqueList.filter(x => x !== TagTypeEnum.noTag);
    } else {
        formattedUniqueList = formattedUniqueList.sort();
    }
    dispatch(specificFieldDataSuccess(columnName, formattedUniqueList));
    return formattedUniqueList;
}

export function getDropdownDataByColumnName(columnName: string, columnType?: ColumnTypeEnum) {
    return (dispatch: Dispatch<any>) => {
        dispatch(isFetchingDropdownOptions(true));
        DashboardServiceApi.auth(false).getDropdownDataByColumnName(columnName)
            .then((response: any) => {
                if (response) {
                    return formatDropdownOptions(response, dispatch, columnName + DashboardHelper.getEditColumnSuffix(), columnType);
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isFetchingDropdownOptions(false));
                dispatch(specificFieldDataSuccess(columnName, undefined));
            });
    };
}

export function getSpecificFieldData(columnName: string, columnType?: ColumnTypeEnum) {
    return (dispatch: Dispatch<any>) => {
        dispatch(isFetchingDropdownOptions(true));
        DashboardServiceApi.auth(false).getSpecificFieldData(columnName)
            .then((response: any) => {
                if (response) {
                    return formatDropdownOptions(response, dispatch, columnName, columnType);
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isFetchingDropdownOptions(false));
                dispatch(specificFieldDataSuccess(columnName, undefined));
            });
    };
}

export function getSpecificFieldDataWithRequest(columnName: string, columnType?: ColumnTypeEnum, filters?: Filters[], queryType?: AdminCRUDTypeEnum) {
    return (dispatch: Dispatch<any>) => {
        const isInternalValue = isInternal(window.location?.pathname);
        dispatch(isFetchingDropdownOptions(true));
        DashboardServiceApi.auth(true, isInternalValue).getSpecificFieldDataWithRequest(columnName, filters, isInternalValue, queryType)
            .then((response: any) => {
                if (response) {
                    return formatDropdownOptions(response, dispatch, columnName, columnType);
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isFetchingDropdownOptions(false));
                dispatch(specificFieldDataSuccess(columnName, undefined));
            });
    };
}

export function getNotificationTemplate(FromScreen: string) {
    return (dispatch: Dispatch<any>) => {
        const isInternalValue = isInternal(routePaths.procurementDashboard);
        return DashboardServiceApi.auth(true, isInternalValue).getNotificationTemplate(FromScreen)
            .then((response: any) => {
                if (response) {
                    console.log(response);
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                //error Handling
                dispatch(isUploadLoading(false));
                return undefined;
            });
    };
}

export function sendNotificationEmail(request: any) {
    return (dispatch: Dispatch<any>) => {
        dispatch(isEditLoading(true));
        return DashboardServiceApi.auth().sendNotificationEmail(request)
            .then((response: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(response));
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                dispatch(isEditLoading(false));
                dispatch(editSuccess(_error.message));
            });
    };
}


export const resetDropdownOptionsBasedOnFilters = (filters?: Filters[]) => {
    return (dispatch: Dispatch<ResetDropdownOptionsBasedOnFilters>) => {
        dispatch({
            payload: {
                filters
            },
            type: DashboardPOActionTypes.RESET_DROPDOWN_WITH_FILTER
        });
    };
};

export const specificFieldDataSuccess = (columnName: string, DropdownOptions: string[] | number[] | undefined) => {
    return (dispatch: Dispatch<SpecificFieldDataSuccess>) => {
        dispatch({
            payload: {
                columnName: columnName,
                DropdownOptions: DropdownOptions
            },
            type: DashboardPOActionTypes.DASHBOARD_DROPDOWN_SUCCESS
        });
    };
};

export const dashboardPOSuccess = (dashboardRequest: DashboardRequest, dashboardResponse?: DashboardResponse) => {
    return (dispatch: Dispatch<DashboardAction>) => {
        dispatch({
            payload: {
                request: dashboardRequest,
                response: dashboardResponse,
            },
            type: DashboardPOActionTypes.DASHBOARD_PO_SUCCESS,
        });
    };
};

export const isFetchingDashboard = (isFetching: boolean) => {
    return (dispatch: Dispatch<IsFetchingDashboardPO>) => {
        dispatch({
            payload: {
                isFetching,
            },
            type: DashboardPOActionTypes.DASHBOARD_PO_LOADING,
        });
    };
};

export const isDownloadLoading = (isFetching: boolean) => {
    return (dispatch: Dispatch<IsDownloadLoading>) => {
        dispatch({
            payload: {
                isFetching
            },
            type: DashboardPOActionTypes.DASHBOARD_DOWNLOAD_LOADING
        });
    };
};

export const isUploadLoading = (isFetching: boolean) => {
    return {
        payload: {
            isFetching
        },
        type: DashboardPOActionTypes.DASHBOARD_UPLOAD_LOADING
    };
};

export const isEditLoading = (isFetching: boolean) => {
    return (dispatch: Dispatch<IsEditLoading>) => {
        dispatch({
            payload: {
                isFetching
            },
            type: DashboardPOActionTypes.DASHBOARD_EDIT_LOADING
        });
    };
};

export const uploadSuccess = (responseMessage: string) => {
    return {
        payload: {
            uploadAPIMessage: responseMessage
        },
        type: DashboardPOActionTypes.DASHBOARD_UPLOAD_SUCCESS
    };
};

export const editSuccess = (responseMessage: string) => {
    return (dispatch: Dispatch<EditSuccess>) => {
        dispatch({
            payload: {
                editAPIMessage: responseMessage
            },
            type: DashboardPOActionTypes.DASHBOARD_EDIT_SUCCESS
        });
    };
};
export const isFetchingDropdownOptions = (isFetching: boolean) => {
    return (dispatch: Dispatch<IsFetchingDropdownOptions>) => {
        dispatch({
            payload: {
                isFetchingDropdownOptions: isFetching,
            },
            type: DashboardPOActionTypes.DASHBOARD_DROPDOWN_LOADING
        });
    };
};

export const setDashboardRequest = (dashboardRequest: DashboardRequest) => {
    return (dispatch: Dispatch<SetDashboardRequest>) => {
        dispatch({
            payload: {
                request: dashboardRequest
            },
            type: DashboardPOActionTypes.SET_DASHBOARD_REQUEST
        });
    };
};

export const setFilterMetaData = (filters?: DataTableFilterMeta) => {
    return (dispatch: Dispatch<SetFilterMetaData>) => {
        dispatch({
            payload: {
                request: filters
            },
            type: DashboardPOActionTypes.SET_FILTER_METADATA
        });
    };
};

export const resetDashboardData = () => {
    return (dispatch: Dispatch<ResetDashboardData>) => {
        dispatch({
            type: DashboardPOActionTypes.RESET_DASHBOARD_DATA
        });
    };
};

export const resetPOContractTiledData = () => {
    return (dispatch: Dispatch<ResetPOContractTiledData>) => {
        dispatch({
            type: DashboardPOActionTypes.RESET_PO_CONTRACT_TILE_DATA
        });
    };
};

export const resetUploadApiMessage = () => {
    return (dispatch: Dispatch<ResetUploadApiMessage>) => {
        dispatch({
            type: DashboardPOActionTypes.RESET_UPLOAD_API_MESSAGE
        });
    };
};

export const resetUpdateApiMessage = () => {
    return (dispatch: Dispatch<ResetUpdateApiMessage>) => {
        dispatch({
            type: DashboardPOActionTypes.RESET_UPDATE_API_MESSAGE
        });
    };
};

export const resetDownloadDashboardData = () => {
    return (dispatch: Dispatch<ResetDownloadDashboardData>) => {
        dispatch({
            type: DashboardPOActionTypes.RESET_DOWNLOAD_DASHBOARD_DATA
        });
    };
};

export const poTileViewSuccess = (dashboardRequest: DashboardRequest, dashboardResponse?: DashboardResponse) => {
    return (dispatch: Dispatch<POTileViewSuccess>) => {
        dispatch({
            payload: {
                request: dashboardRequest,
                response: dashboardResponse,
            },
            type: DashboardPOActionTypes.PO_TILE_SUCCESS,
        });
    };
};

export const isFetchingPOTile = (isFetching: boolean) => {
    return (dispatch: Dispatch<IsFetchingPOTile>) => {
        dispatch({
            payload: {
                isFetching,
            },
            type: DashboardPOActionTypes.PO_TILE_LOADING,
        });
    };
};
