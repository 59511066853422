import { AdminDecalViewModel, StationDecalModel } from '../shared/models/AdminDecalViewModel';
import { ColumnTypeEnum } from '../shared/models/ColumnTypeEnum';
import { AdminOperationsEnum } from '../shared/models/Enums/AdminOperationsEnum';
import { HeadCell } from '../shared/models/HeadCell';

class AdminDecalColumnHelper {
    public static getColumns(operation: AdminOperationsEnum): HeadCell<AdminDecalViewModel>[] {
        switch (operation) {
            case AdminOperationsEnum.viewAll:
                return [
                    { name: 'edit', header: 'Edit', isSortable: false, filter: false, dataType: ColumnTypeEnum.edit },
                    { name: 'delete', header: 'Delete', isSortable: false, filter: false, dataType: ColumnTypeEnum.delete },
                    { name: 'stationCode', header: 'Name', isEditable: false },
                    { name: 'decalCompanyName', header: 'Vendor Name', isEditable: false },
                    { name: 'mailingAddress', header: 'Part Ship to Address', dataType: ColumnTypeEnum.text, isSortable: false, filter: false },
                    { name: 'view', dataType: ColumnTypeEnum.view }
                ];
            case AdminOperationsEnum.view:
                return [
                    { name: 'stationCode', header: 'Station Name', isEditable: false, dataType: ColumnTypeEnum.text },
                    { name: 'decalCompanyName', header: 'Company Name', isEditable: false, dataType: ColumnTypeEnum.text },
                    { name: 'decalAddressLine1', header: 'Part Ship to Address Line1', dataType: ColumnTypeEnum.text, isEditable: false },
                    { name: 'decalAddressLine2', header: 'Part Ship to Address Line2', dataType: ColumnTypeEnum.text, isEditable: false },
                    { name: 'decalCity', header: 'Part Ship to City', isEditable: false, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text },
                    { name: 'decalCountry', header: 'Part Ship to Country', isEditable: false, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.staticDropdown },
                    { name: 'decalState', header: 'Part Ship to State', isEditable: false, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.staticDropdown, parentColumnName: 'decalCountry' },
                    { name: 'decalZip', header: 'Part Ship to Zip/Postal Code', isEditable: false, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text },
                    {
                        name: 'stationDecalUsers', header: 'Additional Contact', dataType: ColumnTypeEnum.additionalContact,
                        childColumns: AdminDecalColumnHelper.getAdditionalColumns(),
                        childNewObject: AdminDecalColumnHelper.getAdditionalContact()
                    },
                    { name: 'decalComments', header: 'Comments', dataType: ColumnTypeEnum.text },
                    { name: 'isDecalActive', isEditable: false, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },                    
                               
                ];
            case AdminOperationsEnum.edit:
                return [
                    { name: 'stationCode', header: 'Station Name', isEditable: false, dataType: ColumnTypeEnum.text },
                    { name: 'decalCompanyName', header: 'Company Name', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'decalAddressLine1', header: 'Part Ship to Address Line1', dataType: ColumnTypeEnum.text, isEditable: true },
                    { name: 'decalAddressLine2', header: 'Part Ship to Address Line2', dataType: ColumnTypeEnum.text, isEditable: true },
                    { name: 'decalCity', header: 'Part Ship to City', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text },
                    { name: 'decalCountry', header: 'Part Ship to Country', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.staticDropdown },
                    { name: 'decalState', header: 'Part Ship to State', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.staticDropdown, parentColumnName: 'decalCountry' },
                    { name: 'decalZip', header: 'Part Ship to Zip/Postal Code', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text, isValidZip: true },
                    {
                        name: 'stationDecalUsers', header: 'Additional Contact', dataType: ColumnTypeEnum.additionalContact, isEditable: true,
                        childColumns: AdminDecalColumnHelper.getAdditionalColumns(),
                        childNewObject: AdminDecalColumnHelper.getAdditionalContact()
                    },
                    { name: 'decalComments', header: 'Comments', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'isDecalActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },                    
                     
                ];
            case AdminOperationsEnum.add:
                return [
                    { name: 'stationCode', header: 'Station Name', isEditable: true, dataType: ColumnTypeEnum.dynamicDropdown, isRequired: true },
                    { name: 'decalCompanyName', header: 'Company Name', isEditable: true, dataType: ColumnTypeEnum.text, isRequired: true },
                    { name: 'decalAddressLine1', header: 'Part Ship to Address Line1', dataType: ColumnTypeEnum.text, isEditable: true, isRequired: true },
                    { name: 'decalAddressLine2', header: 'Part Ship to Address Line2', dataType: ColumnTypeEnum.text, isEditable: true },
                    { name: 'decalCity', header: 'Part Ship to City', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text, isRequired: true  },
                    { name: 'decalCountry', header: 'Part Ship to Country', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.staticDropdown, isRequired: true  },
                    { name: 'decalState', header: 'Part Ship to State', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.staticDropdown, parentColumnName: 'decalCountry', isRequired: true  },
                    { name: 'decalZip', header: 'Part Ship to Zip/Postal Code', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text, isValidZip: true },
                    {
                        name: 'stationDecalUsers', header: 'Additional Contact', dataType: ColumnTypeEnum.additionalContact, isEditable: true, 
                        childColumns: AdminDecalColumnHelper.getAdditionalColumns(),
                        childNewObject: AdminDecalColumnHelper.getAdditionalContact()
                    },
                    { name: 'decalComments', header: 'Comments', isEditable: true, dataType: ColumnTypeEnum.text },
                    { name: 'isDecalActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch },                    
                     
                ];
            default:
                return [];
        }
    }

    public static getAdditionalColumns(): HeadCell<StationDecalModel>[] {
        return [
            { name: 'contactName', header: 'Contact Name', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text },
            { name: 'emailId', header: 'Email Id', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text, isValidEmail:true },
            { name: 'officePhone', header: 'Office Phone', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text, isValidMobile:true },
            { name: 'cellPhone', header: 'Cell Phone', isEditable: true, isPresentInMultipleColumn: true, dataType: ColumnTypeEnum.text, isValidMobile:true },
            { name: 'isActive', isEditable: true, header: 'Is Active', dataType: ColumnTypeEnum.inputSwitch, isPresentInMultipleColumn: true },
            { name: 'isMainContact', isEditable: true, header: 'Is Main Contact', dataType: ColumnTypeEnum.inputSwitch, isPresentInMultipleColumn: true },
        ];
    }

    private static getAdditionalContact(): StationDecalModel {
        return {
            contactName: '',
            emailId: '',
            officePhone: '',
            cellPhone: '',
            isActive: true,
            isMainContact: false
        };
    }
}

export default AdminDecalColumnHelper;

