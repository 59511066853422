/* eslint-disable no-unused-vars */
export enum AuthActionTypes {
    INITIALIZE_START = '@@authentication/INITIALIZE_START',
    INITIALIZE_COMPLETE = '@@authentication/INIT_COMPLETE',
    SIGNIN_START = '@@authentication/SIGNIN_START',
    SIGNIN_SUCCESS = '@@authentication/SIGNIN_SUCCESS',
    SIGNIN_FAIL = '@@authentication/SIGNIN_FAIL',
    SIGNOUT_START = '@@authentication/SIGNOUT_START',
    AUTHORIZE_SUCCESS = '@@authorization/AUTHORIZE_SUCESS',
    AUTHORIZE_FAILURE = '@@authorization/AUTHORIZE_FAILURE',
    SESSION_UPDATE = '@@session/SESSION_UPDATE',
    SESSION_INVALIDATE = '@@session/SESSION_INVALIDATE',

    NAME_SET = '@@user/NAME_SET',
    NAME_RESET = '@@user/NAME_RESET',
    TYPE_SET = '@@user/TYPE_SET',
    TYPE_RESET = '@@user/TYPE_RESET',
    REQUESTED_PAGESET = '@@user/REQUESTED_PAGESET',
    REQUESTED_PAGERESET = '@@user/REQUESTED_PAGERESET',
    ISAUTHENTICATED_SET = '@@user/ISAUTHENTICATED_SET',
    ISAUTHENTICATED_RESET = '@@user/ISAUTHENTICATED_RESET',
}
