import { AdminCRUDTypeEnum } from '../shared/models/Enums/AdminCRUDTypeEnum';
import { AdminOperationsEnum } from '../shared/models/Enums/AdminOperationsEnum';
import { HeadCell } from '../shared/models/HeadCell';
import AdminDecalColumnHelper from './AdminDecalColumnHelper';
import AdminStationContactColumnHelper from './AdminStationContactColumnHelper';
import AdminTemplateColumnHelper from './AdminTemplateColumnHelper';
import AdminUserColumnHelper from './AdminUserColumnHelper';
import AdminVendorColumnHelper from './AdminVendorColumnHelper';

class AdminScreenColumnHelper {
    public static getColumns(type: AdminCRUDTypeEnum | undefined, operation: AdminOperationsEnum): HeadCell<any>[] {
        switch (type) {
            case AdminCRUDTypeEnum.user:
                return AdminUserColumnHelper.getColumns(operation);
            case AdminCRUDTypeEnum.vendor:
                return AdminVendorColumnHelper.getColumns(operation);
            case AdminCRUDTypeEnum.stationContact:
                return AdminStationContactColumnHelper.getColumns(operation);
            case AdminCRUDTypeEnum.template:
                return AdminTemplateColumnHelper.getColumns(operation);
            case AdminCRUDTypeEnum.decal:
                return AdminDecalColumnHelper.getColumns(operation);
            default:
                return [];
        }
    }
}

export default AdminScreenColumnHelper;
