import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import routePaths from '../../shared/constants/routePaths';

import './landingPage.scss'; 
import { Row } from 'react-bootstrap';
import SignIn from '../../containers/SignIn';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import GSEDisplayTextConst from '../../shared/constants/gseDisplayTextConst';
import { FlagsActions } from '../../store/flags';
 
interface ComponentProps {

}

type LandingPageProps =
    ComponentProps
    & typeof mapDispatchToProps;

class LandingPage extends Component<LandingPageProps> {

    constructor(props: LandingPageProps) {                           
        super(props);
        props.disableDashboardLayout();
    }
    
    render() {  
       
        return (       
            <div className="sign-in-page">
                <div className="main row mt-0">
                    <h3 className="col-12">{GSEDisplayTextConst.landingPage.welcomeText}</h3>
                    <p className="col-12">{GSEDisplayTextConst.landingPage.chooseTeamText}</p>
                    <Row className="justify-content-center">
                         <SignIn signInBtnText={GSEDisplayTextConst.landingPage.internalUserLoginText} />
                    </Row>
                    <Row className="justify-content-center">
                        <Link className="col-12 pt-1 button-option justify-content-center shadow-lg rounded" to={routePaths.VendorSignIn} >{GSEDisplayTextConst.landingPage.vendorLoginText}</Link>
                    </Row>        
                </div>
            </div>            
        );
        
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): LandingPageProps => {
    const { internalUser: auth } = state;

    return {
        ...auth,
        ...ownProps,
    };
};

const mapDispatchToProps = {
    disableDashboardLayout: FlagsActions.disableDashboardLayout
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
