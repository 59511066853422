import { appSettings } from '..';
import routePaths from '../shared/constants/routePaths';
import { ApplicationState } from '../store';
import TokenHelper from './TokenHelper';

class UserHelper {

    static manufacturingVendorRole = 'Manufacturer';
    static transportationVendorRole = 'Transporter';

    public static isInternal(state: ApplicationState, pageName: string) {
        return UserHelper.isInternalUserValid(state) && UserHelper.isInternalUserAuthorized(state, pageName);
    }

    public static isExternal(state: ApplicationState, pageName: string) {
        return UserHelper.isExternalUserValid(state) && UserHelper.isExternalUserAuthorized(state, pageName);
    }

    public static isInternalUserValid(state: ApplicationState): boolean {
        const { internalUser } = state;
        return internalUser?.isAuthenticated && internalUser?.idToken
            && TokenHelper.isAuthenticated(internalUser?.idToken);
    }

    public static isExternalUserValid(state: ApplicationState): boolean {
        const { externalUser } = state;
        return externalUser?.isAuthenticated && externalUser?.idToken && TokenHelper.isAuthenticated(externalUser?.idToken);
    }

    public static isInternalUserAuthorized(state: ApplicationState, pageName: string) {
        let isUserAuthorized = false;
        switch (pageName) {
            case routePaths.procurementDashboard:
                isUserAuthorized = state.internalUser.roles.includes(appSettings.role.procurementRole)
                    || state.internalUser.roles.includes(appSettings.role.superAdminRole);
                break;

            case routePaths.internalTransportationDashboard:
                isUserAuthorized = state.internalUser.roles.includes(appSettings.role.transportationRole)
                    || state.internalUser.roles.includes(appSettings.role.superAdminRole);
                break;

            case routePaths.dashboard:
                isUserAuthorized = state.internalUser.roles.includes(appSettings.role.dashboardRole)
                    || state.internalUser.roles.includes(appSettings.role.superAdminRole);
                break;

            case routePaths.manufacturingVendorDashboard:
                isUserAuthorized = state.internalUser.roles.includes(appSettings.role.manufacturingVendorRole)
                    || state.internalUser.roles.includes(appSettings.role.superAdminRole);
                break;

            case routePaths.transportationVendorDashboard:
                isUserAuthorized = state.internalUser.roles.includes(appSettings.role.transportationVendorRole)
                    || state.internalUser.roles.includes(appSettings.role.superAdminRole);
                break;

            case routePaths.admin:
            case routePaths.adminVendor:
            case routePaths.adminStationContact:
            case routePaths.adminVendorUser:
            case routePaths.adminTemplate:
            case routePaths.adminDecal:
                isUserAuthorized = state.internalUser.roles.includes(appSettings.role.adminRole)
                    || state.internalUser.roles.includes(appSettings.role.superAdminRole);
                break;

            default:
                break;
        }
        return isUserAuthorized;
    }

    public static isExternalUserAuthorized(state: ApplicationState, pageName: string) {
        let isUserAuthorized = false;
        switch (pageName) {
            case routePaths.manufacturingVendorDashboard:
                isUserAuthorized = state.externalUser.roles.includes(UserHelper.manufacturingVendorRole);
                break;

            case routePaths.transportationVendorDashboard:
                isUserAuthorized = state.externalUser.roles.includes(UserHelper.transportationVendorRole);
                break;

            default:
                break;
        }
        return isUserAuthorized;
    }

    static getLandingPageRoute(state: ApplicationState) {
        let routePath = routePaths.unAuthorized;
        let roleName = UserHelper.getFirstRoleName(state);

        switch (roleName) {
            case appSettings.role.procurementRole:
                routePath = routePaths.procurementDashboard;
                break;

            case appSettings.role.transportationRole:
                routePath = routePaths.internalTransportationDashboard;
                break;

            case appSettings.role.dashboardRole:
                routePath = routePaths.dashboard;
                break;

            case appSettings.role.manufacturingVendorRole:
                routePath = routePaths.manufacturingVendorDashboard;
                break;

            case appSettings.role.transportationVendorRole:
                routePath = routePaths.transportationVendorDashboard;
                break;

            case appSettings.role.adminRole:
                routePath = routePaths.admin;
                break;

            case appSettings.role.superAdminRole:
                routePath = routePaths.procurementDashboard;
                break;

            case this.manufacturingVendorRole:
                routePath = routePaths.manufacturingVendorDashboard;
                break;

            case this.transportationVendorRole:
                routePath = routePaths.transportationVendorDashboard;
                break;

            default:
                break;
        }
        return routePath;
    }

    private static getFirstRoleName(state: ApplicationState) {
        let roleName = '';
        const isInternal = UserHelper.isInternalUserValid(state);
        const isExternal = UserHelper.isExternalUserValid(state);
        if (isInternal && state.internalUser.roles?.length > 0) {
            roleName = state.internalUser.roles[0];
        } else if (isExternal && state.externalUser.roles?.length > 0) {
            roleName = state.externalUser.roles[0];
        } else {
            roleName = 'Unauthorized';
        }
        return roleName;
    }

    static getRoleOrder() {
        return {
            [appSettings.role.procurementRole]: 0,
            [appSettings.role.transportationRole]: 1,
            [appSettings.role.transportationVendorRole]: 2,
            [appSettings.role.manufacturingVendorRole]: 3,
            [appSettings.role.dashboardRole]: 4,
            [appSettings.role.adminRole]: 5,
            [this.transportationVendorRole]: 6,
            [this.manufacturingVendorRole]: 7
        };
    }

    static rolesSort(roles: string[]) {
        const anyHighNumber = 100;
        const roleOrder = this.getRoleOrder();
        return roles.sort((a, b) => ((roleOrder[a] ?? anyHighNumber) - (roleOrder[b] ?? anyHighNumber)));
    }
}

export default UserHelper;
