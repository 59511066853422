import { appSettings } from '..';
import { ContractTileUpdateRequest } from '../shared/models/ContractTileUpdateRequest';
import { AdminCRUDTypeEnum } from '../shared/models/Enums/AdminCRUDTypeEnum';
import { ManufacturingVendorUpdateRequest } from '../shared/models/ManufacturingVendorUpdateRequest';
import { sendNotification } from '../shared/models/SendNotificationModel';
import { TransportationUpdateRequest } from '../shared/models/TransportationUpdateRequest';
import { TransportationVendorUpdateRequest } from '../shared/models/TransportationVendorUpdateRequest';
import { DashboardRequest, DashboardResponse, Filters } from '../shared/models/dashboard';
import BaseApiClient from './BaseApi';

export class DashboardServiceApi extends BaseApiClient {
    public static scopes: string[] = [
        'User.Read'
    ];

    public static auth(enabled?: boolean, isInternal?: boolean) {
        const authenticated = (enabled === undefined) ? true : enabled;
        const isInternalValue = (isInternal === undefined) ? true : isInternal;
        return new this(authenticated, isInternalValue);
    }

    protected constructor(authenticated?: boolean, isInternal?: boolean) {
        const { gseTrackerApi } = appSettings;
        console.log('isInternal', isInternal);
        super(gseTrackerApi.host, authenticated, isInternal);
    }

    public getDashboardPO(request: DashboardRequest): Promise<DashboardResponse> {
        return this._post<DashboardResponse>(this.baseUrl + '/Views/Dashboard', request, DashboardServiceApi.scopes);
    }

    public getPieChart(forStatus: string, request: any): Promise<any> {
        return this._post<DashboardResponse>(this.baseUrl + '/Views/PieChart?forStatus='+ forStatus, request, DashboardServiceApi.scopes);
    }

    public getProcurementPO(request: DashboardRequest): Promise<DashboardResponse> {
        return this._post<DashboardResponse>(this.baseUrl + '/Views/Procurement', request, DashboardServiceApi.scopes);
    }

    public getTransportationPO(request: DashboardRequest): Promise<DashboardResponse> {
        return this._post<DashboardResponse>(this.baseUrl + '/Views/Transportation', request, DashboardServiceApi.scopes);
    }

    public getManufacturingVendorPO(request: DashboardRequest, isInternal: boolean | undefined): Promise<DashboardResponse> {
        return this._post<DashboardResponse>(this.baseUrl + '/Views/ManufacturingVendor?isInternal=' + isInternal, request, DashboardServiceApi.scopes);
    }

    public getTransportVendorPO(request: DashboardRequest, isInternal: boolean | undefined): Promise<DashboardResponse> {
        return this._post<DashboardResponse>(this.baseUrl + '/Views/TransportVendor?isInternal=' + isInternal, request, DashboardServiceApi.scopes);
    }

    public  getSpecificFieldData(columnName: string): Promise<DashboardResponse> {
        const requestUrl = this.baseUrl + '/Views/SpecificFieldData?fieldName=' + columnName;
        return this._get<DashboardResponse>(requestUrl, DashboardServiceApi.scopes);
    }

    public getSpecificFieldDataWithRequest(columnName: string, request?: Filters[], isInternal?: boolean | undefined, queryType?: AdminCRUDTypeEnum): Promise<DashboardResponse> {
        let requestUrl = this.baseUrl + '/Views/SpecificFieldDataWithCondition?fieldName=' + columnName + '&isInternal=' + isInternal;
        if (queryType !== undefined) {
            requestUrl = requestUrl + '&queryType=' + queryType;
        }
        return this._post<DashboardResponse>(requestUrl, request ?? [], DashboardServiceApi.scopes);
    }

    public getDropdownDataByColumnName(columnName: string): Promise<DashboardResponse> {
        const requestUrl = this.baseUrl + '/Views/getDropdownData?fieldName=' + columnName;
        return this._get<DashboardResponse>(requestUrl, DashboardServiceApi.scopes);
    }

    getSpecDocumentData(polId: string, columnName: string, isInternal: boolean | undefined) {
        const requestUrl = this.baseUrl + '/Views/GetFile?fieldName='
            + columnName + '&isInternal=' + isInternal + '&polId=' + polId;
        return this._get<DashboardResponse>(requestUrl, DashboardServiceApi.scopes);
    }

    public  getNotificationTemplate(FromScreen: string): Promise<DashboardResponse> {
        const requestUrl = this.baseUrl + '/Views/GetNotificationTemplates?FromScreen=' + FromScreen;
        return this._get<DashboardResponse>(requestUrl, DashboardServiceApi.scopes);
    }

    public uploadProcurementFile(request: any): Promise<any> {
        return this._postFile<string>(this.baseUrl + '/Procurement/Upload', request, DashboardServiceApi.scopes);
    }

    public updateProcurementPO(request: any): Promise<any> {
        return this._post<string>(this.baseUrl + '/Procurement/Edit', request, DashboardServiceApi.scopes);
    }

    public updatePOContract(request: ContractTileUpdateRequest): Promise<any> {
        return this._post<string>(this.baseUrl + '/Procurement/Edit/ContractTile', request, DashboardServiceApi.scopes);
    }

    public updateTransportationPO(request: TransportationUpdateRequest): Promise<any> {
        return this._post<string>(this.baseUrl + '/Transportation/Edit', request, DashboardServiceApi.scopes);
    }

    public uploadTransportationFile(request: any): Promise<any> {
        return this._postFile<string>(this.baseUrl + '/Transportation/Upload', request, DashboardServiceApi.scopes);
    }
    
    public getContractTile(request: DashboardRequest): Promise<any> {
        return this._post<DashboardResponse>(this.baseUrl + '/Views/ContractTile', request, DashboardServiceApi.scopes);
    }

    updateTransportationVendorPO(request: TransportationVendorUpdateRequest, isInternal: boolean | undefined): Promise<any> {
        return this._post<string>(this.baseUrl + '/vendor/transport/edit?isInternal=' + isInternal, request, DashboardServiceApi.scopes);
    }

    updateManufacturingVendorPO(request: ManufacturingVendorUpdateRequest, isInternal: boolean | undefined): Promise<any> {
        return this._post<string>(this.baseUrl + '/vendor/manufacture/edit?isInternal=' + isInternal, request, DashboardServiceApi.scopes);
    }

    sendNotificationEmail(request: sendNotification): Promise<any> {
        return this._post<string>(this.baseUrl + '/Views/SendVendorNotification', request, DashboardServiceApi.scopes);
    }
}